/** Auth */
export const AUTHENTICATE_ACTION_BEFORE = "AUTHENTICATE_ACTION_BEFORE";
export const AUTHENTICATE_ACTION_FINISH = "AUTHENTICATE_ACTION_FINISH";
export const AUTHENTICATE_ACTION_SET_EXPIRE = "AUTHENTICATE_ACTION_SET_EXPIRE";
export const AUTHENTICATE_ACTION_SET_QRBASE64 = "AUTHENTICATE_ACTION_SET_QRBASE64";
export const ACTIVATE_ACTION_BEFORE = "ACTIVATE_ACTION_BEFORE";
export const ACTIVATE_ACTION_FINISH = "ACTIVATE_ACTION_FINISH";
export const ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE = "ACTIVATION_PASSWORD_SETTINGS_ACTION_BEFORE";
export const ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH = "ACTIVATION_PASSWORD_SETTINGS_ACTION_FINISH";
export const SET_ACTIVATION_PASSWORD_SETTINGS = "SET_ACTIVATION_PASSWORD_SETTINGS";
export const RESET_PASSWORD_ACTION_BEFORE = "RESET_PASSWORD_ACTION_BEFORE";
export const RESET_PASSWORD_ACTION_FINISH = "RESET_PASSWORD_ACTION_FINISH";
export const FORGOT_PASSWORD_ACTION_BEFORE = "FORGOT_PASSWORD_ACTION_BEFORE";
export const FORGOT_PASSWORD_ACTION_FINISH = "FORGOT_PASSWORD_ACTION_FINISH";
export const SET_TEMPORARY_LOCK_DATA = "SET_TEMPORARY_LOCK_DATA";
export const SET_ACTIVE_COMPONENT_NAME = "SET_ACTIVE_COMPONENT_NAME";
export const SET_TWO_FACTOR_DATA = "SET_TWO_FACTOR_DATA";

/** Streams */
export const STREAMS_BEFORE = "STREAMS_BEFORE";
export const STREAMS_FINISH = "STREAMS_FINISH";
export const SET_STREAMS = "SET_STREAMS";
export const STREAMS_SET_SORTING = "STREAMS_SET_SORTING";
export const SET_STREAMS_GENERAL_INFO = "SET_STREAMS_GENERAL_INFO";
export const ADD_STREAM = "ADD_STREAM";
export const STREAMS_SAVING_BEFORE = "STREAMS_SAVING_BEFORE";
export const STREAMS_SAVING_FINISH = "STREAMS_SAVING_FINISH";
export const SET_AVAILABLE_GAMES = "SET_AVAILABLE_GAMES";

/** Partners */
export const PARTNERS_ACTION_BEFORE = "PARTNERS_ACTION_BEFORE";
export const PARTNERS_ACTION_FINISH = "PARTNERS_ACTION_FINISH";
export const PARTNERS_ACTION_SET_PARTNERS = "PARTNERS_ACTION_SET_PARTNERS";
export const PARTNER_SAVE_ACTION_BEFORE = "PARTNER_SAVE_ACTION_BEFORE";
export const PARTNER_SAVE_ACTION_FINISH = "PARTNER_SAVE_ACTION_FINISH";
export const PARTNER_SAVE_ACTION_SUCCESS = "PARTNER_SAVE_ACTION_SUCCESS";
export const PARTNERS_SET_SORTING = "PARTNERS_SET_SORTING";
export const PARTNERS_SET_FILTERS = "PARTNERS_SET_FILTERS";
export const SET_PARTNER_LOGO = "SET_PARTNER_LOGO";

/** Api Keys */
export const APIKEYS_ACTION_BEFORE = "APIKEYS_ACTION_BEFORE";
export const APIKEYS_ACTION_FINISH = "APIKEYS_ACTION_FINISH";
export const APIKEYS_ACTION_SET_APIKEYS = "APIKEYS_ACTION_SET_APIKEYS";
export const APIKEY_SAVE_ACTION_BEFORE = "APIKEY_SAVE_ACTION_BEFORE";
export const APIKEY_SAVE_ACTION_FINISH = "APIKEY_SAVE_ACTION_FINISH";
export const APIKEY_DELETE_ACTION_SUCCESS = "APIKEY_DELETE_ACTION_SUCCESS";
export const APIKEYS_SET_SORTING = "APIKEYS_SET_SORTING";
export const APIKEYS_SET_FILTERS = "APIKEYS_SET_FILTERS";
export const SET_APIKEY_GENERAL_INFO = "SET_APIKEY_GENERAL_INFO";
export const SET_APIKEY_GAMES = "SET_APIKEY_GAMES";
export const SET_APIKEY_ADD_GAME = "SET_APIKEY_ADD_GAME";
export const SET_APIKEY_UPDATE_GAME = "SET_APIKEY_UPDATE_GAME";
export const SET_APIKEY_DELETE_GAME = "SET_APIKEY_DELETE_GAME";
export const APIKEY_GAMES_BEFORE = "APIKEY_GAMES_BEFORE";
export const APIKEY_GAMES_FINISH = "APIKEY_GAMES_FINISH";
export const APIKEY_GAMES_RTP_BEFORE = "APIKEY_GAMES_RTP_BEFORE";
export const APIKEY_GAMES_RTP_FINISH = "APIKEY_GAMES_RTP_FINISH";
export const SET_APIKEY_GAME_RTP = "SET_APIKEY_GAME_RTP";
export const APIKEY_CURRENCIES_BEFORE = "APIKEY_CURRENCIES_BEFORE";
export const APIKEY_CURRENCIES_FINISH = "APIKEY_CURRENCIES_FINISH";
export const SET_APIKEY_CURRENCIES = "SET_APIKEY_CURRENCIES";
export const SET_APIKEY_UPDATE_CURRENCY = "SET_APIKEY_UPDATE_CURRENCY";
export const SET_APIKEY_ANALYTICAL_TOOLS = "SET_APIKEY_ANALYTICAL_TOOLS";
export const SET_APIKEY_PLATFORMS = "SET_APIKEY_PLATFORMS";
export const APIKEY_BLOCKED_COUNTRIES_BEFORE = "APIKEY_BLOCKED_COUNTRIES_BEFORE";
export const APIKEY_BLOCKED_COUNTRIES_FINISH = "APIKEY_BLOCKED_COUNTRIES_FINISH";
export const SET_APIKEY_BLOCKED_COUNTRIES = "SET_APIKEY_BLOCKED_COUNTRIES";
export const SET_APIKEY_ADD_BLOCKED_COUNTRY = "SET_APIKEY_ADD_BLOCKED_COUNTRY";
export const SET_APIKEY_DELETE_BLOCKED_COUNTRY = "SET_APIKEY_DELETE_BLOCKED_COUNTRY";
export const APIKEY_BLOCKED_IPS_BEFORE = "APIKEY_BLOCKED_IPS_BEFORE";
export const APIKEY_BLOCKED_IPS_FINISH = "APIKEY_BLOCKED_IPS_FINISH";
export const SET_APIKEY_BLOCKED_IPS = "SET_APIKEY_BLOCKED_IPS";
export const SET_APIKEY_ADD_BLOCKED_IP = "SET_APIKEY_ADD_BLOCKED_IP";
export const SET_APIKEY_DELETE_BLOCKED_IP = "SET_APIKEY_DELETE_BLOCKED_IP";
export const SET_APIKEY_UI = "SET_APIKEY_UI";
export const SET_APIKEY_PREVIEW_UI = "SET_APIKEY_PREVIEW_UI";
export const RESET_APIKEY_UI = "RESET_APIKEY_UI";
export const SET_APIKEY_MOBILE_LOGO_ID = "SET_APIKEY_MOBILE_LOGO_ID";
export const APIKEY_GAME_OTHER_CONFIGS_BEFORE = "APIKEY_GAME_OTHER_CONFIGS_BEFORE";
export const APIKEY_GAME_OTHER_CONFIGS_FINISH = "APIKEY_GAME_OTHER_CONFIGS_FINISH";
export const SET_APIKEY_GAME_OTHER_CONFIGS = "SET_APIKEY_GAME_OTHER_CONFIGS";
export const APIKEYS_ADD_ONS_ACTION_BEFORE = "APIKEYS_ADD_ONS_ACTION_BEFORE";
export const APIKEYS_ADD_ONS_ACTION_FINISH = "APIKEYS_ADD_ONS_ACTION_FINISH";
export const APIKEYS_ADD_ONS_SAVE_BEFORE = "APIKEYS_ADD_ONS_SAVE_BEFORE";
export const APIKEYS_ADD_ONS_SAVE_FINISH = "APIKEYS_ADD_ONS_SAVE_FINISH";
export const SET_APIKEYS_ADD_ONS = "SET_APIKEYS_ADD_ONS";

/** Risk Management */
/** Big Multipliers */
export const BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE = "BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE";
export const BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH = "BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH";
export const BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE = "BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE";
export const BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH = "BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH";
export const SET_BIG_MULTIPLIERS_CONFIGURATIONS = "SET_BIG_MULTIPLIERS_CONFIGURATIONS";
export const SET_BIG_MULTIPLIERS_HISTORY_FILTERS = "SET_BIG_MULTIPLIERS_HISTORY_FILTERS";
export const SET_BIG_MULTIPLIERS_HISTORY_SORTING = "SET_BIG_MULTIPLIERS_HISTORY_SORTING";
export const BIG_MULTIPLIERS_HISTORY_BEFORE = "BIG_MULTIPLIERS_HISTORY_BEFORE";
export const BIG_MULTIPLIERS_HISTORY_FINISH = "BIG_MULTIPLIERS_HISTORY_FINISH";
export const SET_BIG_MULTIPLIERS_HISTORY = "SET_BIG_MULTIPLIERS_HISTORY"

/** Bet Anomalies */
export const BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE = "BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE";
export const BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH = "BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH";
export const BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE = "BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE";
export const BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH = "BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH";
export const SET_BET_ANOMALIES_CONFIGURATIONS = "SET_BET_ANOMALIES_CONFIGURATIONS";
export const SET_BET_ANOMALIES_HISTORY_FILTERS = "SET_BET_ANOMALIES_HISTORY_FILTERS";
export const SET_BET_ANOMALIES_HISTORY_SORTING = "SET_BET_ANOMALIES_HISTORY_SORTING";
export const BET_ANOMALIES_HISTORY_BEFORE = "BET_ANOMALIES_HISTORY_BEFORE";
export const BET_ANOMALIES_HISTORY_FINISH = "BET_ANOMALIES_HISTORY_FINISH";
export const SET_BET_ANOMALIES_HISTORY = "SET_BET_ANOMALIES_HISTORY"
export const BET_ANOMALY_SAVE_BEFORE = "BET_ANOMALY_SAVE_BEFORE";
export const BET_ANOMALY_SAVE_FINISH = "BET_ANOMALY_SAVE_FINISH";
export const BET_ANOMALY_UPDATE = "BET_ANOMALY_UPDATE";

/** Player Suspicious Activities */
export const PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE = "PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE";
export const PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH = "PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH";
export const SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS = "SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS";
export const SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING = "SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING";
export const SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY = "SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY";
export const UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY = "UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY";
export const PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE = "PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE";
export const PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH = "PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH";
export const SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS = "SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS";
export const ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS = "ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS";
export const UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS = "UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS";
export const REMOVE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS = "REMOVE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS";
export const PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE = "PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE";
export const PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH = "PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH";

/** Users */
export const USERS_ACTION_BEFORE = "USERS_ACTION_BEFORE";
export const USERS_ACTION_FINISH = "USERS_ACTION_FINISH";
export const USERS_ACTION_SET_USERS = "USERS_ACTION_SET_USERS";
export const USERS_ACTION_SET_INACTIVE_USERS = "USERS_ACTION_SET_INACTIVE_USERS";
export const USERS_INACTIVE_USERS_ACTION_BEFORE = "USERS_INACTIVE_USERS_ACTION_BEFORE";
export const USERS_INACTIVE_USERS_ACTION_FINISH = "USERS_INACTIVE_USERS_ACTION_FINISH";
export const USER_SAVE_ACTION_BEFORE = "USER_SAVE_ACTION_BEFORE";
export const USERS_SAVE_ACTION_FINISH = "USERS_SAVE_ACTION_FINISH";
export const USERS_SET_SORTING = "USERS_SET_SORTING";
export const USERS_SET_FILTERS = "USERS_SET_FILTERS";
export const USERS_AVAILABLE_PARTNERS_ACTION_BEFORE = "USERS_AVAILABLE_PARTNERS_ACTION_BEFORE";
export const USERS_AVAILABLE_PARTNERS_ACTION_FINISH = "USERS_AVAILABLE_PARTNERS_ACTION_FINISH";
export const USERS_ACTION_SET_AVAILABLE_PARTNERS = "USERS_ACTION_SET_AVAILABLE_PARTNERS";
export const SET_USER_GENERAL_INFO = "SET_USER_GENERAL_INFO";
export const SET_USER_PROJECT_ACCESS = "SET_USER_PROJECT_ACCESS";
export const SET_USER_BETSHOP_ACCESS = "SET_USER_BETSHOP_ACCESS";
export const SET_USER_PARTNER_ACCESS = "SET_USER_PARTNER_ACCESS";
export const SET_USER_PERMISSION_GROUPS = "SET_USER_PERMISSION_GROUPS";
export const SET_ADD_USER_PERMISSION_GROUP = "SET_ADD_USER_PERMISSION_GROUP";
export const SET_DELETE_USER_PERMISSION_GROUP = "SET_DELETE_USER_PERMISSION_GROUP";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS = "SET_USER_AVAILABLE_PERMISSION_GROUPS";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE = "SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH = "SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const CHANGE_USER_STATE = "CHANGE_USER_STATE";
export const RESET_EDITING_USER = "RESET_EDITING_USER";

/** Access Managers */
export const ACCESS_MANAGERS_ACTION_BEFORE = "ACCESS_MANAGERS_ACTION_BEFORE";
export const ACCESS_MANAGERS_ACTION_FINISH = "ACCESS_MANAGERS_ACTION_FINISH";
export const ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS = "ACCESS_MANAGERS_ACTION_SET_ACCESS_MANAGERS";
export const ACCESS_MANAGERS_SAVE_ACTION_BEFORE = "ACCESS_MANAGERS_SAVE_ACTION_BEFORE";
export const ACCESS_MANAGERS_SAVE_ACTION_FINISH = "ACCESS_MANAGERS_SAVE_ACTION_FINISH";
export const ACCESS_MANAGERS_SET_SORTING = "ACCESS_MANAGERS_SET_SORTING";
export const ACCESS_MANAGERS_SET_FILTERS = "ACCESS_MANAGERS_SET_FILTERS";
export const SET_ACCESS_MANAGER_GENERAL_INFO = "SET_ACCESS_MANAGER_GENERAL_INFO";
export const RESET_EDITING_ACCESS_MANAGER = "RESET_EDITING_ACCESS_MANAGER";
export const CHANGE_ACCESS_MANAGER_STATE = "CHANGE_ACCESS_MANAGER_STATE";

/** Permission Requets */
export const SET_PERMISSION_REQUESTS_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_ACTION_FINISH = "SET_PERMISSION_REQUESTS_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH = "SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS = "SET_PERMISSION_REQUESTS";
export const SET_PERMISSION_REQUESTS_COUNT = "SET_PERMISSION_REQUESTS_COUNT";
export const SET_PERMISSION_REQUESTS_HISTORY = "SET_PERMISSION_REQUESTS_HISTORY";
export const SET_PERMISSION_REQUESTS_HISTORY_SORTING = "SET_PERMISSION_REQUESTS_HISTORY_SORTING";
export const SET_PERMISSION_REQUESTS_HISTORY_FILTERS = "SET_PERMISSION_REQUESTS_HISTORY_FILTERS";

/** Profile */
export const USERINFO_ACTION_BEFORE = "USERINFO_ACTION_BEFORE";
export const USERINFO_ACTION_FINISH = "USERINFO_ACTION_FINISH";
export const SET_USERINFO = "SET_USERINFO";
export const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
export const RESET_USER_CURRENCY = "RESET_USER_CURRENCY";
export const SET_CURRENCY_TYPE = "SET_CURRENCY_TYPE";
export const SET_USER_CURRENCY = "SET_USER_CURRENCY";
export const SET_USER_MAINTENANCE_MODE = "SET_USER_MAINTENANCE_MODE";
export const PROFILE_ACTION_BEFORE = "PROFILE_ACTION_BEFORE";
export const PROFILE_ACTION_FINISH = "PROFILE_ACTION_FINISH";
export const SET_PROFILE = "SET_PROFILE";
export const PROFILE_SAVE_ACTION_BEFORE = "PROFILE_SAVE_ACTION_BEFORE";
export const PROFILE_SAVE_ACTION_FINISH = "PROFILE_SAVE_ACTION_FINISH";
export const PASSWORD_SETTINGS_ACTION_BEFORE = "PASSWORD_SETTINGS_ACTION_BEFORE";
export const PASSWORD_SETTINGS_ACTION_FINISH = "PASSWORD_SETTINGS_ACTION_FINISH";
export const SET_PASSWORD_SETTINGS = "SET_PASSWORD_SETTINGS";
export const CHANGE_PASSWORD_ACTION_BEFORE = "CHANGE_PASSWORD_ACTION_BEFORE";
export const CHANGE_PASSWORD_ACTION_FINISH = "CHANGE_PASSWORD_ACTION_FINISH";

/** Permissions */
export const PERMISSIONS_ACTION_BEFORE = "PERMISSIONS_ACTION_BEFORE";
export const PERMISSIONS_ACTION_FINISH = "PERMISSIONS_ACTION_FINISH";
export const PERMISSIONS_ACTION_SET_PERMISSIONS = "PERMISSIONS_ACTION_SET_PERMISSIONS";
export const PERMISSION_RESOURCES_ACTION_BEFORE = "PERMISSION_RESOURCES_ACTION_BEFORE";
export const PERMISSION_RESOURCES_ACTION_FINISH = "PERMISSION_RESOURCES_ACTION_FINISH";
export const PERMISSIONS_ACTION_SET_RESOURCES = "PERMISSIONS_ACTION_SET_RESOURCES";

/** Permission Groups */
export const PERMISSION_GROUPS_BEFORE = "PERMISSION_GROUPS_BEFORE";
export const PERMISSION_GROUPS_FINISH = "PERMISSION_GROUPS_FINISH";
export const PERMISSION_GROUPS_SAVE_BEFORE = "PERMISSION_GROUPS_SAVE_BEFORE";
export const PERMISSION_GROUPS_SAVE_FINISH = "PERMISSION_GROUPS_SAVE_FINISH";
export const PERMISSION_GROUPS_SET_MY_GROUPS = "PERMISSION_GROUPS_SET_MY_GROUPS";
export const PERMISSION_GROUPS_SET_MY_GROUPS_SORTING = "PERMISSION_GROUPS_SET_MY_GROUPS_SORTING";
export const PERMISSION_GROUPS_SET_GENERAL_INFO = "PERMISSION_GROUPS_SET_GENERAL_INFO";
export const PERMISSION_GROUPS_SET_QUICK_VIEW_USERS = "PERMISSION_GROUPS_SET_QUICK_VIEW_USERS";
export const PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING = "PERMISSION_GROUPS_SET_QUICK_VIEW_USERS_SORTING";

/** Betshop Performance Report */
export const BETSHOP_PERFORMANCE_REPORT_BEFORE = "BETSHOP_PERFORMANCE_REPORT_BEFORE";
export const BETSHOP_PERFORMANCE_REPORT_FINISH = "BETSHOP_PERFORMANCE_REPORT_FINISH";
export const SET_BETSHOP_PERFORMANCE_REPORT = "SET_BETSHOP_PERFORMANCE_REPORT";
export const SET_BETSHOP_PERFORMANCE_REPORT_FILTERS = "SET_BETSHOP_PERFORMANCE_REPORT_FILTERS";

/** Project Performance Report */
export const PROJECT_PERFORMANCE_REPORT_BEFORE = "PROJECT_PERFORMANCE_REPORT_BEFORE";
export const PROJECT_PERFORMANCE_REPORT_FINISH = "PROJECT_PERFORMANCE_REPORT_FINISH";
export const SET_PROJECT_PERFORMANCE_REPORT = "SET_PROJECT_PERFORMANCE_REPORT";
export const SET_PROJECT_PERFORMANCE_REPORT_FILTERS = "SET_PROJECT_PERFORMANCE_REPORT_FILTERS";

/** Partner Online Report */
export const PARTNER_ONLINE_REPORT_BEFORE = "PARTNER_ONLINE_REPORT_BEFORE";
export const PARTNER_ONLINE_REPORT_FINISH = "PARTNER_ONLINE_REPORT_FINISH";
export const SET_PARTNER_ONLINE_REPORT = "SET_PARTNER_ONLINE_REPORT";
export const SET_PARTNER_ONLINE_REPORT_FILTERS = "SET_PARTNER_ONLINE_REPORT_FILTERS";
export const SET_PARTNER_ONLINE_REPORT_SORTING = "SET_PARTNER_ONLINE_REPORT_SORTING";

/** Dashboard */
export const DASHBOARD_PLAYERS_ACTIVITIES_BEFORE = "DASHBOARD_PLAYERS_ACTIVITIES_BEFORE";
export const DASHBOARD_PLAYERS_ACTIVITIES_FINISH = "DASHBOARD_PLAYERS_ACTIVITIES_FINISH";
export const DASHBOARD_SET_PLAYERS_ACTIVITIES = "DASHBOARD_SET_PLAYERS_ACTIVITIES";
export const DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE = "DASHBOARD_SET_ONLINE_PLAYERS_COUNT_BEFORE";
export const DASHBOARD_SET_ONLINE_PLAYERS_COUNT = "DASHBOARD_SET_ONLINE_PLAYERS_COUNT";
export const DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE = "DASHBOARD_SET_PENDING_BETSLIPS_COUNT_BEFORE";
export const DASHBOARD_SET_PENDING_BETSLIPS_COUNT = "DASHBOARD_SET_PENDING_BETSLIPS_COUNT";
export const DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE = "DASHBOARD_BETSLIPS_ACTIVITIES_BEFORE";
export const DASHBOARD_BETSLIPS_ACTIVITIES_FINISH = "DASHBOARD_BETSLIPS_ACTIVITIES_FINISH";
export const DASHBOARD_SET_BETSLIPS_ACTIVITIES = "DASHBOARD_SET_BETSLIPS_ACTIVITIES";
export const DASHBOARD_SET_FILTERS = "DASHBOARD_SET_FILTERS";
export const DASHBOARD_TOP_MARKETS_BEFORE = "DASHBOARD_TOP_MARKETS_BEFORE";
export const DASHBOARD_TOP_MARKETS_FINISH = "DASHBOARD_TOP_MARKETS_FINISH";
export const DASHBOARD_SET_TOP_MARKETS = "DASHBOARD_SET_TOP_MARKETS";
export const DASHBOARD_GAMES_PERFORMANCE_BEFORE = "DASHBOARD_GAMES_PERFORMANCE_BEFORE";
export const DASHBOARD_GAMES_PERFORMANCE_FINISH = "DASHBOARD_GAMES_PERFORMANCE_FINISH";
export const DASHBOARD_SET_GAMES_PERFORMANCE = "DASHBOARD_SET_GAMES_PERFORMANCE";
export const DASHBOARD_TOP_PLAYERS_BEFORE = "DASHBOARD_TOP_PLAYERS_BEFORE";
export const DASHBOARD_TOP_PLAYERS_FINISH = "DASHBOARD_TOP_PLAYERS_FINISH";
export const DASHBOARD_SET_TOP_PLAYERS = "DASHBOARD_SET_TOP_PLAYERS";
export const DASHBOARD_SET_TOP_PLAYERS_SORTING = "DASHBOARD_SET_TOP_PLAYERS_SORTING";
export const DASHBOARD_PARTNERS_REVENUE_BEFORE = "DASHBOARD_PARTNERS_REVENUE_BEFORE";
export const DASHBOARD_PARTNERS_REVENUE_FINISH = "DASHBOARD_PARTNERS_REVENUE_FINISH";
export const DASHBOARD_SET_PARTNERS_REVENUE = "DASHBOARD_SET_PARTNERS_REVENUE";
export const DASHBOARD_SET_PARTNERS_REVENUE_FILTER = "DASHBOARD_SET_PARTNERS_REVENUE_FILTER";
export const DASHBOARD_CALCULATE_GAMES_PERFORMANCE = "DASHBOARD_CALCULATE_GAMES_PERFORMANCE";
export const DASHBOARD_SET_APIKEY_NAMES = "DASHBOARD_SET_APIKEY_NAMES";
export const DASHBOARD_SET_APIKEY_NAMES_BEFORE = "DASHBOARD_SET_APIKEY_NAMES_BEFORE";
export const DASHBOARD_SET_APIKEY_NAMES_FINISH = "DASHBOARD_SET_APIKEY_NAMES_FINISH";
export const DASHBOARD_SET_BETSHOP_NAMES = "DASHBOARD_SET_BETSHOP_NAMES";
export const DASHBOARD_SET_BETSHOP_NAMES_BEFORE = "DASHBOARD_SET_BETSHOP_NAMES_BEFORE";
export const DASHBOARD_SET_BETSHOP_NAMES_FINISH = "DASHBOARD_SET_BETSHOP_NAMES_FINISH";

/** Notifications */
export const NOTIFICATIONS_ACTION_BEFORE = "NOTIFICATIONS_ACTION_BEFORE";
export const NOTIFICATIONS_ACTION_FINISH = "NOTIFICATIONS_ACTION_FINISH";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

/** Betslips */
export const BETSLIPS_BEFORE = "BETSLIPS_BEFORE";
export const BETSLIPS_FINISH = "BETSLIPS_FINISH";
export const BETSLIPS_SAVE_BEFORE = "BETSLIPS_SAVE_BEFORE";
export const BETSLIPS_SAVE_FINISH = "BETSLIPS_SAVE_FINISH";

export const SET_PENDING_BETSLIPS = "SET_PENDING_BETSLIPS";
export const SET_PENDING_BETSLIPS_SORTING = "SET_PENDING_BETSLIPS_SORTING";
export const SET_PENDING_BETSLIPS_FILTERS = "SET_PENDING_BETSLIPS_FILTERS";

export const SET_SETTLED_BETSLIPS = "SET_SETTLED_BETSLIPS";
export const SET_SETTLED_BETSLIPS_SORTING = "SET_SETTLED_BETSLIPS_SORTING";
export const SET_SETTLED_BETSLIPS_FILTERS = "SET_SETTLED_BETSLIPS_FILTERS";

export const SET_BETSLIPS_REQUESTS = "SET_BETSLIPS_REQUESTS";
export const SET_BETSLIPS_REQUESTS_SORTING = "SET_BETSLIPS_REQUESTS_SORTING";
export const SET_BETSLIPS_REQUESTS_FILTERS = "SET_BETSLIPS_REQUESTS_FILTERS";

export const SET_BETSLIPS_DB_CACHE_COMPARE = "SET_BETSLIPS_DB_CACHE_COMPARE";

/** Events */
export const EVENTS_BEFORE = "EVENTS_BEFORE";
export const EVENTS_FINISH = "EVENTS_FINISH";
export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENTS_SORTING = "SET_EVENTS_SORTING";
export const SET_EVENTS_FILTERS = "SET_EVENTS_FILTERS";
export const EVENT_BEFORE = "EVENT_BEFORE";
export const EVENT_FINISH = "EVENT_FINISH";
export const SET_EVENT = "SET_EVENT";

/** Players */
export const PLAYERS_BEFORE = "PLAYERS_BEFORE";
export const PLAYERS_FINISH = "PLAYERS_FINISH";
export const PLAYERS_SAVE_BEFORE = "PLAYERS_SAVE_BEFORE";
export const PLAYERS_SAVE_FINISH = "PLAYERS_SAVE_FINISH";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_PLAYERS_SORTING = "SET_PLAYERS_SORTING";
export const SET_PLAYERS_FILTERS = "SET_PLAYERS_FILTERS";
export const CHANGE_PLAYER_STATE = "CHANGE_PLAYER_STATE";
export const PLAYERS_QUICK_INFO_BEFORE = "PLAYERS_QUICK_INFO_BEFORE";
export const PLAYERS_QUICK_INFO_FINISH = "PLAYERS_QUICK_INFO_FINISH";
export const SET_PLAYER_QUICK_INFO = "SET_PLAYER_QUICK_INFO";
export const SET_PLAYER_GENERAL_INFO = "SET_PLAYER_GENERAL_INFO";
export const PLAYERS_SET_PLAYER_SESSIONS = "PLAYERS_SET_PLAYER_SESSIONS";
export const PLAYERS_SET_SESSIONS_SORTING = "PLAYERS_SET_SESSIONS_SORTING";
export const PLAYERS_SET_SESSIONS_FILTERS = "PLAYERS_SET_SESSIONS_FILTERS";
export const SET_PLAYER_PENDING_BETSLIPS = "SET_PLAYER_PENDING_BETSLIPS";
export const SET_PLAYER_PENDING_BETSLIPS_SORTING = "SET_PLAYER_PENDING_BETSLIPS_SORTING";
export const SET_PLAYER_PENDING_BETSLIPS_FILTERS = "SET_PLAYER_PENDING_BETSLIPS_FILTERS";
export const SET_PLAYER_SETTLED_BETSLIPS = "SET_PLAYER_SETTLED_BETSLIPS";
export const SET_PLAYER_SETTLED_BETSLIPS_SORTING = "SET_PLAYER_SETTLED_BETSLIPS_SORTING";
export const SET_PLAYER_SETTLED_BETSLIPS_FILTERS = "SET_PLAYER_SETTLED_BETSLIPS_FILTERS";

/** Transactions */
export const TRANSACTIONS_BEFORE = "TRANSACTIONS_BEFORE";
export const TRANSACTIONS_FINISH = "TRANSACTIONS_FINISH";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TRANSACTIONS_SORTING = "SET_TRANSACTIONS_SORTING";
export const SET_TRANSACTIONS_FILTERS = "SET_TRANSACTIONS_FILTERS";
export const TRANSACTION_HISTORY_BEFORE = "TRANSACTION_HISTORY_BEFORE";
export const TRANSACTION_HISTORY_FINISH = "TRANSACTION_HISTORY_FINISH";
export const TRANSACTION_RESEND_BEFORE = "TRANSACTION_RESEND_BEFORE";
export const TRANSACTION_RESEND_FINISH = "TRANSACTION_RESEND_FINISH";
export const SET_TRANSACTION_HISTORY = "SET_TRANSACTION_HISTORY";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const SET_ROLLBACKS_ACTIVE_TAB = "SET_ROLLBACKS_ACTIVE_TAB";
export const SET_RESULTS_ACTIVE_TAB = "SET_RESULTS_ACTIVE_TAB";
export const SET_TRANSACTIONS_ACTIVE_TAB = "SET_TRANSACTIONS_ACTIVE_TAB";
export const PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_BEFORE = "PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_BEFORE"
export const PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_FINISH = "PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_FINISH"
export const SET_PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE = "SET_PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE"

/** Bonuses */
export const BONUSES_BEFORE = "BONUSES_BEFORE";
export const BONUSES_FINISH = "BONUSES_FINISH";
export const JACKPOT_BONUSES_BEFORE = "JACKPOT_BONUSES_BEFORE";
export const JACKPOT_BONUSES_FINISH = "JACKPOT_BONUSES_FINISH";
export const BONUS_BEFORE = "BONUS_BEFORE";
export const BONUS_FINISH = "BONUS_FINISH";
export const JACKPOT_BONUS_BEFORE = "JACKPOT_BONUS_BEFORE";
export const JACKPOT_BONUS_FINISH = "JACKPOT_BONUS_FINISH";
export const SET_STANDARD_BONUSES = "SET_STANDARD_BONUSES";
export const SET_JACKPOT_BONUSES = "SET_JACKPOT_BONUSES";
export const SET_JACKPOT_BONUSES_FILTERS = "SET_JACKPOT_BONUSES_FILTERS";
export const SET_JACKPOT_BONUSES_SORTING = "SET_JACKPOT_BONUSES_SORTING";
export const SET_JACKPOT_QUICK_INFO = "SET_JACKPOT_QUICK_INFO";
export const SET_JACKPOT_HISTORY_BEFORE = "SET_JACKPOT_HISTORY_BEFORE";
export const SET_JACKPOT_HISTORY_FINISH = "SET_JACKPOT_HISTORY_FINISH";
export const SET_JACKPOT_HISTORY = "SET_JACKPOT_HISTORY";
export const SET_JACKPOT_HISTORY_SORTING = "SET_JACKPOT_HISTORY_SORTING";
export const SET_JACKPOT_HISTORY_FILTERS = "SET_JACKPOT_HISTORY_FILTERS";
export const SET_BONUSES_FILTERS = "SET_BONUSES_FILTERS";
export const SET_BONUSES_SORTING = "SET_BONUSES_SORTING";
export const SET_BONUS_GENERAL_INFO = "SET_BONUS_GENERAL_INFO";
export const SET_JACKPOT_GENERAL_INFO = "SET_JACKPOT_GENERAL_INFO";
export const CHANGE_STANDARD_BONUS_STATE = "CHANGE_STANDARD_BONUS_STATE";
export const CHANGE_JACKPOT_BONUS_STATE = "CHANGE_JACKPOT_BONUS_STATE";
export const SET_BONUS_AVAILABLE_BETSHOPS = "SET_BONUS_AVAILABLE_BETSHOPS"
export const SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS = "SET_BONUS_JACKPOT_AVAILABLE_BETSHOPS"
export const SET_BONUS_AVAILABLE_GAMES = "SET_BONUS_AVAILABLE_GAMES"
export const SET_BONUS_JACKPOT_AVAILABLE_GAMES = "SET_BONUS_JACKPOT_AVAILABLE_GAMES"
export const SET_BONUS_QUICK_INFO = "SET_BONUS_QUICK_INFO";
export const SET_BONUS_PRINT_VOUCHERS = "SET_BONUS_PRINT_VOUCHERS";
export const SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS = "SET_BONUS_PRINT_VOUCHERS_TRANSLATIONS";

/** Maintenance Mode */
export const MAINTENANCE_MODE_BEFORE = "MAINTENANCE_MODE_BEFORE";
export const MAINTENANCE_MODE_FINISH = "MAINTENANCE_MODE_FINISH";
export const SET_MAINTENANCE_MODE = "SET_MAINTENANCE_MODE";

/** Operators */
export const SET_OPERATORS = "SET_OPERATORS";
export const OPERATORS_BEFORE = "OPERATORS_BEFORE";
export const OPERATORS_FINISH = "OPERATORS_FINISH";
export const OPERATORS_SET_SORTING = "OPERATORS_SET_SORTING";

/** Requests */
export const REQUESTS_ACTION_BEFORE = "REQUESTS_ACTION_BEFORE";
export const REQUESTS_ACTION_FINISH = "REQUESTS_ACTION_FINISH";
export const REQUEST_ACTION_BEFORE = "REQUEST_ACTION_BEFORE";
export const REQUEST_ACTION_FINISH = "REQUEST_ACTION_FINISH";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_REQUESTS_SORTING = "SET_REQUESTS_SORTING";
export const SET_REQUESTS_FILTERS = "SET_REQUESTS_FILTERS";
export const SET_REQUEST = "SET_REQUEST";

/** Errors */
export const ERRORS_ACTION_BEFORE = "ERRORS_ACTION_BEFORE";
export const ERRORS_ACTION_FINISH = "ERRORS_ACTION_FINISH";
export const ERROR_ACTION_BEFORE = "ERROR_ACTION_BEFORE";
export const ERROR_ACTION_FINISH = "ERROR_ACTION_FINISH";
export const SET_ERRORS = "SET_ERRORS";
export const SET_ERRORS_SORTING = "SET_ERRORS_SORTING";
export const SET_ERRORS_FILTERS = "SET_ERRORS_FILTERS";
export const SET_ERROR = "SET_ERROR";

/** Jobs */
export const JOBS_ACTION_BEFORE = "JOBS_ACTION_BEFORE";
export const JOBS_ACTION_FINISH = "JOBS_ACTION_FINISH";
export const JOBS_ACTION_SET_JOBS = "JOBS_ACTION_SET_JOBS";
export const JOBS_ACTION_UPDATE_JOB = "JOBS_ACTION_UPDATE_JOB";
export const JOBS_ACTION_SAVE_BEFORE = "JOBS_ACTION_SAVE_BEFORE";
export const JOBS_ACTION_SAVE_FINISHED = "JOBS_ACTION_SAVE_FINISHED";
export const JOBS_ACTION_SET_SETTINGS = "JOBS_ACTION_SET_SETTINGS";

/** Monitoring */
export const MONITORING_ACTION_BEFORE = "MONITORING_ACTION_BEFORE";
export const MONITORING_ACTION_FINISH = "MONITORING_ACTION_FINISH";
export const MONITORING_ACTION_SERVICE_BEFORE = "MONITORING_ACTION_SERVICE_BEFORE";
export const MONITORING_ACTION_SERVICE_FINISH = "MONITORING_ACTION_SERVICE_FINISH";
export const MONITORING_SET_SERVICES = "MONITORING_SET_SERVICES";
export const MONITORING_SET_SERVICE = "MONITORING_SET_SERVICE";

/** Streams */
export const STREAMS_ACTION_BEFORE = "STREAMS_ACTION_BEFORE";
export const STREAMS_ACTION_FINISH = "STREAMS_ACTION_FINISH";
export const SET_DEVELOPER_SPACE_STREAMS = "SET_DEVELOPER_SPACE_STREAMS";

/** Stream Servers */
export const GET_ALL_STREAM_SERVERS = "GET_ALL_STREAM_SERVERS";
export const SET_ALL_STREAM_SERVERS = "SET_ALL_STREAM_SERVERS";
export const GET_ALL_STREAM_SERVERS_FINISH = "GET_ALL_STREAM_SERVERS_FINISH";

export const SET_AVAILABLE_STREAM_SERVER_GAMES = "SET_AVAILABLE_STREAM_SERVER_GAMES";

/** Post Deployment actions */
export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE";
export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH";
export const SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES = "SET_POST_DEPLOYMENT_TRANSLATION_LANGUAGE_CODES";

/** Betslip Corrections actions */
export const SET_BETSLIP_CORRECTION_ACTION_BEFORE = "SET_BETSLIP_CORRECTION_ACTION_BEFORE";
export const SET_BETSLIP_CORRECTION_ACTION_FINISH = "SET_BETSLIP_CORRECTION_ACTION_FINISH";
export const SET_BETSLIP_CORRECTIONS = "SET_BETSLIP_CORRECTIONS";
export const SET_BETSLIP_CORRECTIONS_FILTERS = "SET_BETSLIP_CORRECTIONS_FILTERS";
export const SET_BETSLIP_CORRECTIONS_SORTING = "SET_BETSLIP_CORRECTIONS_SORTING";

// -------------------------------

/** Global Partners Dropdown */
export const SET_GLOBAL_PARTNER_ID = "SET_GLOBAL_PARTNER_ID";
export const SET_GLOBAL_PARTNERS = "SET_GLOBAL_PARTNERS";

/** Partner Edit */
export const PARTNER_ACTION_BEFORE = "PARTNER_ACTION_BEFORE";
export const PARTNER_ACTION_FINISH = "PARTNER_ACTION_FINISH";
export const PARTNER_RESET = "PARTNER_RESET";

export const PARTNER_ACTION_SET_GENERAL_INFO = "PARTNER_ACTION_SET_GENERAL_INFO";

export const PARTNER_ACTION_SET_ONLINE_CONFIGS = "PARTNER_ACTION_SET_ONLINE_CONFIGS";
export const PARTNER_ACTION_SET_ONLINE_RTP = "PARTNER_ACTION_SET_ONLINE_RTP";

export const PARTNER_ACTION_SET_RETAIL_CONFIGS = "PARTNER_ACTION_SET_RETAIL_CONFIGS";
export const PARTNER_ACTION_SET_RETAIL_RTP = "PARTNER_ACTION_SET_RETAIL_RTP";

export const PARTNER_SET_SECURITY_PASSWORD_SETTINGS = "PARTNER_SET_SECURITY_PASSWORD_SETTINGS";
export const PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS = "PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS";
export const PARTNER_SET_SECURITY_IP_RESTRICTION = "PARTNER_SET_SECURITY_IP_RESTRICTION";
export const PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS = "PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS";

export const PARTNER_CURRENCIES_BEFORE = "PARTNER_CURRENCIES_BEFORE";
export const PARTNER_CURRENCIES_FINISH = "PARTNER_CURRENCIES_FINISH";
export const PARTNER_SET_CURRENCIES = "PARTNER_SET_CURRENCIES";
export const PARTNER_ADD_CURRENCY = "PARTNER_ADD_CURRENCY";
export const PARTNER_UPDATE_CURRENCY = "PARTNER_UPDATE_CURRENCY";
export const PARTNER_DELETE_CURRENCY = "PARTNER_DELETE_CURRENCY";
export const PARTNER_AVAILABLE_CURRENCIES_BEFORE = "PARTNER_AVAILABLE_CURRENCIES_BEFORE";
export const PARTNER_AVAILABLE_CURRENCIES_FINISH = "PARTNER_AVAILABLE_CURRENCIES_FINISH";
export const PARTNER_SET_AVAILABLE_CURRENCIES = "PARTNER_SET_AVAILABLE_CURRENCIES";

export const PARTNER_LANGUAGES_BEFORE = "PARTNER_LANGUAGES_BEFORE";
export const PARTNER_LANGUAGES_FINISH = "PARTNER_LANGUAGES_FINISH";
export const PARTNER_SET_LANGUAGES = "PARTNER_SET_LANGUAGES";
export const PARTNER_ADD_LANGUAGE = "PARTNER_ADD_LANGUAGE";
export const PARTNER_UPDATE_LANGUAGE = "PARTNER_UPDATE_LANGUAGE";
export const PARTNER_DELETE_LANGUAGE = "PARTNER_DELETE_LANGUAGE";
export const PARTNER_AVAILABLE_LANGUAGES_BEFORE = "PARTNER_AVAILABLE_LANGUAGES_BEFORE";
export const PARTNER_AVAILABLE_LANGUAGES_FINISH = "PARTNER_AVAILABLE_LANGUAGES_FINISH";
export const PARTNER_SET_AVAILABLE_LANGUAGES = "PARTNER_SET_AVAILABLE_LANGUAGES";

export const PARTNER_WALLET_BEFORE = "PARTNER_WALLET_BEFORE";
export const PARTNER_WALLET_FINISH = "PARTNER_WALLET_FINISH";
export const PARTNER_WALLET_SAVE_BEFORE = "PARTNER_WALLET_SAVE_BEFORE";
export const PARTNER_WALLET_SAVE_FINISH = "PARTNER_WALLET_SAVE_FINISH";
export const PARTNER_SET_WALLET = "PARTNER_SET_WALLET";
export const PARTNER_UPDATE_WALLET = "PARTNER_UPDATE_WALLET";

export const PARTNER_ACTIVITY_BEFORE = "PARTNER_ACTIVITY_BEFORE";
export const PARTNER_ACTIVITY_FINISH = "PARTNER_ACTIVITY_FINISH";
export const PARTNER_SET_PARTNER_ACTIVITY = "PARTNER_SET_PARTNER_ACTIVITY";
export const PARTNER_SET_ACTIVITY_SORTING = "PARTNER_SET_ACTIVITY_SORTING";
export const PARTNER_SET_ACTIVITY_FILTERS = "PARTNER_SET_ACTIVITY_FILTERS";

/** Games */
export const GAMES_BEFORE = "GAMES_BEFORE";
export const GAMES_FINISH = "GAMES_FINISH";
export const GAME_BEFORE = "GAME_BEFORE";
export const GAME_FINISH = "GAME_FINISH";
export const SET_GAMES = "SET_GAMES";
export const DELETE_GAME = "DELETE_GAME";
export const CHANGE_GAME_STATE = "CHANGE_GAME_STATE";
export const SET_GAMES_FILTERS = "SET_GAMES_FILTERS";
export const SET_GAMES_SORTING = "SET_GAMES_SORTING";
export const SET_GAME_DETAILS = "SET_GAME_DETAILS";
export const SET_GAME_GENERAL_INFO = "SET_GAME_GENERAL_INFO";
export const RESET_GAME_GENERAL_INFO = "RESET_GAME_GENERAL_INFO";
export const SET_GAME_FOOTBALL_SCENE_PROBABILITIES = "SET_GAME_FOOTBALL_SCENE_PROBABILITIES";
export const SET_PENALTY_GOAL_MISS_PROBABILITIES = "SET_PENALTY_GOAL_MISS_PROBABILITIES";
export const SET_GAME_FOOTBALL_HANDICAP_PROBABILITY = "SET_GAME_FOOTBALL_HANDICAP_PROBABILITY";
export const SET_GAME_STRENGTHS = "SET_GAME_STRENGTHS";
export const SET_GAME_COMPETITIONS_AND_RACES = "SET_GAME_COMPETITIONS_AND_RACES";
export const SET_GAME_FOOTBALL_TEAMS = "SET_GAME_FOOTBALL_TEAMS";
export const SET_GAME_PENALTY_TEAMS = "SET_GAME_PENALTY_TEAMS";
export const SET_GAME_RTPS = "SET_GAME_RTPS";
export const SET_GAME_BANNERS = "SET_GAME_BANNERS";
export const SET_GAME_LIVE_MONITOR_LOGO_ID = "SET_GAME_LIVE_MONITOR_LOGO_ID";
export const SET_GAME_STREAM_URL = "SET_GAME_STREAM_URL";
export const SET_GAME_STREAM_CONFIGURATION = "SET_GAME_STREAM_CONFIGURATION";
export const GAME_ALL_TEAMS_BEFORE = "GAME_ALL_TEAMS_BEFORE";
export const GAME_ALL_TEAMS_FINISH = "GAME_ALL_TEAMS_FINISH";
export const SET_GAME_ALL_TEAMS  = "SET_GAME_ALL_TEAMS ";
export const GAME_ADD_TEAMS_BEFORE = "GAME_ADD_TEAMS_BEFORE";
export const GAME_ADD_TEAMS_FINISH = "GAME_ADD_TEAMS_FINISH";

/** Boxes */
export const BOXES_BEFORE = "BOXES_BEFORE";
export const BOXES_FINISH = "BOXES_FINISH";
export const BOX_BEFORE = "BOX_BEFORE";
export const BOX_FINISH = "BOX_FINISH";
export const SET_BOXES = "SET_BOXES";
export const BOXES_ACTION_SET_BOXES_SORTING = "BOXES_ACTION_SET_BOXES_SORTING";
export const SET_BOX_GENERAL_INFO = "SET_BOX_GENERAL_INFO";
export const SET_BOX_GAMES = "SET_BOX_GAMES";
export const SET_BOX_ADD_GAME = "SET_BOX_ADD_GAME";
export const SET_BOX_UPDATE_GAME = "SET_BOX_UPDATE_GAME";
export const SET_BOX_DELETE_GAME = "SET_BOX_DELETE_GAME";
export const BOX_GAMES_BEFORE = "BOX_GAMES_BEFORE";
export const BOX_GAMES_FINISH = "BOX_GAMES_FINISH";
export const BOX_GAMES_RTP_BEFORE = "BOX_GAMES_RTP_BEFORE";
export const BOX_GAMES_RTP_FINISH = "BOX_GAMES_RTP_FINISH";
export const SET_BOX_GAME_RTP = "SET_BOX_GAME_RTP";

/** Betshops */
export const BETSHOPS_ACTION_BEFORE = "BETSHOPS_ACTION_BEFORE";
export const BETSHOPS_ACTION_FINISH = "BETSHOPS_ACTION_FINISH";
export const BETSHOPS_ACTION_SET_BETSHOPS = "BETSHOPS_ACTION_SET_BETSHOPS";
export const BETSHOP_SAVE_ACTION_BEFORE = "BETSHOP_SAVE_ACTION_BEFORE";
export const BETSHOP_SAVE_ACTION_FINISH = "BETSHOP_SAVE_ACTION_FINISH";
export const BETSHOPS_SET_FILTERS = "BETSHOPS_SET_FILTERS";
export const BETSHOPS_SET_SORTING = "BETSHOPS_SET_SORTING";
export const SET_BETSHOP_GENERAL_INFO = "SET_BETSHOP_GENERAL_INFO";
export const SET_BETSHOP_LOGO_ID = "SET_BETSHOP_LOGO_ID";
export const BETSHOPS_ACTION_SET_BETSHOP_ENABLED = "BETSHOPS_ACTION_SET_BETSHOP_ENABLED";
export const SET_BETSHOP_CURRENCY = "SET_BETSHOP_CURRENCY";
export const SET_BETSHOP_BOXES = "SET_BETSHOP_BOXES";
export const ADD_BETSHOP_BOX = "ADD_BETSHOP_BOX";
export const UPDATE_BETSHOP_BOX = "UPDATE_BETSHOP_BOX";
export const DELETE_BETSHOP_BOX = "DELETE_BETSHOP_BOX";
export const BETSHOP_BOXES_BEFORE = "BETSHOP_BOXES_BEFORE";
export const BETSHOP_BOXES_FINISH = "BETSHOP_BOXES_FINISH";
export const SET_BETSHOP_AVAILABLE_BOXES = "SET_BETSHOP_AVAILABLE_BOXES";
export const BETSHOP_AVAILABLE_BOXES_BEFORE = "BETSHOP_AVAILABLE_BOXES_BEFORE";
export const BETSHOP_AVAILABLE_BOXES_FINISH = "BETSHOP_AVAILABLE_BOXES_FINISH";
export const SET_BETSHOP_OPERATING_HOURS = "SET_BETSHOP_OPERATING_HOURS";
export const SET_BETSHOP_LIMIT_SETTINGS = "SET_BETSHOP_LIMIT_SETTINGS";
export const SET_BETSHOP_BONUS_CONFIGS = "SET_BETSHOP_BONUS_CONFIGS";
export const SET_BETSHOP_UI = "SET_BETSHOP_UI";
export const RESET_BETSHOP_UI = "RESET_BETSHOP_UI";

export const BETSHOP_ACTIVITY_BEFORE = "BETSHOP_ACTIVITY_BEFORE";
export const BETSHOP_ACTIVITY_FINISH = "BETSHOP_ACTIVITY_FINISH";
export const BETSHOP_SET_BETSHOP_ACTIVITY = "BETSHOP_SET_BETSHOP_ACTIVITY";
export const BETSHOP_SET_ACTIVITY_SORTING = "BETSHOP_SET_ACTIVITY_SORTING";
export const BETSHOP_SET_ACTIVITY_FILTERS = "BETSHOP_SET_ACTIVITY_FILTERS";

export const BETSHOP_SETTLEMENT_REPORT_BEFORE = "BETSHOP_SETTLEMENT_REPORT_BEFORE";
export const BETSHOP_SETTLEMENT_REPORT_FINISH = "BETSHOP_SETTLEMENT_REPORT_FINISH";
export const BETSHOP_SET_SETTLEMENT_REPORT = "BETSHOP_SET_SETTLEMENT_REPORT";

export const BETSHOP_SESSIONS_BEFORE = "BETSHOP_SESSIONS_BEFORE";
export const BETSHOP_SESSIONS_FINISH = "BETSHOP_SESSIONS_FINISH";
export const BETSHOP_SET_BETSHOP_SESSIONS = "BETSHOP_SET_BETSHOP_SESSIONS";
export const BETSHOP_SET_SESSIONS_SORTING = "BETSHOP_SET_SESSIONS_SORTING";
export const BETSHOP_SET_SESSIONS_FILTERS = "BETSHOP_SET_SESSIONS_FILTERS";

export const BETSHOP_WALLET_BEFORE = "BETSHOP_WALLET_BEFORE";
export const BETSHOP_WALLET_FINISH = "BETSHOP_WALLET_FINISH";
export const BETSHOP_WALLET_SAVE_BEFORE = "BETSHOP_WALLET_SAVE_BEFORE";
export const BETSHOP_WALLET_SAVE_FINISH = "BETSHOP_WALLET_SAVE_FINISH";
export const BETSHOP_SET_WALLET = "BETSHOP_SET_WALLET";
export const BETSHOP_UPDATE_WALLET = "BETSHOP_UPDATE_WALLET";

/** Cashiers */
export const CASHIERS_ACTION_BEFORE = "CASHIERS_ACTION_BEFORE";
export const CASHIERS_ACTION_FINISH = "CASHIERS_ACTION_FINISH";
export const CASHIERS_ACTION_SET_CASHIERS = "CASHIERS_ACTION_SET_CASHIERS";
export const CASHIERS_SAVE_ACTION_BEFORE = "CASHIERS_SAVE_ACTION_BEFORE";
export const CASHIERS_SAVE_ACTION_FINISH = "CASHIERS_SAVE_ACTION_FINISH";
export const CASHIERS_SET_SORTING = "CASHIERS_SET_SORTING";
export const CASHIERS_SET_FILTERS = "CASHIERS_SET_FILTERS";
export const SET_CASHIER_GENERAL_INFO = "SET_CASHIER_GENERAL_INFO";
export const CHANGE_CASHIER_STATE = "CHANGE_CASHIER_STATE";
export const CASHIERS_AVAILABLE_BETSHOPS_BEFORE = "CASHIERS_AVAILABLE_BETSHOPS_BEFORE";
export const CASHIERS_AVAILABLE_BETSHOPS_FINISH = "CASHIERS_AVAILABLE_BETSHOPS_FINISH";
export const CASHIERS_SET_AVAILABLE_BETSHOPS = "CASHIERS_SET_AVAILABLE_BETSHOPS";

/** Translation */
export const TRANSLATION_GROUPS_BEFORE = "TRANSLATION_GROUPS_BEFORE";
export const TRANSLATION_GROUPS_FINISH = "TRANSLATION_GROUPS_FINISH";
export const TRANSLATION_SET_GROUPS = "TRANSLATION_SET_GROUPS";
export const TRANSLATIONS_BEFORE = "TRANSLATIONS_BEFORE";
export const TRANSLATIONS_FINISH = "TRANSLATIONS_FINISH";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const TRANSLATION_SAVE_BEFORE = "TRANSLATION_SAVE_BEFORE";
export const TRANSLATION_SAVE_FINISH = "TRANSLATION_SAVE_FINISH";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const TRANSLATION_SET_SYSTEM_DEFAULT = "TRANSLATION_SET_SYSTEM_DEFAULT";
export const TRANSLATIONS_PUBLISH_BEFORE = "TRANSLATIONS_PUBLISH_BEFORE";
export const TRANSLATIONS_PUBLISH_FINISH = "TRANSLATIONS_PUBLISH_FINISH";
export const TRANSLATIONS_UNPUBLISH_BEFORE = "TRANSLATIONS_UNPUBLISH_BEFORE";
export const TRANSLATIONS_UNPUBLISH_FINISH = "TRANSLATIONS_UNPUBLISH_FINISH";
export const SET_LIVE_MONITOR_TRANSLATIONS = "SET_LIVE_MONITOR_TRANSLATIONS";
export const UPDATE_LIVE_MONITOR_TRANSLATION = "UPDATE_LIVE_MONITOR_TRANSLATION";
export const SET_SELECTED_GAME_TYPE = "SET_SELECTED_GAME_TYPE";
export const SET_RULES = "SET_RULES";
export const SET_RULES_HISTORY = "SET_RULES_HISTORY";
export const RULES_SET_SORTING = "RULES_SET_SORTING";
export const SET_RULE_DETAILS = "SET_RULE_DETAILS";
export const RULES_HISTORY_SORTING = "RULES_HISTORY_SORTING";
export const UPDATE_RULE = "UPDATE_RULE";
export const RULE_TRANSLATION_SET_SYSTEM_DEFAULT = "RULE_TRANSLATION_SET_SYSTEM_DEFAULT";
export const ADD_RULE_LANGUAGE_BEFORE = "ADD_RULE_LANGUAGE_BEFORE";
export const ADD_RULE_LANGUAGE_FINISH = "ADD_RULE_LANGUAGE_FINISH";

/** Tickets */
export const TICKETS_BEFORE = "TICKETS_BEFORE";
export const TICKETS_FINISH = "TICKETS_FINISH";
export const TICKETS_SAVE_BEFORE = "TICKETS_SAVE_BEFORE";
export const TICKETS_SAVE_FINISH = "TICKETS_SAVE_FINISH";
export const SET_CURRENT_BET_TICKET = "SET_CURRENT_BET_TICKET";
export const SET_CURRENT_CANCEL_TICKET = "SET_CURRENT_CANCEL_TICKET";
export const SET_CURRENT_PAYOUT_TICKET = "SET_CURRENT_PAYOUT_TICKET";
export const SET_TICKET_LOGO = "SET_TICKET_LOGO";
export const SET_RETAIL_BET_TICKET = "SET_RETAIL_BET_TICKET";
export const SET_RETAIL_CANCEL_TICKET = "SET_RETAIL_CANCEL_TICKET";
export const SET_RETAIL_PAYOUT_TICKET = "SET_RETAIL_PAYOUT_TICKET";

/** Hot keys */
export const HOTKEYS_BEFORE = "HOTKEYS_BEFORE";
export const HOTKEYS_FINISH = "HOTKEYS_FINISH";
export const SET_HOTKEYS = "SET_HOTKEYS";

/** User logs */
export const USER_LOGS_BEFORE = "USER_LOGS_BEFORE";
export const USER_LOGS_FINISH = "USER_LOGS_FINISH";
export const SET_USER_LOGS = "SET_USER_LOGS";
export const SET_USER_LOGS_SORTING = "SET_USER_LOGS_SORTING";
export const SET_USER_LOGS_FILTERS = "SET_USER_LOGS_FILTERS";
export const SET_USER_LOG_DETAILS = "SET_USER_LOG_DETAILS";
export const SET_USER_LOG_RESOURCES = "SET_USER_LOG_RESOURCES";

/** System Currencies */
export const SYSTEM_SET_CURRENCIES = "SYSTEM_SET_CURRENCIES";
export const SYSTEM_SET_AVAILABLE_CURRENCIES = "SYSTEM_SET_AVAILABLE_CURRENCIES";
export const SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES = "SYSTEM_SET_SYSTEM_AVAILABLE_CURRENCIES";
export const SYSTEM_ADD_CURRENCY = "SYSTEM_ADD_CURRENCY";
export const SYSTEM_UPDATE_CURRENCY = "SYSTEM_UPDATE_CURRENCY";
export const SYSTEM_CURRENCIES_BEFORE = "SYSTEM_CURRENCIES_BEFORE";
export const SYSTEM_CURRENCIES_FINISH = "SYSTEM_CURRENCIES_FINISH";
export const SYSTEM_CURRENCY_BEFORE = "SYSTEM_CURRENCY_BEFORE";
export const SYSTEM_CURRENCY_FINISH = "SYSTEM_CURRENCY_FINISH";
export const SYSTEM_AVAILABLE_CURRENCIES_BEFORE = "SYSTEM_AVAILABLE_CURRENCIES_BEFORE";
export const SYSTEM_AVAILABLE_CURRENCIES_FINISH = "SYSTEM_AVAILABLE_CURRENCIES_FINISH";
export const SYSTEM_CURRENCIES_SET_SORTING = "SYSTEM_CURRENCIES_SET_SORTING";
export const SYSTEM_CURRENCIES_SET_FILTERS = "SYSTEM_CURRENCIES_SET_FILTERS";

/** System Languages */
export const SYSTEM_SET_LANGUAGES = "SYSTEM_SET_LANGUAGES";
export const SYSTEM_SET_AVAILABLE_LANGUAGES = "SYSTEM_SET_AVAILABLE_LANGUAGES";
export const SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES = "SYSTEM_SET_SYSTEM_AVAILABLE_LANGUAGES";
export const SYSTEM_ADD_LANGUAGE = "SYSTEM_ADD_LANGUAGE";
export const SYSTEM_DELETE_LANGUAGE = "SYSTEM_DELETE_LANGUAGE";
export const SYSTEM_LANGUAGES_BEFORE = "SYSTEM_LANGUAGES_BEFORE";
export const SYSTEM_LANGUAGES_FINISH = "SYSTEM_LANGUAGES_FINISH";
export const SYSTEM_LANGUAGE_BEFORE = "SYSTEM_LANGUAGE_BEFORE";
export const SYSTEM_LANGUAGE_FINISH = "SYSTEM_LANGUAGE_FINISH";
export const SYSTEM_AVAILABLE_LANGUAGES_BEFORE = "SYSTEM_AVAILABLE_LANGUAGES_BEFORE";
export const SYSTEM_AVAILABLE_LANGUAGES_FINISH = "SYSTEM_AVAILABLE_LANGUAGES_FINISH";
export const SYSTEM_LANGUAGES_SET_SORTING = "SYSTEM_LANGUAGES_SET_SORTING";

/** Autosuggestion */
export const AUTOSUGGESTION_SET_APIKEY_NAMES = "AUTOSUGGESTION_SET_APIKEY_NAMES";
export const AUTOSUGGESTION_SET_GAME_NAMES = "AUTOSUGGESTION_SET_GAME_NAMES";
export const AUTOSUGGESTION_SET_PARTNERS = "AUTOSUGGESTION_SET_PARTNERS";
export const AUTOSUGGESTION_SET_BETSHOP_NAMES = "AUTOSUGGESTION_SET_BETSHOP_NAMES";
export const AUTOSUGGESTION_SET_USER_NAMES = "AUTOSUGGESTION_SET_USER_NAMES";
export const AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES = "AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES";
export const AUTOSUGGESTION_SET_CASHIER_NAMES = "AUTOSUGGESTION_SET_CASHIER_NAMES";
export const AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES = "AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES";
export const AUTOSUGGESTION_SET_CONTROLLERS = "AUTOSUGGESTION_SET_CONTROLLERS";
export const AUTOSUGGESTION_SET_BONUS_NAMES = "AUTOSUGGESTION_SET_BONUS_NAMES";
export const AUTOSUGGESTION_SET_JACKPOT_NAMES = "AUTOSUGGESTION_SET_JACKPOT_NAMES";
export const AUTOSUGGESTION_SET_SYSTEM_MARKETS = "AUTOSUGGESTION_SET_SYSTEM_MARKETS";
export const AUTOSUGGESTION_SET_CURRENCY_NAMES = "AUTOSUGGESTION_SET_CURRENCY_NAMES";

/** System */
export const SYSTEM_BETSHOPS_BEFORE = "SYSTEM_BETSHOPS_BEFORE";
export const SYSTEM_BETSHOPS_FINISH = "SYSTEM_BETSHOPS_FINISH";
export const SYSTEM_SET_BETSHOPS = "SYSTEM_SET_BETSHOPS";

/** Users Feedback */
export const SURVEYS_ACTION_BEFORE = "SURVEYS_ACTION_BEFORE";
export const SURVEYS_ACTION_FINISH = "SURVEYS_ACTION_FINISH";
export const SURVEY_QUICK_INFO_BEFORE = "SURVEY_QUICK_INFO_BEFORE";
export const SURVEY_QUICK_INFO_FINISH = "SURVEY_QUICK_INFO_FINISH";
export const SET_SURVEY_QUICK_INFO = "SET_SURVEY_QUICK_INFO";
export const SET_SURVEY_SAVE_BEFORE = "SET_SURVEY_SAVE_ACTION_BEFORE";
export const SET_SURVEY_SAVE_FINISHED = "SET_SURVEY_SAVE_FINISHED";
export const SURVEY_ADD_PARTNER_BEFORE = "SURVEY_ADD_PARTNER__BEFORE";
export const SURVEY_ADD_PARTNER_FINISH = "SURVEY_ADD_PARTNER_FINISH";
export const SURVEY_SAVE_PARTNERS_BEFORE = "SURVEY_SAVE_PARTNERS_BEFORE";
export const SURVEY_SAVE_PARTNERS_FINISH = "SURVEY_SAVE_PARTNERS_FINISH";
export const SURVEY_SAVE_PARTNERS = "SURVEY_SAVE_PARTNERS";
export const SET_SURVEY_GENERAL_INFO = "SET_SURVEY_GENERAL_INFO";
export const SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE = "SURVEY_AVAILABLE_PARTNERS_ACTION_BEFORE";
export const SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH = "SURVEY_AVAILABLE_PARTNERS_ACTION_FINISH";
export const SET_SURVEY_AVAILABLE_PARTNERS = "SET_SURVEY_AVAILABLE_PARTNERS";
export const SET_SURVEY_PARTNERS_BEFORE = "SET_SURVEY_PARTNERS_BEFORE";
export const SET_SURVEY_PARTNERS_FINISH = "SET_SURVEY_PARTNERS_FINISH";
export const SET_SURVEY_PARTNERS = "SET_SURVEY_PARTNERS";
export const SET_SURVEY_PARTNERS_SORTING = "SET_SURVEY_PARTNERS_SORTING";
export const SET_SURVEY_PARTNER_FEEDBACKS_BEFORE = "SET_SURVEY_PARTNER_FEEDBACKS_BEFORE";
export const SET_SURVEY_PARTNER_FEEDBACKS_FINISH = "SET_SURVEY_PARTNER_FEEDBACKS_FINISH";
export const SET_SURVEY_PARTNER_FEEDBACKS = "SET_SURVEY_PARTNER_FEEDBACKS";
export const SET_SURVEY_PARTNER_FEEDBACKS_SORTING = "SET_SURVEY_PARTNER_FEEDBACKS_SORTING";
export const SURVEYS_ACTION_SET_SURVEYS = "SURVEYS_ACTION_SET_SURVEYS";
export const SURVEYS_SET_SORTING = "SURVEYS_SET_SORTING";
export const RESET_EDITING_SURVEY = "RESET_EDITING_SURVEY";
export const CHANGE_SURVEY_STATE = "CHANGE_SURVEY_STATE";
