import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Layout as AntLayout, ConfigProvider, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import useCurrentPath from "hooks/useCurrentPath";

const { Content: AntContent, Sider: AntSider } = AntLayout;

import Header from "components/common/header";
import DashboardMenu from "components/common/dashboardMenu";
import LogoutModal from "components/common/logoutModal";

import { getUserInfo, resetUserSelectedCurrency } from "store/actions/dashboard/profile/userInfo.action";
import { addNotification } from "store/actions/dashboard/notifications/notifications.action";

import LocalStorageUtils from 'utils/localStorage';
import SignalRUtils from 'utils/signalR';
import { getUser, refreshToken } from 'utils/auth';
import { logout } from 'store/actions/auth/auth.action';
import { ADMIN_WS_CHECKING_REGEX, CASHIER_WS_CHECKING_REGEX } from 'constants/regex.constants';
import { buildPathToStaticFolderOfCDN } from "utils/common";


const BREAKPOINT = 992;

/** General wrapper for dashboard pages */
const Container = ({ children, getUserInfo, resetUserSelectedCurrency, isUserInfoLoading, addNotification, logout }) => {
	const [collapsed, setCollapsed] = useState(window.innerWidth < BREAKPOINT ? true : LocalStorageUtils.get("collapsed_sidebar") || false);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < BREAKPOINT);
	const { t } = useTranslation();
	const path = useCurrentPath();

	const onNavigate = (url) => {
		if(isMobileView) {
			setCollapsed(true);
		}

		if(url !== path) {
			resetUserSelectedCurrency();
		}
	};

	useEffect(() => {
		getUserInfo();
		SignalRUtils.buildConnections(handleSignalREvents);

		return () => {
			SignalRUtils.getConnections().forEach((connection) => {
				connection.getConnection().invoke("Unsubscribe", "Logout");
				connection.getConnection().off("Logout");

				connection.getConnection().invoke("Unsubscribe", "RefreshToken");
				connection.getConnection().off("RefreshToken");

				connection.getConnection().invoke("Unsubscribe", "Notification");
				connection.getConnection().off("Notification");

				connection.stopConnection();
			});
		};
	}, []);

	useEffect(() => {
		if (!isMobileView) {
			LocalStorageUtils.set("collapsed_sidebar", collapsed);
		}
	}, [collapsed]);

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Container
	 */
	const handleSignalREvents = () => {
		SignalRUtils.getConnections().forEach((connection) => {
			if (ADMIN_WS_CHECKING_REGEX.test(connection.connectionURL)) {
				connection.getConnection().off("Logout");
				connection.getConnection().off("RefreshToken");

				connection.getConnection().on("Logout", () => {
					logout();
				});

				connection.getConnection().on("RefreshToken", () => {
					const user = getUser();

					const refresh_token = user?.refreshToken ?? null;
					const userName = user?.userName ?? null;
					refreshToken(refresh_token, userName);
				});
			}

			if (ADMIN_WS_CHECKING_REGEX.test(connection.connectionURL) || CASHIER_WS_CHECKING_REGEX.test(connection.connectionURL)) {
				connection.getConnection().off('Notification');
				connection.getConnection().on('Notification', data => {
					addNotification(JSON.parse(data))
				});
			}
		})
	}

	return isUserInfoLoading ? (
		<Spin className="dashboard-loader" indicator={<LoadingOutlined spin />} />
	) : (
		<AntLayout>
			<AntSider
				breakpoint="lg"
				width="290"
				className="dashboard_sider"
				trigger={null}
				collapsible
				collapsed={collapsed}
				onBreakpoint={(isMobile) => {
					setIsMobileView(isMobile);
					setCollapsed(isMobile ? true : LocalStorageUtils.get("collapsed_sidebar") || false);
				}}
			>
				<div className="dashboard_logo">
					<img
						src={
							collapsed
								? buildPathToStaticFolderOfCDN("admin-images/logo-small.png")
								: buildPathToStaticFolderOfCDN("admin-images/logo.svg") 
						}
						alt="logo"
					/>
				</div>
				<DashboardMenu onNavigate={onNavigate} />
				<div className="dashboard_footer">
					<i className={!collapsed ? "icon-menu" : "icon-menu-revert"} onClick={() => setCollapsed(!collapsed)} />
				</div>
			</AntSider>
			<AntLayout style={{ marginLeft: collapsed ? 80 : 280 }} className="dashboard_layout_wrapper">
				<ConfigProvider getPopupContainer={(trigger) => (trigger && trigger.parentNode ? trigger.parentNode : document.body)}>
					<Header onSiderCollapse={() => setCollapsed(!collapsed)} collapsed={collapsed} />
					<AntContent>
						<div className="dashboard_layout">
							{children}
							<LogoutModal />
						</div>
					</AntContent>
				</ConfigProvider>
			</AntLayout>
		</AntLayout>
	);
};

/** Container propTypes
 * PropTypes
 */
Container.propTypes = {
	/** Page content */
	children: PropTypes.node,
	/** Redux action to get user info */
	getUserInfo: PropTypes.func,
	/** Redux state property, is true when loading user info */
	isUserInfoLoading: PropTypes.bool,
	/** Redux action to add notification */
	addNotification: PropTypes.func,
	logout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isUserInfoLoading: state.profile.isUserInfoLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getUserInfo: () => {
		dispatch(getUserInfo());
	},
	resetUserSelectedCurrency: () => {
		dispatch(resetUserSelectedCurrency());
	},
	addNotification: (notification) => {
		dispatch(addNotification(notification));
	},
	logout: () => {
		dispatch(logout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
