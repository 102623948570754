import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Tooltip, Tag } from "antd";

import Table from "components/common/table";
import OnlineFilters from "../../online/settled/filters.component";
import RetailFilters from "../../retail/settled/filters.component";
import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";
import ChangeState from "./changeState";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { BETSLIP_MODES, BET_STATE, CANCEL_USER_TYPE, KEYS_OF_LOCAL_STORAGE, BET_STATE_COLOR } from "constants/bet.constants";
import { SCHEDULED_GAME_TYPE, GAME_EVENT_TYPE, SCHEDULED_GAME_TYPE_MAPPER, GAME_CATEGORY, GAME_TYPES } from "constants/game.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_ACTION } from "constants/permissions.constants";
import { FILTER_SHOW_IN_CURRENCY, FILTER_BET_STATE } from "constants/filter.constants";
import { BONUS_TYPE_TRANSLATION_TEXTS } from "constants/bonus.constants";
import { PAYMENT_TYPE_TEXT } from "constants/partner.constants";

import { makeBetText, makeGroupTitle, makeWinnerMarketName } from "utils/markets";
import { hasPermission } from "utils/permissions";
import { getPartnerCurrency } from "utils/currency";
import { isRacingGame, isLeagueGame, isCupGame, getRoundNameOfCupGame, flagsToBinary, getGamesFromConfig, isNullish } from "utils/common";
import { columnsTitlesByProjectType, getIncludedColumnsFromLocalStorage, setIncludedColumnsFromLocalStorage, getFilteredColumns, convertAmt } from "utils/betslips";
import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

const dashedString = "- - -";

/** Settled Betslips Table Component */
const SettledBetsTableComponent = ({
	getSettledBets,
	setSettledBetsSorting,
	setSettledBetsFilters,
	bets,
	isLoading,
	sorting,
	filters,
	total,
	globalPartnerId,
	projectType,
	exportURL,
	currency,
	currencies,
	filtersComponent,
	permission,
	gameCategory
}) => {
	const { t } = useTranslation();

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		...columnsTitlesByProjectType(projectType, "key"),
		{ title: t("pages.dashboard.bets.ip"), key: "ipAddress" },
		...(
			gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{ title: t("pages.dashboard.bets.bonus_id"), key: "bonusId" },
					{ title: t("pages.dashboard.bets.bonus_type"), key: "bonusType" }
				]
				: []
		),
		{ title: t("pages.dashboard.bets.redeem"), key: "redeem" },
		{ title: t("pages.dashboard.bets.calculated_at"), key: "calculateTime" },
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
				{ title: t("pages.dashboard.bets.betting_period_start"), key: "bettingPeriodStartTime" },
				{ title: t("pages.dashboard.bets.betting_period_end"), key: "bettingPeriodEndTime" }
			]),
		{ title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.username") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.cashier") : dashedString, key: "userName" },
		{ title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.project") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.betshop") : dashedString, key: "projectName" },
		{ title: t("pages.dashboard.bets.starts_at"), key: "eventStartTime" },
		{ title: t("pages.dashboard.bets.ends_at"), key: "eventFinishTime" },
		{ title: t("pages.dashboard.bets.currency"), key: "currencyId" },
		...(
			projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{ title: t("pages.dashboard.bets.promotionPercent"), key: "promotionPercent" },
					{ title: t("pages.dashboard.bets.promotionAmount"), key: "promotionAmount" }
				]
				: []
		)
	];

	const [includedColumns, setIncludedColumns] = useState(() => getIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.SETTLED_BETSLIPS_SAVED_KEY));

	const [showStateChangePopup, setShowStateChangePopup] = useState(null);

	const exportFilters = {
		...filters,
		status: filters.status.length === 1 && filters.status[0] === FILTER_BET_STATE.ALL ? null : flagsToBinary(filters.status),
		gameCategory
	};

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf SettledBetsTableComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	const renderBetsStatus = (value, record) => {
		const { allowEdit, status } = record
		const betArr = (
			record.type === BETSLIP_MODES.MULTI && Array.isArray(record.bets)
				? record.bets
				: [record]
		)
		const allowOnlyCancel = betArr.some((b) => b.gameType === SCHEDULED_GAME_TYPE.KENO.value)
		return (
			<Tooltip
				title={
					record.cancelUserType !== CANCEL_USER_TYPE.NONE || value === BET_STATE.REJECTED_BY_OPERATOR
						? (
							<Fragment>
								{record.cancelUserType !== CANCEL_USER_TYPE.NONE && (
									<Fragment>
										<b>{t("pages.dashboard.bets.cancel_user_type_" + record.cancelUserType)}</b>
										<br />
									</Fragment>
								)}
								{record.cancelReason && <span>{`${t("pages.dashboard.bets.reason")}: ${record.cancelReason}`}</span>}
							</Fragment>
						)
						: null
				}
			>
				<Tag color={BET_STATE_COLOR[status]}>
					<Fragment>
						{t("pages.dashboard.bets.bet_status_" + status)}
						{hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) && projectType === PROJECT_TYPE.RETAIL && allowEdit ? (
							<i
								className="icon-edit"
								onClick={() => {
									return handleStateChangeClick({
										id: record.betSlipId,
										status: status,
										allowOnlyCancel: allowOnlyCancel
									});
								}}
							/>
						) : null}
					</Fragment>
				</Tag>
			</Tooltip>
		);
	};

	const renderBetsStatusOfDetails = (value, record) => {
		const { allowEdit, status } = record;
		const canChange = (
			!record.payoutTime &&
			record.type !== BETSLIP_MODES.MULTI &&
			status !== BET_STATE.REJECTED_BY_OPERATOR &&
			(status !== BET_STATE.CANCELLED || record.gameType !== SCHEDULED_GAME_TYPE.KENO.value) &&
			record.bonusId === null &&
			allowEdit
		)
		return (
			<Tooltip
				title={
					record.cancelUserType !== CANCEL_USER_TYPE.NONE || value === BET_STATE.REJECTED_BY_OPERATOR
						? (
							<Fragment>
								{record.cancelUserType !== CANCEL_USER_TYPE.NONE && (
									<Fragment>
										<b>{t("pages.dashboard.bets.cancel_user_type_" + record.cancelUserType)}</b>
										<br />
									</Fragment>
								)}

								{record.cancelReason && <span>{`${t("pages.dashboard.bets.reason")}: ${record.cancelReason}`}</span>}
							</Fragment>
						)
						: null
				}
			>
				<Tag color={BET_STATE_COLOR[status]}>
					<Fragment>
						{t("pages.dashboard.bets.bet_status_" + status)}
						{
							hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY }) && projectType === PROJECT_TYPE.RETAIL && canChange ? (
								<i
									className="icon-edit"
									onClick={() => {
										return handleStateChangeClick({
											betId: record.betId,
											id: record.betSlipId,
											status: status,
											allowOnlyCancel: record.gameType === SCHEDULED_GAME_TYPE.KENO.value
										});
									}}
								/>
							) : null
						}
					</Fragment>
				</Tag>
			</Tooltip>
		);
	};

	const mapGameData = (record) => {
		const team1 = record?.gameData?.team1 ?? {};
		const team2 = record?.gameData?.team2 ?? {};

		if (!record.eventId) {
			return (
				<span className="info-text error-text">
					{t("common.event_not_found")}
				</span>
			);
		}
		if ([GAME_TYPES[record.gameCategory].FOOTBALL_SINGLE_MATCH.value, SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value].includes(record.gameType)) {
			return `${team1.countryName ?? ""} - ${team2.countryName ?? ""}`;
		}

		if (isLeagueGame(record.gameType)) {
			const getLeagueGamesEventTitle = () => {
				if (record.gameType === SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value) {
					return t("common.english_league");
				}
				if (record.gameType === SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value) {
					return t("common.turkish_league");
				}
			}

			if (record.eventType === GAME_EVENT_TYPE.LEAGUE) {
				return getLeagueGamesEventTitle();
			}
			if (record.gameData) {
				return `${record?.gameData?.team1?.countryCode ?? ""} - ${record?.gameData?.team2?.countryCode ?? ""}`;
			}
			return getLeagueGamesEventTitle();
		}

		if (isCupGame(record.gameType)) {
			if (record.eventType === GAME_EVENT_TYPE.LEAGUE) {
				return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[record.gameType]}`);
			}
			if (record.eventType === GAME_EVENT_TYPE.WEEK) {
				return getRoundNameOfCupGame(record.eventOrderNumber, record.gameType);
			}
			if (record.gameData) {
				return `${record?.gameData?.team1?.countryCode ?? ""} - ${record?.gameData?.team2?.countryCode ?? ""}`;
			}

			return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[record.gameType]}`);
		}

		if (isRacingGame(record.gameType)) {
			return record?.gameData?.venue ?? "";
		}

		if (record.gameType === SCHEDULED_GAME_TYPE.KENO.value) {
			return t("common.keno");
		}
		return "";
	}

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bets.betslip_id"),
			dataIndex: "betSlipId",
			render: (value, record) => {
				return (
					<div style={{ width: 80 }}>
						{
							record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)
								? record.betSlipId
								: dashedString
						}
					</div>
				)
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.bet_id"),
			dataIndex: "betId",
			render: (value, record) => {
				const isMultiBetSlipInfo = record.type === BETSLIP_MODES.MULTI && Array.isArray(record.bets);

				return (
					<div style={{ width: 80 }}>
						{
							isMultiBetSlipInfo
								? dashedString
								: record.betId
						}
					</div>
				)
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "betTime",
			render: (value, record) => {
				const condition = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets)
				return (
					<div style={{ width: 100 }}>
						{
							condition
								? dashedString
								: (
									<Fragment>
										<div>{moment.utc(value).local().format(DATE_FORMAT)}</div>
										<div>{moment.utc(value).local().format(TIME_FORMAT)}</div>
									</Fragment>
								)
						}
					</div>
				)
			}
		},
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
				{
					title: t("pages.dashboard.bets.bet_type"),
					dataIndex: "type",
					render: (value, record) => {
						let renderValue = ""
						switch (value) {
							case BETSLIP_MODES.SINGLE:
								renderValue = t("pages.dashboard.bets.single");
								break;
							case BETSLIP_MODES.MULTI:
								if (Array.isArray(record.bets)) {
									renderValue = `${t("pages.dashboard.bets.multi")} (${record.bets.length})`;
								} else {
									renderValue = dashedString
								}
								break
							default:
								break;
						}
						return (
							<div style={{ width: 100, minWidth: 100 }}>
								{renderValue}
							</div>
						);
					}
				}
			]),
		{
			title: t("pages.dashboard.bets.status"),
			dataIndex: "status",
			className: "ant-table-tag-cell",
			render: (value, record) => {
				return (
					<div style={{ width: 230 }}>
						{
							record.type === BETSLIP_MODES.SINGLE || !Array.isArray(record.bets)
								? renderBetsStatus(value, record)
								: renderBetsStatusOfDetails(value, record)
						}
					</div>
				)
			}
		},
		...columnsTitlesByProjectType(projectType, "dataIndex", dashedString),
		{
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.username") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.cashier") : dashedString,
			dataIndex: "userName",
			render: (value, record) => {
				let renderValue = value
				if (!Array.isArray(record.bets) && record.type === BETSLIP_MODES.MULTI) {
					renderValue = dashedString;
				} else if (projectType === PROJECT_TYPE.ONLINE && record.isTesting) {
					renderValue = value + "(Test)";
				}
				return (
					<div style={{ width: 150, minWidth: 150 }}>
						{renderValue}
					</div>
				)
			},
			sorter: false
		},
		{
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.project") : projectType === PROJECT_TYPE.RETAIL ? t("pages.dashboard.bets.betshop") : dashedString,
			dataIndex: "projectName",
			render: (value, record) => {
				let renderValues = [];
				if (!Array.isArray(record.bets) && record.type === BETSLIP_MODES.MULTI) {
					renderValues.push(dashedString);
				} else {
					renderValues.push(value);
					renderValues.push(record.projectId);
				}
				return (
					<div style={{ width: 250 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			}
		},
		{
			title: t("pages.dashboard.bets.game_type"),
			dataIndex: "gameType",
			render: (value, record) => {
				let renderValues = [];
				if (Array.isArray(record.bets)) {
					renderValues.push(dashedString)
				} else {
					renderValues.push(record.gameName)
					renderValues.push(t(
						`common.${getGamesFromConfig(record.gameCategory)
							.filter((v) => v.value === value)
							.map((v) => v.type)}`
					))
				}
				return (
					<div style={{ width: 320 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			}
		},
		{
			title: `${t("pages.dashboard.bets.event")} & ${t("pages.dashboard.bets.id")}`,
			dataIndex: "eventId",
			render: (value, record) => {

				const gameData = mapGameData(record)
				const elements = (
					Array.isArray(record.bets)
						? [dashedString]
						: [
							gameData,
							value
						]
				)
				return (
					<div style={{ width: 150 }}>
						{elements.map((element, i) => {
							return (
								<div key={i}>{element}</div>
							)
						})}
					</div>
				)
			},
			sorter: false,
			copy: true,
			copyCondition: (record) => record.type === BETSLIP_MODES.SINGLE || !Array.isArray(record.bets)
		},
		{
			title: t("pages.dashboard.bets.result"),
			dataIndex: "result",
			render: (value, settled) => {
				return (
					<div style={{ width: 150 }}>
						{
							Array.isArray(settled.bets)
								? dashedString
								: renderResult(settled)
						}
					</div>
				);
			},
			sorter: false
		},
		{
			title: `${t("pages.dashboard.bets.market_selection")} & ${t("pages.dashboard.bets.id")}`,
			dataIndex: "marketId",
			render: (value, record) => {
				let renderValues = [];
				if (Array.isArray(record.bets)) {
					renderValues.push(dashedString)
				} else {
					const marketTitle = record.gameData ? makeGroupTitle(record.period, record.market, record.gameData, record.argument, record.odd, record.gameType) : record.market;
					const marketName = record.gameData ? makeWinnerMarketName(record.odd, record.gameData, record.market, record.argument, record.gameType) : record.odd
					renderValues.push(`${marketTitle} ${marketName}`)
					renderValues.push(value)
				}
				return (
					<div style={{ width: 480 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			},
			sorter: false,
			copy: true,
			copyCondition: (record) => record.type === BETSLIP_MODES.SINGLE || !Array.isArray(record.bets)
		},
		{
			title: t("pages.dashboard.bets.stake"),
			dataIndex: "amount",
			render: (value, record) => {
				return (<div style={{ width: 150 }}>{convertAmt(value, record, currencies, currency, globalPartnerId, filters)}</div>)
			}
		},
		{
			title: t("pages.dashboard.bets.odds"),
			dataIndex: "factor",
			render: (value, record) => {
				return (
					<div style={{ width: 125 }}>
						{
							Number(value).toFixed(2)
						}
					</div>
				)
			},
			sorter: false
		},
		...(
			projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{
						title: t("pages.dashboard.bets.promotionPercent"),
						dataIndex: "promotionPercent",
						render: (value, record) => {
							return (
								<div style={{ width: 125 }}>
									{
										record.type === BETSLIP_MODES.MULTI && !isNullish(value)
											? value
											: dashedString
									}
								</div>
							);
						}
					},
					{
						title: t("pages.dashboard.bets.promotionAmount"),
						dataIndex: "promotionAmount",
						render: (_, record) => {
							const value = (record.status === BET_STATE.LOST) ? record.possiblePromotionAmount : record.promotionAmount
							return (
								<div style={{ width: 200 }}>
									{
										record.type === BETSLIP_MODES.MULTI && !isNullish(value)
											? convertAmt(value, record, currencies, currency, globalPartnerId, filters)
											: dashedString
									}
								</div>
							);
						}
					}
				]
				: []
		),
		{
			title: t("pages.dashboard.bets.possible_win"),
			dataIndex: "possibleWin",
			render: (value, record) => {
				const condition = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets)
				return (
					<div style={{ width: 200 }}>
						{
							condition
								? dashedString
								: convertAmt(value, record, currencies, currency, globalPartnerId, filters)
						}
					</div>
				)
			}
		},
		{
			title: t("pages.dashboard.bets.win"),
			dataIndex: "winning",
			render: (value, record) => {
				const condition = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets)
				const winning = (
					condition
						? dashedString
						: convertAmt(value, record, currencies, currency, globalPartnerId, filters)
				)
				return (
					<div style={{ width: 200 }}>
						<span title={`${t("pages.dashboard.bets.win")}:${winning}`}>{winning}</span>
					</div>
				);
			}
		},
		...(projectType === PROJECT_TYPE.ONLINE ? [] : [
			{
				title: t("pages.dashboard.bets.calculated_at"),
				dataIndex: "calculateTime",
				render: (value, record) => {
					let dateValue = value ?? dashedString;
					let renderValues = [];
					const condition = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets)

					if (condition) {
						renderValues.push(dashedString)
					} else {
						renderValues.push(moment.utc(dateValue).local().format(DATE_FORMAT));
						renderValues.push(moment.utc(dateValue).local().format(TIME_FORMAT));
					}

					return (
						<div style={{ width: 200, minWidth: 200 }}>
							{
								renderValues.map((renderValue, i) => (
									<div key={i}>{renderValue}</div>
								))
							}
						</div>
					)
				},
				sorter: false
			}
		]),
		{
			title: t("pages.dashboard.bets.paidout_at"),
			dataIndex: "payoutTime",
			render: (value, record) => {
				let renderValues = [];
				const condition = (record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)) && Boolean(value)
				if (condition) {
					renderValues.push(moment.utc(value).local().format(DATE_FORMAT));
					renderValues.push(moment.utc(value).local().format(TIME_FORMAT));
				} else {
					renderValues.push(dashedString);
				}
				return (
					<div style={{ width: 200, minWidth: 200 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currencyId",
			render: (value, record) => {
				let currencyCode = currency.code;

				if (filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL || value === "FUN") {
					currencyCode = value;
				}

				const condition = record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)

				return (
					<div style={{ width: 125 }}>
						{
							condition
								? getPartnerCurrency(currencyCode, currencies, globalPartnerId, record.betTime)
								: dashedString
						}
					</div>
				);
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.starts_at"),
			dataIndex: "eventStartTime",
			render: (value, record) => {
				let renderValues = [];
				const condition = Array.isArray(record.bets) || !value
				if (condition) {
					renderValues.push(dashedString);
				} else {
					renderValues.push(moment.utc(value).local().format(DATE_FORMAT));
					renderValues.push(moment.utc(value).local().format(TIME_FORMAT));
				}
				return (
					<div style={{ width: 200, minWidth: 200 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.ends_at"),
			dataIndex: "eventFinishTime",
			render: (value, record) => {
				let renderValues = [];
				const condition = Array.isArray(record.bets) || !value
				if (condition) {
					renderValues.push(dashedString);
				} else {
					renderValues.push(moment.utc(value).local().format(DATE_FORMAT));
					renderValues.push(moment.utc(value).local().format(TIME_FORMAT));
				}
				return (
					<div style={{ width: 200, minWidth: 200 }}>
						{
							renderValues.map((renderValue, i) => (
								<div key={i}>{renderValue}</div>
							))
						}
					</div>
				)
			},
			sorter: false
		},
		...(
			gameCategory === GAME_CATEGORY.SCHEDULED
				? [
					{
						title: t("pages.dashboard.bets.betting_period_start"),
						dataIndex: "bettingPeriodStartTime",
						render: (value, record) => {
							let renderValues = [];
							const condition = Array.isArray(record.bets) || !value
							if (condition) {
								renderValues.push(dashedString);
							} else {
								renderValues.push(moment.utc(value).local().format(DATE_FORMAT));
								renderValues.push(moment.utc(value).local().format(TIME_FORMAT));
							}
							return (
								<div style={{ width: 200, minWidth: 200 }}>
									{
										renderValues.map((renderValue, i) => (
											<div key={i}>{renderValue}</div>
										))
									}
								</div>
							)
						},
						sorter: false
					},
					{
						title: t("pages.dashboard.bets.betting_period_end"),
						dataIndex: "bettingPeriodEndTime",
						render: (value, record) => {
							let renderValues = [];
							const condition = Array.isArray(record.bets) || !value
							if (condition) {
								renderValues.push(dashedString);
							} else {
								renderValues.push(moment.utc(value).local().format(DATE_FORMAT));
								renderValues.push(moment.utc(value).local().format(TIME_FORMAT));
							}
							return (
								<div style={{ width: 200, minWidth: 200 }}>
									{
										renderValues.map((renderValue, i) => (
											<div key={i}>{renderValue}</div>
										))
									}
								</div>
							)
						},
						sorter: false
					},
					{
						title: t("pages.dashboard.bets.bonus_id"),
						dataIndex: "bonusId",
						sorter: false,
						render: (value, record) => {
							let bonusId = record.bonusId;
							const isMultiBetInfo = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets);
			
							if (bonusId === null || isMultiBetInfo) {
								bonusId = dashedString;
							}
							return (
								<div style={{ width: 250 }}>
									{<span title={bonusId}>{bonusId}</span>}
								</div>
							);
						}
					},
					{
						title: t("pages.dashboard.bets.bonus_type"),
						dataIndex: "bonusType",
						render: (value, record) => {
							let bonusTypeText = record.bonusId;
							const isMultiBetInfo = record.type === BETSLIP_MODES.MULTI && !Array.isArray(record.bets);
			
							if (bonusTypeText === null || isMultiBetInfo) {
								bonusTypeText = dashedString;
							} else {
								bonusTypeText = BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType] ? t(BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType]) : value;
							}
			
							return (
								<div style={{ width: 126 }}>
									{<span title={bonusTypeText}>{bonusTypeText}</span>}
								</div>
							);
						}
					}
				]
				: []
		),
		{
			title: t("pages.dashboard.bets.redeem"),
			dataIndex: "redeem",
			render: (value, record) => {
				let redeemText = null
				if (isNullish(value)) {
					redeemText = dashedString
				} else {
					redeemText = convertAmt(value, record, currencies, currency, globalPartnerId, filters);
				}
				return (
					<div style={{ width: 200 }}>
						<span title={`${t("pages.dashboard.bets.redeem")}:${redeemText}`}>{redeemText}</span>
					</div>
				);
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.bets.ip"),
			dataIndex: "ipAddress",
			render: (value, record) => {
				const condition = record.type === BETSLIP_MODES.SINGLE || Array.isArray(record.bets)
				return (
					<div style={{ width: 126 }}>
						{
							condition
								? value
								: dashedString
						}
					</div>
				)
			},
			sorter: false
		}
	];

	/** Function to get table details data
	 * @function
	 * @param {object} record - row item
	 * @returns {object} - table details data
	 * @memberOf SettledBetsTableComponent
	 */
	const getDetailsData = (record) => {
		if (!record.bets) {
			return null;
		}
		return {
			data: record.bets,
			columns: [
				{ title: null, dataIndex: "", render: () => (<div style={{ width: 0 }}></div>) },
				...getFilteredColumns(columns, columnsToInclude, includedColumns),
				{ title: null, dataIndex: "", render: () => (<div style={{ width: 0 }}></div>) },
			],
			uniqueKey: "id",
			fixed: true,
			tableProps: {
				showHeader: false
			},
			spinProps: {
				style: {
					padding: 0
				}
			}
		};
	};

	/** Function to render event result
	 * @function
	 * @param {object} settled
	 * @returns {JSX}
	 * @memberOf SettledBetsTableComponent
	 */
	const renderResult = (settled) => {
		if (settled.eventType === GAME_EVENT_TYPE.WEEK) {
			return dashedString;
		}

		if (!settled.gameData) {
			return <span className="info-text error-text">{t("common.event_not_found")}</span>;
		}

		if (settled.eventType === GAME_EVENT_TYPE.LEAGUE) {
			if (isLeagueGame(settled.gameType)) {
				const group = settled?.market;

				const groupResult = group === "Winner" ? settled.gameData.markets.winner || undefined : group === "Loser" ? settled.gameData.markets.loser || undefined : undefined;
				if (groupResult !== undefined) {
					return groupResult;
				}
				return dashedString;
			}

			if (isCupGame(settled.gameType)) {
				const group = settled?.market;

				const groupResult = group === "Champion" ? settled.gameData.markets.champion || undefined : group === "Finalist" ? settled.gameData.markets.finalist || undefined : undefined;
				if (groupResult !== undefined) {
					return groupResult;
				}
				return dashedString;
			}
		}

		if (isRacingGame(settled.gameType)) {
			const participants = settled?.gameData?.participants ?? [];
			const firstPlace = participants.find((p) => p.place === 1);

			return `${(firstPlace?.number ?? "")}. ${firstPlace?.name ?? ""}`;
		}

		if (settled.gameType === SCHEDULED_GAME_TYPE.KENO.value) {
			const scenes = settled?.gameData?.scenes ?? [];
			const res = scenes
				.map((sc) => sc.number)
				.join(", ");
			return (
				<span title={res} className="table-col-shorten-text">
					{res}
				</span>
			);
		}

		const team1 = settled?.gameData?.team1;
		const team2 = settled?.gameData?.team2;

		if (team1 && team2) {
			return `${(team1.goalCount ?? 0)} - ${(team2.goalCount ?? 0)}`;
		}

		return dashedString;
	};

	/** Fires on state change button click
	 * @function
	 * @param {object} item - the item
	 * @memberOf SettledBetsTableComponent
	 */
	const handleStateChangeClick = (item) => {
		setShowStateChangePopup(item);
	};

	/** Keep included columns in local storage */
	useEffect(() => {
		setIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.SETTLED_BETSLIPS_SAVED_KEY, includedColumns);
	}, [includedColumns]);

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{
						hasPermission({ resource: permission, action: PERMISSION_ACTION.EXPORT })
							? (
								<ExportButton
									columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))}
									tableName={t("pages.dashboard.bets.tabs.settled_betslips")}
									url={exportURL}
									filters={exportFilters}
									sorting={sorting}
								/>
							)
							: null
					}
					<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
				</div>
				{filtersComponent ? (
					<Fragment>{filtersComponent}</Fragment>
				) : projectType === PROJECT_TYPE.ONLINE ? (
					<OnlineFilters projectType={projectType} gameCategory={gameCategory} showBetsBySeparate={true} />
				) : projectType === PROJECT_TYPE.RETAIL ? (
					<RetailFilters projectType={projectType} gameCategory={gameCategory} showBetsBySeparate={true} />
				) : (
					<Fragment />
				)}
			</div>
			<Table
				wrapperClassName="vs--betslip-table vs--betslip-table-new"
				uniqueKey="id"
				loading={isLoading}
				columns={getFilteredColumns(columns, columnsToInclude, includedColumns)}
				data={bets}
				loadFn={(fromFirstPage) => getSettledBets(fromFirstPage, gameCategory)}
				sorting={sorting}
				filters={filters}
				setSortingFn={setSettledBetsSorting}
				setFiltersFn={setSettledBetsFilters}
				total={total}
				updateProps={[globalPartnerId]}
				isDisabled={() => false}
				detailsType="table"
				details={getDetailsData}
				enableReload={true}
				expandIconProps={{ style: { minWidth: 34, display: "block" } }}
				isNonExpendable={(record) => (record.type !== BETSLIP_MODES.MULTI) || (!Array.isArray(record.bets))}
			/>
			{
				showStateChangePopup
					? (
						<ChangeState
							gameCategory={gameCategory}
							item={showStateChangePopup}
							onClose={() => setShowStateChangePopup(null)}
						/>
					)
					: null}
		</div>
	);
};

/** SettledBetsTableComponent propTypes
 * PropTypes
 */
SettledBetsTableComponent.propTypes = {
	/** Function to get settled bets */
	getSettledBets: PropTypes.func,
	/** The array of settled bets */
	bets: PropTypes.arrayOf(betType),
	/** Is true when loading settled bets */
	isLoading: PropTypes.bool,
	/** Function to set settled bets sorting details */
	setSettledBetsSorting: PropTypes.func,
	/** Function to set settled bets filters */
	setSettledBetsFilters: PropTypes.func,
	/** Settled bets sorting details */
	sorting: sortingType,
	/** Settled bets filters */
	filters: PropTypes.object,
	/** Settled bets total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** The url to export table data */
	exportURL: PropTypes.string,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** The JSX content of filters */
	filtersComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	/** The permission for actions */
	permission: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, null)(SettledBetsTableComponent);