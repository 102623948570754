import { useState, useEffect, useMemo, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import Select from "antd/lib/select";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

import LoadedGamePerformanceChart from "./loadedChart";
import SearchableSelect from "components/common/searchableSelect";
import { setDashboardFilters } from "store/actions/dashboard/dashboard/dashboard.action";
import { chartInitialOptions, containerProps } from "./initialConfigs";
import SortingSwitcher from "components/ui/sortingSwitcher";
import {
	drawLabel,
	formatValue,
	checkGameTypeForSkip,
	generateMetricValueByDataCategory,
	calculateAbsoluteTotalOfMetric,
	getGameKey,
	keyToGameData
} from "utils/dashboard";
import { ORDER_DIRECTION, PROJECT_TYPE, ALL_OPTION_ID } from "constants/common.constants";
import { GAME_CATEGORY, GAME_TYPE_MAPPER, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import {
	DASHBOARD_GAME_PERFORMANCE_METRICS,
	DASHBOARD_GAME_PERFORMANCE_METRICS_TRANSLATION,
	DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY,
	DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY_TRANSLATION
} from "constants/dashboard.constants";

import NoChartData from "../noChartData";

const AllGAmesCount = (Object.keys(SCHEDULED_GAME_TYPE).length + Object.keys(INSTANT_GAME_TYPE).length);

/** Dashboard Game Performance Chart Component */
const GamePerformanceChart = ({
	gameCategory,
	calculatedGamePerformance,
	minorUnit,
	negativeColumnColor = "#E64E48",
	positiveColumnColor = "#7AB6D8",
	negativeColumnLabelColor = "#E64E48",
	positiveColumnLabelColor = "#4D4D4D",
	isGamesPerformanceLoading
}) => {

	const { t } = useTranslation();
	const [isShowMore, setIsShowMore] = useState(false)

	const calculatedGamePerformanceData = useMemo(() => {
		const { scheduledGamesPerformanceChart, instantGamesPerformanceChart } = calculatedGamePerformance;
		const performance = (
			gameCategory === GAME_CATEGORY.SCHEDULED
				? { scheduledGamesPerformanceChart }
				: gameCategory === GAME_CATEGORY.INSTANT
					? { instantGamesPerformanceChart }
					: { scheduledGamesPerformanceChart, instantGamesPerformanceChart }
		)

		return Object.values(performance).flatMap((calculatedByCategory) => {
			return calculatedByCategory
		})

	}, [calculatedGamePerformance, gameCategory])

	const existingGameCategoryGameTypes = useMemo(() => {
		return calculatedGamePerformanceData.map(gp => getGameKey(gp))
	}, [calculatedGamePerformanceData]);

	const gamesMultiSelectItems = useMemo(() => {
		return existingGameCategoryGameTypes.length > 0 ? [ALL_OPTION_ID, ...existingGameCategoryGameTypes] : existingGameCategoryGameTypes;
	}, [existingGameCategoryGameTypes]);

	const [chartOptions, setChartOptions] = useState(chartInitialOptions);
	const [gameTypes, setGameTypes] = useState([]);
	const [metric, setMetric] = useState(DASHBOARD_GAME_PERFORMANCE_METRICS.GGR);
	const [dataCategory, setDataCategory] = useState(DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY.NUMBER);
	const [sortingState, setSortingState] = useState(ORDER_DIRECTION.DESC);
	const [isNoData, setIsNoData] = useState(true);

	/* Update game types in filter */
	useEffect(() => {
		setGameTypes(
			existingGameCategoryGameTypes.length > 0
				? [ALL_OPTION_ID]
				: []
		);
	}, [existingGameCategoryGameTypes]);

	/* Update chart */
	useEffect(() => {
		/* The calculation can takes a long time, so it wraps is async operation. */
		const timeoutId = setTimeout(() => {
			/* Skip game if necessary */
			const filteredCalculatedGamePerformance = calculatedGamePerformanceData.filter((cgpd) => checkGameTypeForSkip(getGameKey(cgpd), gameTypes));

			/* Calculate total if necessary for displaying by % */
			const { valueForPercentCalculation, isAllZero } = calculateAbsoluteTotalOfMetric(filteredCalculatedGamePerformance, metric, dataCategory === DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY.PERCENT);

			/* Variables with max value of array */
			let maxValue = 0;
			let sumValue = 0;

			/* Calculate chart data */
			const dataForDrawing = filteredCalculatedGamePerformance
				.map((performanceData) => {
					const value = generateMetricValueByDataCategory(performanceData, metric, valueForPercentCalculation);
					/* get metric unsigned value` numeric or percent */
					const unsignedValue = Math.abs(value);

					sumValue += unsignedValue;

					/* Assign max value if necessary */
					if (unsignedValue > maxValue) {
						maxValue = unsignedValue;
					}

					/*
					Collect data, in chart always passed unsigned value,
					for displaying negativ there will check value color
				*/
					return {
						y: unsignedValue,
						color: value < 0 ? negativeColumnColor : positiveColumnColor,
						name: t(`common.${GAME_TYPE_MAPPER[performanceData.gameCategory][performanceData.gameType]}`)
					};
				}, []);

			/* Sorting by optimal condition */
			dataForDrawing.sort((next, prev) => {
				switch (sortingState) {
					case ORDER_DIRECTION.ASC: return next.y < prev.y ? -1 : 0;
					case ORDER_DIRECTION.DESC: return next.y > prev.y ? -1 : 0;
					default: return 0;
				}
			});

			setIsNoData(!sumValue)
			/* Set updated options of chart */
			setChartOptions((prev) => {
				return {
					...prev,
					chart: {
						...prev.chart,
						height: 40.9 * (isShowMore ? (gameTypes[0] === ALL_OPTION_ID ? AllGAmesCount : gameTypes.length) : 10)
					},
					xAxis: {
						...prev.xAxis,
						min: 0,
						max: isShowMore ? (gameTypes[0] === ALL_OPTION_ID ? AllGAmesCount : gameTypes.length) : 9
					},
					yAxis: {
						...prev.yAxis,
						max:
							/* When all values 0 need drow chart with 0 values to left side */
							isAllZero
								? 10
								: /*
										When there is on value mor then 90% the chart
										drow data label left side over the series column,
										this condition stretch axis ~105 to 110
									*/
								dataCategory === DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY.PERCENT && maxValue >= 90
									? 110
									: null
					},
					series: [
						{
							data: dataForDrawing,
							pointWidth: 20,
							dataLabels: {
								style: { fontSize: 14, fontWeight: 600 },
								formatter: function () {
									/* Check value sign by color, there isn't other way */
									const isNegative = this.color === negativeColumnColor;
									/* Declare variables for draw label */
									const value = this.y * (isNegative ? -1 : 1);
									const color = isNegative ? negativeColumnLabelColor : positiveColumnLabelColor;
									const showNumeric = valueForPercentCalculation === null;
									return drawLabel(formatValue(value, metric === DASHBOARD_GAME_PERFORMANCE_METRICS.BETS_COUNT && showNumeric ? 0 : minorUnit, showNumeric), color);
								},
								enabled: true,
								crop: false,
								overflow: "allow",
								x: -3
							}
						}
					]
				};
			});
		}, 200);
		return () => {
			clearTimeout(timeoutId)
		}
	},
		[
			t,
			calculatedGamePerformanceData,
			minorUnit,
			gameTypes,
			metric,
			dataCategory,
			sortingState,
			negativeColumnColor,
			positiveColumnColor,
			negativeColumnLabelColor,
			positiveColumnLabelColor,
			isShowMore
		]
	);

	useEffect(() => {
		if (chartOptions?.series?.at(0)?.data?.length <= 10) {
			setIsShowMore(false)
		}
	}, [chartOptions?.series?.at(0)?.data?.length])

	const toText = option => {
		if (option === ALL_OPTION_ID) {
			return t("common.all")
		}

		const { gameCategory, gameType } = keyToGameData(option)

		return t(`common.${GAME_TYPE_MAPPER[gameCategory][gameType]}`)
	}

	return (
		<div className="dashboard-chart dashboard-chart-horizontal-amounts dashboard-chart-game-performance">
			<div className="dashboard-chart-header">
				<span className="dashboard-chart-header-title">{t("pages.dashboard.dashboard.game_performance_chart")}</span>
				<span className="dashboard-chart-header-elements">
					<SortingSwitcher value={sortingState} onChange={setSortingState} />
				</span>
			</div>
			<div className="vs--pt-16 vs--pl-16 vs--pr-16">
				<Row gutter={[16, 24]}>
					<Col xs={24} sm={12} lg={6} xxl={4}>
						<Space direction="vertical" className="dashboard-form-less-item-container dashboard-form-less-item-container-row">
							<Typography.Text>
								<label htmlFor="dashboard-chart-game-performance-gameType">{t("pages.dashboard.dashboard.games")}</label>
							</Typography.Text>
							<SearchableSelect
								id="dashboard-chart-game-performance-gameType"
								className={gameTypes.length > 1 ? "vs--show-suffix-icon" : "vs--hide-suffix-icon"}
								mode="multiple"
								getPopupContainer={(trigger) => trigger.parentNode}
								value={gameTypes}
								items={gamesMultiSelectItems}
								valueProp={gameType => gameType}
								textProp={toText}
								renderText={toText}
								onChange={(selectedGameTypes) => {
									switch (true) {
										case selectedGameTypes.at(0) === ALL_OPTION_ID && selectedGameTypes.length > 1:
											setGameTypes(selectedGameTypes.slice(1));
											break;
										case selectedGameTypes.at(-1) === ALL_OPTION_ID && selectedGameTypes.length > 0:
										case selectedGameTypes.length === existingGameCategoryGameTypes.length && existingGameCategoryGameTypes.length > 0:
										case selectedGameTypes.length === 0 && existingGameCategoryGameTypes.length > 0:
											setGameTypes([ALL_OPTION_ID]);
											break;
										case selectedGameTypes.length === 0 && existingGameCategoryGameTypes.length === 0:
											setGameTypes([]);
											break;
										default:
											setGameTypes([...selectedGameTypes]);
											break;
									}
								}}
							/>
						</Space>
					</Col>
					<Col xs={24} sm={12} lg={6} xxl={4}>
						<Space direction="vertical" className="dashboard-form-less-item-container dashboard-form-less-item-container-row">
							<Typography.Text>
								<label htmlFor="dashboard-chart-game-performance-metrics">{t("pages.dashboard.dashboard.metrics")}</label>
							</Typography.Text>
							<Select disabled={isGamesPerformanceLoading} id="dashboard-chart-game-performance-metrics" suffixIcon={<i className="icon-down" />} value={metric} onChange={(value) => setMetric(value)} getPopupContainer={(trigger) => trigger.parentNode}>
								{Object.values(DASHBOARD_GAME_PERFORMANCE_METRICS).map((value) => (
									<Select.Option key={value} value={value}>
										{t(DASHBOARD_GAME_PERFORMANCE_METRICS_TRANSLATION[value])}
									</Select.Option>
								))}
							</Select>
						</Space>
					</Col>
					<Col xs={24} sm={12} lg={6} xxl={4}>
						<Space direction="vertical" className="dashboard-form-less-item-container dashboard-form-less-item-container-row">
							<Typography.Text>
								<label htmlFor="dashboard-chart-game-performance-dataCategory">{t("pages.dashboard.dashboard.dataCategory")}</label>
							</Typography.Text>
							<Select disabled={isGamesPerformanceLoading} id="dashboard-chart-game-performance-dataCategory" suffixIcon={<i className="icon-down" />} value={dataCategory} onChange={(value) => setDataCategory(value)} getPopupContainer={(trigger) => trigger.parentNode}>
								{Object.values(DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY).map((value) => (
									<Select.Option key={value} value={value}>
										{t(DASHBOARD_GAME_PERFORMANCE_DATA_CATEGORY_TRANSLATION[value])}
									</Select.Option>
								))}
							</Select>
						</Space>
					</Col>
				</Row>
			</div>

			<div className="dashboard-chart-content">
				{
					isGamesPerformanceLoading
						? <LoadedGamePerformanceChart />
						: !isGamesPerformanceLoading && isNoData
							? (
								<div className="game-preformance-no-chart-data-wrapper">
									<NoChartData />
								</div>
							)
							: (
								<Fragment>
									<div>
										<HighchartsReact
											highcharts={Highcharts}
											containerProps={containerProps}
											options={chartOptions}
										/>
										{
											chartOptions?.series?.at(0)?.data?.length > 10
												? (
													<div className="vs--show-all-games vs--flex vs--flex-row vs--justify-end vs--align-center">
														<button className={"vs--show-all-games-button " + (isShowMore ? '' : 'vs--show-all-games-button-active')} onClick={() => setIsShowMore(prev => !prev)}>
															{isShowMore ? 'Show Less' : 'Show All Games'}
															<i className="icon-down"></i>
														</button>
													</div>
												)
												: null
										}
									</div>
								</Fragment>
							)
				}
			</div>
		</div>
	);
};

/** GamePerformanceChart propTypes
 * PropTypes
 */
GamePerformanceChart.propTypes = {
	calculatedGamePerformance: PropTypes.exact({
		scheduledGamesPerformanceChart: PropTypes.arrayOf(PropTypes.object),
		instantGamesPerformanceChart: PropTypes.arrayOf(PropTypes.object)
	}),
	minorUnit: PropTypes.number,
	/** Project type(Online / Retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux action to set filters */
	setDashboardFilters: PropTypes.func,
	/** React property, optional color for draw columns with negative value */
	negativeColumnColor: PropTypes.string,
	/** React property, optional color for draw columns with positive value */
	positiveColumnColor: PropTypes.string,
	/** React property, optional color for lable of columns with negative value */
	negativeColumnLabelColor: PropTypes.string,
	/** React property, optional color for lable of columns with positive value */
	positiveColumnLabelColor: PropTypes.string,
	// Redux state controls gamePerformanceChart data loading indicator
	isGamesPerformanceLoading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	let calculatedGamePerformance = { scheduledGamesPerformanceChart: [], instantGamesPerformanceChart: [] };
	switch (ownProps.projectType) {
		case PROJECT_TYPE.ONLINE:
			calculatedGamePerformance = state.dashboard.online.gamesPerformance.calculated;
			break;
		case PROJECT_TYPE.RETAIL:
			calculatedGamePerformance = state.dashboard.retail.gamesPerformance.calculated;
			break;
		default:
			break;
	}
	const { minorUnit } = state.profile.userInfo.currency;

	const isGamesPerformanceLoading = state.dashboard.isGamesPerformanceLoading;

	return { calculatedGamePerformance, minorUnit, isGamesPerformanceLoading };
};

const mapDispatchToProps = (dispatch) => ({
	setDashboardFilters: (filters, projectType) => {
		dispatch(setDashboardFilters(filters, projectType));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(GamePerformanceChart);
