import { Fragment, useState, useEffect, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Switch from "antd/lib/switch";
const { Item: FormItem } = Form;
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Table from "components/common/table";
import Question from "components/common/question";

import ruleType from "types/translation/rule.type";
import sortingType from "types/common/sorting.type";
import { setSelectedGameType, getRules, publishRules, setRulesSorting, setRulesSystemDefault, getPartnerAvailableLanguagesForRule, clearPartnerAvailableLanguagesForRule } from "store/actions/dashboard/cms/translation/rules.action";
import { hasPermission, hasPermissions, hasOneOfPermissions } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_CATEGORY, GAME_TYPE_MAPPER } from "constants/game.constants";
import Paths from "constants/path.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";
import { getGamesFromConfig } from "utils/common";

const AddRule = lazy(() => import("./addRule/rule-add.component"));

const RulesTab = ({
	selectedGameType,
	rules,
	isSystemAvailableLoading,
	systemLanguages,
	total,
	sorting,
	isLoading,
	isPublishing,
	setSelectedGameType,
	getRules,
	setRulesSorting,
	publishRules,
	systemDefault,
	setRulesSystemDefault,
	isSystemAllLoading,
	globalPartnerId,
	gameCategory,
	updateLanguages,
	updateLanguagesState,
	getPartnerAvailableLanguagesForRule,
	clearPartnerAvailableLanguagesForRule
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { hash } = useLocation();
	const [searchParams] = useSearchParams();
	const [formInstance] = Form.useForm();

	const [showPublishPopup, setShowPublishPopup] = useState(false);
	const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState(false);

	useEffect(() => {
		getPartnerAvailableLanguagesForRule(selectedGameType, gameCategory)
		return () => {
			clearPartnerAvailableLanguagesForRule()
		}
	}, [globalPartnerId, selectedGameType, gameCategory, updateLanguagesState])

	const columns = [
		{
			title: t("pages.dashboard.translations.languages"),
			dataIndex: "languageCode",
			render: (languageCode) => <span>{systemLanguages?.[languageCode.toLowerCase()] ?? "-"}</span>,
			sorter: false
		},
		{
			title: t("pages.dashboard.translations.title"),
			dataIndex: "title",
			sorter: false
		},
		{
			title: t("pages.dashboard.translations.state"),
			dataIndex: "isPublished",
			render: (isPublished) => (
				<div className="vs--flex vs--align-center">
					<span className={`vs--rule-state ${isPublished ? "vs--rule-state-published" : "vs--rule-state-not-published"} vs--mr-10`}></span>
					<span>{isPublished ? t("pages.dashboard.translations.published") : t("pages.dashboard.translations.not_published")}</span>
				</div>
			)
		},
		{
			title: t("pages.dashboard.translations.last_update_by"),
			dataIndex: "userName",
			sorter: false
		},
		{
			title: t("pages.dashboard.translations.last_modified_at"),
			dataIndex: "date",
			render: (value) => (value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"),
			sorter: false
		}
	];

	useEffect(() => {
		if (
			hasPermissions([
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
			])
		) {
			getRules({
				fromFirstPage: true,
				gameType: selectedGameType,
				isSystemDefault: hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW }) && systemDefault,
				gameCategory: gameCategory
			});
		} else {
			if (hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW }) && systemDefault && !hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW })) {
				getRules({
					fromFirstPage: true,
					gameType: selectedGameType,
					isSystemDefault: true,
					gameCategory: gameCategory
				});
			}

			if (hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }) && !systemDefault && !hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW })) {
				getRules({
					fromFirstPage: true,
					gameType: selectedGameType,
					isSystemDefault: false,
					gameCategory: gameCategory
				});
			}
		}
	}, [systemDefault, getRules, selectedGameType, globalPartnerId, gameCategory]);

	useEffect(() => {
		setRulesSystemDefault((hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW }) && systemDefault) || (!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }) && !systemDefault), gameCategory);
	}, []);

	return (
		<Fragment>
			<div className="dashboard-section-content">
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<div className="inline-form-item" style={{ justifyContent: "flex-end" }}>
							<label style={{ opacity: systemDefault ? "1" : "0.5" }}>{t("pages.dashboard.translations.system_default_rules")}</label>
							<FormItem className="inline-form-item-control" style={{ flex: 0 }}>
								<Switch
									onChange={(val) => {
										setRulesSystemDefault(val, gameCategory);
									}}
									checked={systemDefault}
									disabled={
										!hasPermissions([
											{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
											{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
										])
									}
								/>
							</FormItem>
						</div>
					</Col>
				</Row>
				<Form colon={false} requiredMark={false} form={formInstance} layout="vertical">
					<Row justify="space-between" align="bottom">
						<Col xs={12} md={4}>
							<FormItem className="vs--rules-game-field" label={t("pages.dashboard.translations.game_type")}>
								<Select value={selectedGameType} onChange={(gameType) => setSelectedGameType(gameType, gameCategory)} suffixIcon={<i className="icon-down" />} getPopupContainer={(trigger) => trigger.parentNode}>
									{getGamesFromConfig(gameCategory).map((gtObj) => (
										<Select.Option key={gtObj.value} value={gtObj.value}>
											{t(`common.${GAME_TYPE_MAPPER[gameCategory][gtObj.value]}`)}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col xs={12} md={4}>
							<div className="table-buttons-dropdowns">
								{!systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }) ? (
									<div>
										<Button type="secondary" onClick={() => setIsAddRuleModalOpen(true)}>
											<span className="table-filters-header-title">{t("pages.dashboard.translations.add_rule")}</span>
										</Button>
									</div>
								) : null}
								{hasOneOfPermissions([
									{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY },
									{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }
								]) && (
									<div className="table-buttons-dropdowns-button table-buttons-dropdowns-button-addSurvey">
										<Button onClick={() => setShowPublishPopup(null)} type="primary" disabled={!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY })}>
											<i className="icon-publish" />
											{t(systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY }) ? "pages.dashboard.translations.publish_all" : "pages.dashboard.translations.publish")}
										</Button>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Table
						loading={isLoading || isPublishing || isSystemAvailableLoading || isSystemAllLoading}
						columns={columns}
						data={rules}
						loadFn={(fromFirstPage) =>
							!fromFirstPage &&
							getRules({
								fromFirstPage,
								gameType: selectedGameType,
								isSystemDefault: systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW }),
								gameCategory: gameCategory
							})
						}
						updateProps={[selectedGameType, systemLanguages]}
						total={total}
						sorting={sorting}
						setSortingFn={(sortObj) => setRulesSorting(sortObj, gameCategory)}
						actions={
							hasOneOfPermissions([
								{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY },
								{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }
							])
								? {
										edit: {
											handler: (rule) => {
												const path = gameCategory === GAME_CATEGORY.SCHEDULED ? Paths.DASHBOARD_CMS_SCHEDULED_RULES_EDIT : Paths.DASHBOARD_CMS_INSTANT_RULES_EDIT;
												navigate({
													pathname: path.replace(DYNAMIC_PATH_ID_REGEX, rule.id),
													search: searchParams.toString(),
													hash
												});
											},
											hidden: !hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY }) && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW })
										},
										publish: {
											title: t("pages.dashboard.translations.publish"),
											handler: (rule) => setShowPublishPopup(rule.languageCode),
											icon: <i className="icon-publish" />,
											hidden: (rule) => rule.isPublished
										}
									}
								: {}
						}
					/>
				</Form>
			</div>

			<Question
				type="confirm"
				onOk={() => {
					const onSuccess = () =>
						getRules({
							fromFirstPage: true,
							gameType: selectedGameType,
							isSystemDefault: systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW }),
							gameCategory: gameCategory
						});
					publishRules({
						gameType: selectedGameType,
						languageCode: showPublishPopup,
						onSuccess: onSuccess,
						systemDefault: systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY }),
						gameCategory: gameCategory
					});
					setShowPublishPopup(false);
				}}
				onCancel={() => setShowPublishPopup(false)}
				isVisible={showPublishPopup !== false}
				width={350}
				okText={t("pages.dashboard.translations.publish")}
				cancelText={t("common.cancel")}
				title={t("pages.dashboard.translations.publish_translation_title")}
				message={showPublishPopup === null ? t("pages.dashboard.translations.publish_all_translations") : t("pages.dashboard.translations.publish_translation")}
			/>
			{isAddRuleModalOpen && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.MODIFY }) ? (
				<Suspense fallback="">
					<AddRule gameCategory={gameCategory} open={isAddRuleModalOpen} onCancel={() => setIsAddRuleModalOpen(false)} updateLanguages={updateLanguages} />
				</Suspense>
			) : null}
		</Fragment>
	);
};

RulesTab.propTypes = {
	/** Redux state property, selected game */
	selectedGameType: PropTypes.number,
	/** Redux action, setting current filtered gameType */
	setSelectedGameType: PropTypes.func,
	/** Redux state property, selected game rule */
	rules: PropTypes.arrayOf(ruleType),
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** React property, represents if system languages data is empty or not */
	isSystemLanguagesEmpty: PropTypes.bool,
	/** Redux state property, rules total count */
	total: PropTypes.number,
	/** Redux state property, rules sorting details */
	sorting: sortingType,
	/** Redux state property, is true when loading rules data */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when loading system languages */
	isSystemAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when publishing rules */
	isPublishing: PropTypes.bool,
	/** Redux action to get rules */
	getRules: PropTypes.func,
	/** Redux action to set rules sorting details */
	setRulesSorting: PropTypes.func,
	/** Redux action to publish rules */
	publishRules: PropTypes.func,
	/** Redux action to set system default translation */
	setRulesSystemDefault: PropTypes.func,
	/** Redux state property, is true when system default translations is enabled */
	systemDefault: PropTypes.bool,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	updateLanguages: PropTypes.func,
	updateLanguagesState: PropTypes.any,
	getPartnerAvailableLanguagesForRule: PropTypes.func,
	clearPartnerAvailableLanguagesForRule: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getRules: ({ fromFirstPage, gameType, isSystemDefault, gameCategory }) => {
		dispatch(getRules({ fromFirstPage, gameType, isSystemDefault, gameCategory }));
	},
	setRulesSorting: (sorting, gameCategory) => {
		dispatch(setRulesSorting(sorting, gameCategory));
	},
	publishRules: ({ gameType, languageCode, onSuccess, systemDefault, gameCategory }) => {
		dispatch(publishRules({ gameType, languageCode, onSuccess, systemDefault, gameCategory }));
	},
	setSelectedGameType: (gameType, gameCategory) => {
		dispatch(setSelectedGameType(gameType, gameCategory));
	},
	setRulesSystemDefault: (systemDefault, gameCategory) => {
		dispatch(setRulesSystemDefault(systemDefault, gameCategory));
	},
	getPartnerAvailableLanguagesForRule: (gameType, gameCategory) => {
		dispatch(getPartnerAvailableLanguagesForRule(gameType, gameCategory))
	},
	clearPartnerAvailableLanguagesForRule: () => {
		dispatch(clearPartnerAvailableLanguagesForRule())
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.translations.isLoading,
		isSaving: state.translations.isSaving,
		isSystemAllLoading: state.translations.isSystemAllLoading,
		selectedGameType: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.selectedGameType,
		rules: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.data,
		systemDefault: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.systemDefault,
		total: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.total,
		sorting: state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.sorting,
		systemLanguages: state.systemLanguages.systemAvailableLanguages,
		isSystemAvailableLoading: state.systemLanguages.isAvailableLoading,
		isPublishing: state.translations.isPublishing,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RulesTab);
