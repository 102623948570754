import PropTypes from "prop-types";
import { SCHEDULED_GAME_TYPE } from "constants/game.constants";
import footballFlags from "systemData/footballFlags";

import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";

/** Flag Component */
const Flag = ({ code, gameType = SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value, className }) => {

	const lowerCaseCode = code.toLowerCase();
	const flagCode = footballFlags[gameType][lowerCaseCode] || `${lowerCaseCode}.svg`;

	return (
		<div className={mergeClassNames("flag", className)}>
			<div 
				className="flag-icon" 
				style={
					lowerCaseCode
						? { backgroundImage: `url(${buildPathToStaticFolderOfCDN(`images/flags/${flagCode}`)})` }
						: null
				}
				role="img"
				alt={lowerCaseCode}
			/>
		</div>
	);
};

/** Flag propTypes
 * PropTypes
 */
Flag.propTypes = {
	/** Country code of flag */
	code: PropTypes.string,
	gameType: PropTypes.oneOf(Object.values(SCHEDULED_GAME_TYPE).map((gt) => gt.value))
};

export default Flag;
