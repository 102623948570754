import { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Typography, DatePicker, Select } from "antd";
import Input from "antd/lib/input";
import NumericInput from "components/common/numericInput";
import { getPlayerSuspiciousActivitiesHistory, setPlayerSuspiciousActivitiesHistoryFilters } from "store/actions/dashboard/riskManagment/playerSuspiciousActivities/playerSuspiciousActivities.actions";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import locale from "antd/es/date-picker/locale/en_US";
import { getGamesFromConfig, isNullish } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

const { Item: FormItem } = Form;
const allCategories = "";

/** Games Page History Filters Component */
const HistoryFilters = ({
	filters = {},
	getPlayerSuspiciousActivitiesHistory = Function.prototype,
	setPlayerSuspiciousActivitiesHistoryFilters = Function.prototype
}) => {

	const [selectedPartnerId, setSelectedPartnerId] = useState(null);
	const [filterGameCategory, setFilterGameCategory] = useState(allCategories);

	const { t } = useTranslation();

	const formInstanceRef = useRef();

	const partnersNames = useAutosuggestion(AUTOSUGGESTION_TYPE.PARTNER_NAMES, []);

	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [selectedPartnerId], {
		actionArguments: [
			{
				partnerId: selectedPartnerId,
				params: {
					gameCategory: filterGameCategory === allCategories ? null : filterGameCategory
				}
			}
		]
	});

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true }]
		};
	}, []);

	const filtersList = [
		{ name: "partnerName", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID") },
		{ name: "partnerId", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID"), hidden: () => true },
		{ name: "projectName", title: t("pages.dashboard.apikeys.project_name_or_ID") },
		{ name: "projectId", title: t("pages.dashboard.apikeys.project_name_or_ID"), hidden: () => true },
		{ name: "playerExternalId", title: t("pages.dashboard.players.external_id") },
		{ name: "playerId", title: t("pages.dashboard.bets.player_id") },
		{ name: "minBetAmount", title: `${t("common.min")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")}` },
		{ name: "maxBetAmount", title: `${t("common.max")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")}` },
		{ name: "minAvgBetAmount", title: `${t("common.min")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageBetAmount")}` },
		{ name: "maxAvgBetAmount", title: `${t("common.max")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageBetAmount")}` },
		{ name: "minDeviation", title: `${t("common.min")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")}` },
		{ name: "maxDeviation", title: `${t("common.max")} ${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")}` },
		{ name: "date", title: t("pages.dashboard.bets.date_and_time") }
	];

	const getItemKey = (item) => item.key;
	const getItemValue = (item) => item.value;

	const handleFilters = (filters) => {
		const modifiedFilters = { ...filters };
		if (modifiedFilters.partnerId) {
			modifiedFilters.partnerName = partnersNames.find((partner) => partner.key === filters.partnerId)?.value ?? "";
		} else {
			modifiedFilters.partnerName = ""
		}
		if(modifiedFilters.projectId) {
			modifiedFilters.projectName = apiKeyNames.find((partner) => partner.key === filters.projectId)?.value ?? "";
		} else {
			modifiedFilters.projectName = ""
		}

		if (!modifiedFilters.partnerName) {
			modifiedFilters.partnerId = "";
			modifiedFilters.projectName = "";
		}
		if (!modifiedFilters.projectName) {
			modifiedFilters.projectId = "";
		}

		setPlayerSuspiciousActivitiesHistoryFilters(modifiedFilters);
	}

	return (
		<FiltersWrapper
			loadFn={getPlayerSuspiciousActivitiesHistory}
			setFiltersFn={handleFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList}
			filtersName="playerSuspiciousActivitiesHistoryFilter"
			dependencies={[
				{ field: "partnerId", resetField: "projectId", resetValue: "" },
				{ field: "partnerId", resetField: "projectName", resetValue: "" },
				{ field: "partnerId", resetField: "partnerName", resetValue: "" },
				{ field: "partnerName", resetField: "projectId", resetValue: "" },
				{ field: "partnerName", resetField: "partnerId", resetValue: "" },
				{ field: "partnerName", resetField: "projectName", resetValue: "" },
				{ field: "projectName", resetField: "projectId", resetValue: "" },
				{ field: "projectId", resetField: "projectName", resetValue: "" }
			]}
			formInstanceRef={formInstanceRef}
		>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="playerId" label={t("pages.dashboard.bets.player_id")}>
						<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.player_id")}`} />
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="playerExternalId" label={t("pages.dashboard.players.external_id")}>
						<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.players.external_id")}`} />
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="partnerId" label={t("pages.dashboard.developerBetCorrections.partnerNameOrID")}>
						<SearchableSelect
							addNone={true}
							placeholder={`${t("common.start_typing")} ${t("pages.dashboard.partners.partner_name_or_id")}`}
							items={partnersNames}
							valueProp={getItemKey}
							textProp={getItemValue}
							renderText={getItemValue}
							suffixIcon={<i className="icon-down" />}
							onChange={(partnerId) => {
								setSelectedPartnerId(partnerId)
								if (partnerId === allCategories && typeof formInstanceRef?.current?.setFieldsValue === "function") {
									formInstanceRef.current.setFieldsValue({ projectId: allCategories });
								}
							}}
						/>
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem dependencies={["partnerId"]} noStyle shouldUpdate >
						{
							({ getFieldValue }) => {
								const partnerId = getFieldValue("partnerId");
								return (
									<FormItem name="projectId" label={t("pages.dashboard.apikeys.project_name_or_ID")}>
										<SearchableSelect
											disabled={partnerId === allCategories}
											addNone={true}
											placeholder={`${t("common.start_typing")} ${t("pages.dashboard.apikeys.project_name_or_ID")}`}
											items={apiKeyNames}
											valueProp={getItemKey}
											textProp={getItemValue}
											renderText={getItemValue}
											suffixIcon={<i className="icon-down" />}
										/>
									</FormItem>
								)
							}
						}
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")} (EUR)`}</label>
					</div>
					<Row wrap={false} gutter={[16, 0]}>
						<Col>
							<FormItem name="minBetAmount">
								<NumericInput placeholder={t("common.min")} />
							</FormItem>
						</Col>
						<Col>
							<FormItem name="maxBetAmount">
								<NumericInput placeholder={t("common.max")} />
							</FormItem>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageBetAmount")} (EUR)`}</label>
					</div>
					<Row wrap={false}>
						<FormItem name="minAvgBetAmount">
							<NumericInput placeholder={t("common.min")} />
						</FormItem>
						<FormItem name="maxAvgBetAmount">
							<NumericInput placeholder={t("common.max")} />
						</FormItem>
					</Row>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")}`}</label>
					</div>
					<Row wrap={false}>
						<FormItem name="minDeviation">
							<NumericInput placeholder={t("common.min")} />
						</FormItem>
						<FormItem name="maxDeviation">
							<NumericInput placeholder={t("common.max")} />
						</FormItem>
					</Row>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem
						name="date"
						label={t("pages.dashboard.bets.date_and_time")}
					>
						<DatePicker.RangePicker
							placeholder={[t("common.from"), t("common.to")]}
							format={`${DATE_FORMAT} ${TIME_FORMAT}`}
							showTime={{ format: TIME_FORMAT }}
							allowClear={false}
							locale={{
								...locale,
								lang: {
									...locale.lang,
									ok: t("common.apply")
								}
							}}
						/>
					</FormItem>
				</Col>
			</Row>
		</FiltersWrapper>
	);
};

/** HistoryFilters propTypes
 * PropTypes
 */
HistoryFilters.propTypes = {
	/** Redux state property, games filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	setPlayerSuspiciousActivitiesHistoryFilters: (filters) => {
		dispatch(setPlayerSuspiciousActivitiesHistoryFilters(filters));
	},
	getPlayerSuspiciousActivitiesHistory: (fromFirstPage) => {
		dispatch(getPlayerSuspiciousActivitiesHistory(fromFirstPage));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.riskManagement.playerSuspiciousActivities.history.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFilters);
