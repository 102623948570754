import localStorageUtils from "utils/localStorage";

import {
	TRANSLATION_GROUPS_BEFORE,
	TRANSLATION_GROUPS_FINISH,
	TRANSLATION_SET_GROUPS,
	TRANSLATIONS_BEFORE,
	TRANSLATIONS_FINISH,
	SET_TRANSLATIONS,
	TRANSLATION_SAVE_BEFORE,
	TRANSLATION_SAVE_FINISH,
	UPDATE_TRANSLATION,
	TRANSLATION_SET_SYSTEM_DEFAULT,
	TRANSLATIONS_PUBLISH_BEFORE,
	TRANSLATIONS_PUBLISH_FINISH,
	TRANSLATIONS_UNPUBLISH_BEFORE,
	TRANSLATIONS_UNPUBLISH_FINISH,
	SET_LIVE_MONITOR_TRANSLATIONS,
	UPDATE_LIVE_MONITOR_TRANSLATION,
	SET_SELECTED_GAME_TYPE,
	SET_RULES,
	RULES_SET_SORTING,
	SET_RULES_HISTORY,
	RULES_HISTORY_SORTING,
	SET_RULE_DETAILS,
	UPDATE_RULE,
	RULE_TRANSLATION_SET_SYSTEM_DEFAULT,
	PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_FINISH,
	PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_BEFORE,
	SET_PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE
} from "../../actionTypes";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case TRANSLATION_GROUPS_BEFORE:
			return {
				...state,
				isGroupsLoading: true
			};
		case TRANSLATION_GROUPS_FINISH:
			return {
				...state,
				isGroupsLoading: false
			};
		case TRANSLATION_SET_GROUPS:
			return {
				...state,
				groups: payload.groups
			};
		case TRANSLATIONS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case TRANSLATIONS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_TRANSLATIONS:
			return {
				...state,
				translations: payload.translations
			};
		case TRANSLATION_SAVE_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case TRANSLATION_SAVE_FINISH:
			return {
				...state,
				isSaving: false
			};
		case UPDATE_TRANSLATION:
			return {
				...state,
				translations: state.translations.map((t) => (t.key === payload.key ? { ...t, translations: t.translations.map((tr) => (tr.languageCode.toLowerCase() === payload.translation.languageCode.toLowerCase() ? payload.translation : tr)) } : t))
			};
		case TRANSLATION_SET_SYSTEM_DEFAULT:
			localStorageUtils.set("systemDefaultTranslation", payload.systemDefault);
			return {
				...state,
				systemDefault: payload.systemDefault
			};
		case RULE_TRANSLATION_SET_SYSTEM_DEFAULT:
			localStorageUtils.set("rulesSystemDefaultTranslation", payload.systemDefault);
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						main: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].main,
							systemDefault: payload.systemDefault
						}
					}
				}
			};
		case TRANSLATIONS_PUBLISH_BEFORE:
			return {
				...state,
				isPublishing: true
			};
		case TRANSLATIONS_PUBLISH_FINISH:
			return {
				...state,
				isPublishing: false
			};
		case TRANSLATIONS_UNPUBLISH_BEFORE:
			return {
				...state,
				isUnpublishing: true
			};
		case TRANSLATIONS_UNPUBLISH_FINISH:
			return {
				...state,
				isUnpublishing: false
			};
		case SET_LIVE_MONITOR_TRANSLATIONS:
			return {
				...state,
				liveMonitorTranslations: payload.translations
			};
		case UPDATE_LIVE_MONITOR_TRANSLATION:
			return {
				...state,
				liveMonitorTranslations: state.liveMonitorTranslations.map((t) => (t.key === payload.key ? { ...t, translations: t.translations.map((tr) => (tr.languageCode.toLowerCase() === payload.translation.languageCode.toLowerCase() ? payload.translation : tr)) } : t))
			};
		case SET_SELECTED_GAME_TYPE:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						main: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].main,
							selectedGameType: payload.gameType
						}
					}
				}
			};
		case SET_RULES:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						main: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].main,
							data: payload.data.item2,
							total: payload.data.item1
						}
					}
				}
			};
		case RULES_SET_SORTING:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						main: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].main,
							sorting: payload.sorting
						}
					}
				}
			};
		case SET_RULES_HISTORY:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						history: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].history,
							data: payload.data.item2,
							total: payload.data.item1
						}
					}
				}
			};
		case RULES_HISTORY_SORTING:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						history: {
							...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]].history,
							sorting: payload.sorting
						}
					}
				}
			};
		case SET_RULE_DETAILS:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						editingRule: payload.data
					}
				}
			};
		case UPDATE_RULE:
			return {
				...state,
				rules: {
					...state.rules,
					[REDUX_RULES_STORE_FIELD[payload.gameCategory]]: {
						...state.rules[REDUX_RULES_STORE_FIELD[payload.gameCategory]],
						editingRule: payload.data
					}
				}
			};
		case PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_BEFORE:
			return {
				...state,
				isPartnerAvailableLanguagesForRuleLoading: true
			};
		case PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE_FINISH:
			return {
				...state,
				isPartnerAvailableLanguagesForRuleLoading: false
			};
		case SET_PARTNER_AVAILABLE_PUBLISHED_LANGUAGES_FOR_RULE:
			return {
				...state,
				partnerAvailableLanguagesForRule: payload.data
			};
		default:
			return state;
	}
};
