import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setApikeySaveActionBefore, setApikeySaveActionFinished } from "./apikeys.action";

import { APIKEY_GAMES_BEFORE, APIKEY_GAMES_FINISH, SET_APIKEY_GAMES, SET_APIKEY_ADD_GAME, SET_APIKEY_UPDATE_GAME, SET_APIKEY_DELETE_GAME } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";
import { RESPONSE_STATE } from "constants/response.constants";
import { message } from "antd";

const setApiKeyGamesBefore = () => ({
	type: APIKEY_GAMES_BEFORE
});

const setApiKeyGamesFinished = () => ({
	type: APIKEY_GAMES_FINISH
});

const setApikeyGames = (games, gameCategory) => ({
	type: SET_APIKEY_GAMES,
	payload: { games, gameCategory }
});

export const setApiKeyAddGame = (game, gameCategory) => ({
	type: SET_APIKEY_ADD_GAME,
	payload: { game, gameCategory }
});

export const setApiKeyUpdateGame = (game, gameCategory) => ({
	type: SET_APIKEY_UPDATE_GAME,
	payload: { game, gameCategory }
});

export const setApiKeyDeleteGame = (id, gameCategory) => ({
	type: SET_APIKEY_DELETE_GAME,
	payload: { id, gameCategory }
});

export const getApiKeyGames = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_GET_SCHEDULED_GAME : ApiUrls.APIKEY_GET_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setApiKeyGamesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: games } }) => {
				dispatch(setApikeyGames(games, gameCategory))
				dispatch(setApiKeyGamesFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyGamesFinished());
			})
	}
}

export const saveApiKeyGames = (id, games, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_SAVE_SCHEDULED_GAMES : ApiUrls.APIKEY_SAVE_INSTANT_GAMES;
	return (dispatch) => {
		dispatch(setApiKeyGamesBefore());
		dispatch(setApikeyGames(games, gameCategory));
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: { id, gameIds: games.map((g) => g.id) }
		})
			.then(({ data: { value: games } }) => {
				dispatch(setApikeyGames(games, gameCategory));
				dispatch(setApiKeyGamesFinished());
			})
			.catch((ex) => {
				dispatch(setApiKeyGamesFinished());
			});
	};
};

export const addApiKeyGame = (game, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_ADD_SCHEDULED_GAME : ApiUrls.APIKEY_ADD_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: game
		})
			.then(({ data: { value: game } }) => {
				dispatch(setApiKeyAddGame(game, gameCategory));
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const addApiKeyGames = (gamesData, gameCategory, onSuccess) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_ADD_SCHEDULED_GAMES : ApiUrls.APIKEY_ADD_INSTANT_GAMES;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: gamesData
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess && onSuccess();
				}
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const updateApiKeyGame = (game, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_SAVE_SCHEDULED_GAME : ApiUrls.APIKEY_SAVE_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: game
		})
			.then(({ data: { value: game } }) => {
				dispatch(setApiKeyUpdateGame(game, gameCategory));
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const deleteApiKeyGame = (id, gameId, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.APIKEY_DELETE_SCHEDULED_GAME : ApiUrls.APIKEY_DELETE_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setApikeySaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.DELETE,
			data: { id, gameId }
		})
			.then(() => {
				dispatch(setApiKeyDeleteGame(gameId, gameCategory));
				dispatch(setApikeySaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setApikeySaveActionFinished());
			});
	};
};

export const resetApiKeyGames = (gameCategory) => (dispatch) => {
	dispatch(setApikeyGames([], gameCategory));
};
