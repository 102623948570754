import { PROJECT_TYPE } from "constants/common.constants";

const PATHS = {
	AUTHENTICATE: "/User/Login",
	REFRESH_TOKEN: "/User/RefreshToken",
	AUTHENTICATE_QR: "/User/AuthenticatorQR",
	AUTHENTICATE_TOKEN: "/User/AuthenticateToken",
	LOGOUT: "/User/Logout",

	GET_PROFILE: "/User/Profile",
	SAVE_PROFILE: "/User/SaveProfile",
	GET_USERINFO: "/User/Info",

	GET_RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS: "/BigMultiplierActivity/Settings",
	SAVE_RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS: "/BigMultiplierActivity/SaveSettings",
	GET_RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY: "/BigMultiplierActivity/All",
	EXPORT_RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY: "/BigMultiplierActivity/ExportAll",

	GET_RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS: "/BetAnomaly/Settings",
	SAVE_RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS: "/BetAnomaly/SaveSettings",
	SAVE_RISK_MANAGEMENT_BET_ANOMALY: "/BetAnomaly/SaveBetAnomaly",
	GET_RISK_MANAGEMENT_BET_ANOMALIES_HISTORY: "/BetAnomaly/All",
	EXPORT_RISK_MANAGEMENT_BET_ANOMALIES_HISTORY: "/BetAnomaly/ExportAll",

	GET_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY: "/SuspiciousActivity/All",
	GET_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS: "/SuspiciousActivity/Settings",
	ADD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION: "/SuspiciousActivity/AddSetting",
	SAVE_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION: "/SuspiciousActivity/SaveSetting",
	DELETE_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION: "/SuspiciousActivity/DeleteSetting",
	EXPORT_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY: "/SuspiciousActivity/ExportAll",
	CHANGE_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_RISK_CASE_STATUS: "/SuspiciousActivity/ChangeStatus",


	GET_USERS: "/User/All",
	CREATE_USER: "/User/Create",
	USER_GET_INACTIVE: "/User/Inactive",
	USER_GET_AVAILABLE_PARTNERS: "/User/AvailablePartners",
	USER_GET_GENERAL_INFO: "/User/GeneralInfo",
	USER_SAVE_GENERAL_INFO: "/User/SaveGeneralInfo",
	USER_GET_PROJECT_ACCESS: "/User/ProjectsAccess",
	USER_ADD_PROJECT_ACCESS: "/User/AddProjectAccess",
	USER_DELETE_PROJECT_ACCESS: "/User/DeleteProjectAccess",
	USER_ADD_PARTNER_ACCESS: "/User/AddPartnerAccess",
	USER_DELETE_PARTNER_ACCESS: "/User/DeletePartnerAccess",
	USER_GET_PARTNER_ACCESS: "/User/PartnerAccess",
	USER_ADD_BETSHOP_ACCESS: "/User/AddBetShopAccess",
	USER_DELETE_BETSHOP_ACCESS: "/User/DeleteBetShopAccess",
	USER_GET_BETSHOPS_ACCESS: "/User/BetShopsAccess",
	USER_UPLOAD_AVATAR: "/User/UploadAvatar",
	USER_DELETE_AVATAR: "/User/DeleteAvatar",
	USER_UPLOAD_PROFILE_AVATAR: "/User/UploadProfileAvatar",
	USER_DELETE_PROFILE_AVATAR: "/User/DeleteProfileAvatar",
	GET_PASSWORD_SETTINGS: "/User/PasswordSettings",
	CHANGE_PASSWORD: "/User/ChangePassword",
	USER_ACTIVATE: "/User/Activate",
	GET_ACTIVATION_PASSWORD_SETTINGS: "/User/ActivationPasswordSettings",
	RESET_PASSWORD: "/User/ResetPassword",
	FORGOT_PASSWORD: "/User/ForgotPassword",
	SET_PASSWORD: "/User/SetPassword",
	CHANGE_USER_STATE: "/User/ChangeState",
	DELETE_USER: "/User/Delete",
	USER_FORCE_LOGOUT: "/User/ForceLogout",
	USER_UNLOCK: "/User/Unlock",
	USER_RESEND_REGISTRATION_EMAIL: "/User/ResendRegistrationEmail",
	EXPORT_USERS: "/User/ExportAll",
	EXPORT_USERS_REPRINT_REQUEST: "/User/ExportReprintRequests",

	GET_REPRINT_REQUESTS: "/User/ReprintRequests",
	DECLINE_REPRINT_REQUEST: "/User/DeclineReprintRequest",
	APPROVE_REPRINT_REQUEST: "/User/ApproveReprintRequest",

	GET_NOTIFICATIONS: "/User/Notifications",
	NOTIFICATIONS_MARK_AS_READ: "/User/MarkAsRead",
	DELETE_NOTIFICATIONS: "/User/DeleteNotification",

	GET_ACCESS_MANAGERS: "/AccessManager/All",
	EXPORT_ACCESS_MANAGERS: "/AccessManager/Export",
	CREATE_ACCESS_MANAGER: "/AccessManager/Create",
	GET_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/GeneralInfo",
	SAVE_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/SaveGeneralInfo",
	CHANGE_ACCESS_MANAGER_STATE: "/AccessManager/ChangeState",

	GET_PERMISSION_REQUESTS: "/Permission/Requests",
	APPROVE_PERMISSION_REQUEST: "/Permission/Approve",
	REJECT_PERMISSION_REQUEST: "/Permission/Reject",
	GET_PERMISSION_REQUESTS_HISTORY: "/Permission/RequestHistory",

	GET_USER_PERMISSIONS: "/Permission/UserPermissions",
	SAVE_USER_PERMISSIONS: "/Permission/SaveUserPermissions",

	USER_GET_PERMISSION_GROUPS: "/Permission/UserGroups",
	USER_ADD_PERMISSION_GROUP: "/Permission/AddUserPermissionGroup",
	USER_DELETE_PERMISSION_GROUP: "/Permission/DeleteUserPermissionGroup",
	USER_GET_AVAILABLE_PERMISSION_GROUPS: "/Permission/AvailableGroups",

	GET_SYSTEM_PERMISSIONS: "/Permission/SystemPermissions",
	GET_SYSTEM_PERMISSION_RESOURCES: "/Permission/SystemResources",

	GET_PERMISSION_GROUPS: "/Permission/AllGroups",
	CREATE_PERMISSION_GROUP: "/Permission/CreateGroup",
	DELETE_PERMISSION_GROUP: "/Permission/DeleteGroup",
	PERMISSION_GROUP_GET_PERMISSIONS: "/Permission/GroupPermissions",
	PERMISSION_GROUP_SAVE_PERMISSIONS: "/Permission/SaveGroupPermissions",
	PERMISSION_GROUP_GET_USERS: "/Permission/GroupUsers",
	PERMISSION_GROUP_ADD_USERS: "/Permission/AddUsersPermissionGroup",
	EXPORT_PERMISSION_GROUPS: "/Permission/ExportAll",
	EXPORT_PERMISSSION_GROUP_USERS: "/Permission/ExportGroupUsers",

	GET_PARTNERS: "/Partner/All",
	CREATE_PARTNER: "/Partner/Create",
	GET_PARTNER_GENERAL_INFO: "/Partner/GetGeneralInfo",
	SET_PARTNER_GENERAL_INFO: "/Partner/SaveGeneralInfo",
	GET_PARTNER_ONLINE_SETTINGS: "/Partner/GetOnlineSettings",
	SET_PARTNER_ONLINE_SETTINGS: "/Partner/SaveOnlineSettings",
	GET_PARTNER_ONLINE_RTP: "/Partner/GetOnlineRTP",
	SET_PARTNER_ONLINE_RTP: "/Partner/SaveOnlineRTP",
	GET_PARTNER_RETAIL_CONFIGS: "/Partner/GetRetailConfiguration",
	SET_PARTNER_RETAIL_CONFIGS: "/Partner/SaveRetailConfiguration",
	PARTNER_UPLOAD_LOGO: "/Partner/UploadBetShopsLogo",
	PARTNER_DELETE_LOGO: "/Partner/DeleteBetShopsLogo",
	GET_PARTNER_RETAIL_RTP: "/Partner/GetRetailRTP",
	SET_PARTNER_RETAIL_RTP: "/Partner/SaveRetailRTP",
	PARTNER_UPLOAD_TICKET_LOGO: "/Partner/UploadTicketLogo",
	PARTNER_DELETE_TICKET_LOGO: "/Partner/DeleteTicketLogo",
	PARTNER_GET_TICKET_LOGO: "/Partner/TicketLogo",
	GET_PARTNER_RETAIL_BET_TICKET: "/Partner/GetRetailBetTicket",
	SET_PARTNER_RETAIL_BET_TICKET: "/Partner/SaveRetailBetTicket",
	GET_PARTNER_RETAIL_CANCEL_TICKET: "/Partner/GetRetailCancelTicket",
	SET_PARTNER_RETAIL_CANCEL_TICKET: "/Partner/SaveRetailCancelTicket",
	GET_PARTNER_RETAIL_PAYOUT_TICKET: "/Partner/GetRetailPayoutTicket",
	SET_PARTNER_RETAIL_PAYOUT_TICKET: "/Partner/SaveRetailPayoutTicket",
	GET_PARTNER_SECURITY_PASSWORD_SETTINGS: "/Partner/GetPasswordSettings",
	SET_PARTNER_SECURITY_PASSWORD_SETTINGS: "/Partner/SavePasswordSettings",
	GET_PARTNER_SECURITY_MULTIDEVICE_TOKEN_SETTINGS: "/Partner/GetTokenSettings",
	GET_PARTNER_SECURITY_AUTHENTICATION_SETTINGS: "/Partner/GetAuthenticationSettings",
	SET_PARTNER_SECURITY_AUTHENTICATION_SETTINGS: "/Partner/SaveAuthenticationSettings",
	SET_PARTNER_SECURITY_MULTIDEVICE_TOKEN_SETTINGS: "/Partner/SaveTokenSettings",
	GET_PARTNER_SECURITY_IPRESTRICTION_SETTINGS: "/Partner/GetIPRestriction",
	SET_PARTNER_SECURITY_IPRESTRICTION_SETTINGS: "/Partner/SaveIPRestriction",
	GET_PARTNER_CURRENCIES: "/Partner/GetCurrencySettings",
	ADD_PARTNER_CURRENCY: "/Partner/AddCurrency",
	SAVE_PARTNER_CURRENCY: "/Partner/SaveCurrency",
	DELETE_PARTNER_CURRENCY: "/Partner/DeleteCurrency",
	GET_PARTNER_AVAILABLE_CURRENCIES: "/Partner/AvailableCurrencies",
	GET_PARTNER_LANGUAGES: "/Partner/GetLanguageSettings",
	ADD_PARTNER_LANGUAGE: "/Partner/AddLanguage",
	SAVE_PARTNER_LANGUAGE: "/Partner/SaveLanguage",
	DELETE_PARTNER_LANGUAGE: "/Partner/DeleteLanguage",
	GET_PARTNER_AVAILABLE_LANGUAGES: "/Partner/AvailableLanguages",
	GET_PARTNER_WALLET: "/Partner/GetWallet",
	SAVE_PARTNER_WALLET: "/Partner/SaveWallet",
	GET_PARTNER_WALLET_HISTORY: "/Partner/GetWalletHistory",
	EXPORT_PARTNERS: "/Partner/ExportAll",
	EXPORT_PARTNERS_WALLET_HISTORY: "/Partner/ExportWalletHistory",

	GETALL_SCHEDULED_GAMES: "/ScheduledGame/All",
	CREATE_SCHEDULED_GAME: "/ScheduledGame/Create",
	DELETE_SCHEDULED_GAME: "/ScheduledGame/Delete",
	CHANGE_SCHEDULED_GAME_STATE: "/ScheduledGame/ChangeState",
	SCHEDULED_GAME_GET_DETAILS: "/ScheduledGame/GetDetails",
	SCHEDULED_GAME_GET_GENERAL_INFO: "/ScheduledGame/GetGeneralInfo",
	SCHEDULED_GAME_SAVE_GENERAL_INFO: "/ScheduledGame/SaveGeneralInfo",
	SCHEDULED_GAME_GET_FOOTBALL_SCENE_PROBABILITY: "/ScheduledGame/FootballSceneProbability",
	SCHEDULED_GAME_GET_PENALTY_GOAL_MISS_PROBABILITY: "/ScheduledGame/PenaltyGoalMissProbability",
	SCHEDULED_GAME_SAVE_FOOTBALL_SCENE_PROBABILITY: "/ScheduledGame/SaveFootballSceneProbability",
	SCHEDULED_GAME_SAVE_PENALTY_GOAL_MISS_PROBABILITY: "/ScheduledGame/SavePenaltyGoalMissSceneProbability",
	SCHEDULED_GAME_GET_FOOTBALL_HANDICAP_PROBABILITY: "/ScheduledGame/FootballHandicapProbability",
	SCHEDULED_GAME_GET_PENALTY_HANDICAP_PROBABILITY: "/ScheduledGame/PenaltyHandicapProbability",
	SCHEDULED_GAME_SAVE_FOOTBALL_HANDICAP_PROBABILITY: "/ScheduledGame/SaveFootballHandicapProbability",
	SCHEDULED_GAME_GET_STRENGTHS: "/ScheduledGame/RaceParticipantStrengths",
	SCHEDULED_GAME_SAVE_STRENGTHS: "/ScheduledGame/SaveRaceParticipantStrengths",
	SCHEDULED_GAME_GET_COMPETITIONS_AND_RACES: "/ScheduledGame/RaceParticipantCompetitionsAndRaces",
	SCHEDULED_GAME_SAVE_COMPETITIONS_AND_RACES: "/ScheduledGame/SaveRaceParticipantCompetitionsAndRaces",
	SCHEDULED_GAME_GET_FOOTBALL_TEAMS: "/ScheduledGame/FootballTeams",
	SCHEDULED_GAME_GET_PENALTY_TEAMS: "/ScheduledGame/PenaltyTeams",
	SCHEDULED_GAME_SAVE_FOOTBALL_TEAMS: "/ScheduledGame/SaveFootballTeams",
	SCHEDULED_GAME_SAVE_PENALTY_TEAMS: "/ScheduledGame/SavePenaltyTeams",
	SCHEDULED_GAME_GET_RTPS: "/ScheduledGame/GetRTPs",
	SCHEDULED_GAME_SAVE_RTPS: "/ScheduledGame/SaveRTPs",
	SCHEDULED_GAME_GET_BANNERS: "/ScheduledGame/Banners",
	SCHEDULED_GAME_GET_BANNER: "/ScheduledGame/Banner",
	SCHEDULED_GAME_UPLOAD_BANNER: "/ScheduledGame/UploadBanner",
	SCHEDULED_GAME_DELETE_BANNER: "/ScheduledGame/DeleteBanner",
	SCHEDULED_GAME_UPLOAD_LIVE_MONITOR_LOGO: "/ScheduledGame/UploadLiveMonitorLogo",
	SCHEDULED_GAME_DELETE_LIVE_MONITOR_LOGO: "/ScheduledGame/DeleteLiveMonitorLogo",
	SCHEDULED_GAME_GET_STREAM_CONFIGURATION: "/ScheduledGame/StreamConfiguration",
	SCHEDULED_GAME_SAVE_STREAM_CONFIGURATION: "/ScheduledGame/SaveStreamConfiguration",
	SCHEDULED_GAMES_ALL_PENALTY_TEAMS: "/ScheduledGame/AllPenaltyTeams",
	SCHEDULED_GAMES_ADD_TEAMS: "/ScheduledGame/AddPenaltyTeams",
	EXPORT_SCHEDULED_GAMES: "/ScheduledGame/ExportAll",

	GETALL_INSTANT_GAMES: "/InstantGame/All",
	CREATE_INSTANT_GAME: "/InstantGame/Create",
	DELETE_INSTANT_GAME: "/InstantGame/Delete",
	CHANGE_INSTANT_GAME_STATE: "/InstantGame/ChangeState",
	INSTANT_GAME_GET_DETAILS: "/InstantGame/GetDetails",
	INSTANT_GAME_GET_GENERAL_INFO: "/InstantGame/GetGeneralInfo",
	INSTANT_GAME_SAVE_GENERAL_INFO: "/InstantGame/SaveGeneralInfo",
	INSTANT_GAME_GET_FOOTBALL_SCENE_PROBABILITY: "/InstantGame/FootballSceneProbability",
	INSTANT_GAME_SAVE_FOOTBALL_SCENE_PROBABILITY: "/InstantGame/SaveFootballSceneProbability",
	INSTANT_GAME_GET_FOOTBALL_TEAMS: "/InstantGame/FootballTeams",
	INSTANT_GAME_SAVE_FOOTBALL_TEAMS: "/InstantGame/SaveFootballTeams",
	INSTANT_GAME_GET_RTPS: "/InstantGame/GetRTPs",
	INSTANT_GAME_SAVE_RTPS: "/InstantGame/SaveRTPs",
	INSTANT_GAME_GET_STREAM_CONFIGURATION: "/InstantGame/StreamConfiguration",
	INSTANT_GAME_SAVE_STREAM_CONFIGURATION: "/InstantGame/SaveStreamConfiguration",
	EXPORT_INSTANT_GAMES: "/InstantGame/ExportAll",

	STREAMS_GETALL: "/Stream/All",
	STREAMS_CREATE: "/Stream/Create",
	STREAMS_GET_GENERAL_INFO: "/Stream/GetGeneralInfo",
	STREAMS_SAVE_GENERAL_INFO: "/Stream/SaveGeneralInfo",
	STREAMS_GET_AVAILABLE_GAMES: "/Stream/AvailableGames",
	EXPORT_STREAMS: "/Stream/ExportAll",

	// Stream servers
	STREAM_SERVERS_GET_ALL: "/StreamServer/All",
	STREAM_SERVERS_RESTART: "/StreamServer/RestartStreamServer",
	STREAM_SERVERS_ADD_GAME: "/StreamServer/AddGame",
	STREAM_SERVERS_RESTART_GAME: "/StreamServer/RestartGame",
	STREAM_SERVERS_REMOVE_GAME: "/StreamServer/RemoveGame",
	STREAM_SERVERS_GET_AVAILABLE_GAMES: "/StreamServer/AvailableGames",

	// Post Deployment Actions
	EXPORT_POST_DEPLOYMENT_ACTIONS_TRANSLATIONS: "/Developer/ExportTranslations",
	UPDATE_POST_DEPLOYMENT_ACTIONS_TRANSLATIONS: "/Developer/UpdateTranslations",
	GET_POST_DEPLOYMENT_TRANSLATION_ACTIONS_LANGUAGES: "/Developer/Languages",
	POST_DEPLOYMENT_ACTIONS_FLUSH_CACHE: "/Developer/FlushCache",

	// Betslip Corrections
	GET_BET_CORRECTIONS: "/Bet/BetsCorrections",

	GETALL_BOXES: "/Box/GetAll",
	CREATE_BOX: "/Box/Create",
	BOX_GET_GENERAL_INFO: "/Box/GetGeneralInfo",
	BOX_SAVE_GENERAL_INFO: "/Box/SaveGeneralInfo",
	BOX_ADD_GAME: "/Box/AddGame",
	BOX_ADD_GAMES: "/Box/AddScheduledGames",
	BOX_SAVE_GAME: "/Box/SaveGame",
	BOX_DELETE_GAME: "/Box/DeleteGame",
	BOX_SAVE_GAMES: "/Box/SaveGames",
	BOX_GET_GAMES: "/Box/GetGames",
	BOX_GET_RTP: "/Box/GetRTPs",
	BOX_SAVE_RTP: "/Box/SaveRTPs",

	GET_APIKEYS: "/ApiKey/All",
	CREATE_APIKEY: "/ApiKey/Create",
	DELETE_APIKEY: "/ApiKey/Delete",
	APIKEY_GET_GENERAL_INFO: "/ApiKey/GeneralInfo",
	APIKEY_SAVE_GENERAL_INFO: "/ApiKey/SaveGeneralInfo",
	APIKEY_SAVE_CURRENCY: "/ApiKey/SaveCurrency",
	APIKEY_GET_CURRENCIES: "/ApiKey/Currencies",
	APIKEY_DELETE_CURRENCY: "/ApiKey/DeleteCurrency",
	APIKEY_GET_BLOCKED_COUNTRIES: "/ApiKey/RestrictedCountries",
	APIKEY_ADD_BLOCKED_COUNTRY: "/ApiKey/AddRestrictedCountry",
	APIKEY_DELETE_BLOCKED_COUNTRY: "/ApiKey/DeleteRestrictedCountry",
	APIKEY_GET_BLOCKED_IPS: "/ApiKey/RestrictedIPs",
	APIKEY_ADD_BLOCKED_IP: "/ApiKey/AddRestrictedIP",
	APIKEY_DELETE_BLOCKED_IP: "/ApiKey/DeleteRestrictedIP",
	APIKEY_UPLOAD_LOGO: "/ApiKey/UploadMobileLogo",
	APIKEY_DELETE_LOGO: "/ApiKey/DeleteMobileLogo",
	APIKEY_GET_UI: "/ApiKey/GetUI",
	APIKEY_SAVE_UI: "/ApiKey/SaveUI",
	EXPORT_APIKEY: "/ApiKey/ExportAll",
	APIKEY_GET_ADD_ONS: "/ApiKey/AddOnsSettings",
	APIKEY_SAVE_ADD_ONS: "/ApiKey/SaveAddOnsSettings",

	GET_ONLINE_BETS: "/Bet/OnlineBetSlips",
	GET_RETAIL_BETS: "/Bet/RetailBetSlips",

	GET_ONLINE_BETS_NEW: "/Bet/OnlineBets",
	GET_RETAIL_BETS_NEW: "/Bet/RetailBets",

	BETS_CANCEL_BET: "/Bet/CancelBet",
	BETS_CANCEL_BETSLIP: "/Bet/CancelBetSlip",
	BETS_CHANGE_BET: "/Bet/ChangeBet",
	BETS_CHANGE_BETSLIP: "/Bet/ChangeBetSlip",
	EXPORT_ONLINE_BETS: "/Bet/ExportOnlineBets",
	EXPORT_ONLINE_BETSLIPS: "/Bet/ExportOnlineBetSlips",
	EXPORT_RETAIL_BETS: "/Bet/ExportRetailBets",
	EXPORT_RETAIL_BETSLIPS: "/Bet/ExportRetailBetSlips",
	
	CORRECT_BETSLIP_STATUS: "/Bet/CorrectBetSlipStatus",

	APIKEY_ADD_SCHEDULED_GAME: "/ApiKey/AddScheduledGame",
	APIKEY_ADD_SCHEDULED_GAMES: "/ApiKey/AddScheduledGames",
	APIKEY_SAVE_SCHEDULED_GAME: "/ApiKey/SaveScheduledGame",
	APIKEY_GET_SCHEDULED_GAME_OTHER_CONFIGS: "/ApiKey/ScheduledGameOtherConfigs",
	APIKEY_SAVE_SCHEDULED_GAME_OTHER_CONFIGS: "/ApiKey/SaveScheduledGameOtherConfigs",
	APIKEY_DELETE_SCHEDULED_GAME: "/ApiKey/DeleteScheduledGame",
	APIKEY_GET_SCHEDULED_GAME: "/ApiKey/ScheduledGames",
	APIKEY_SAVE_SCHEDULED_GAMES: "/ApiKey/SaveScheduledGames",
	APIKEY_GET_SCHEDULED_GAME_RTP: "/ApiKey/ScheduledGameRTPs",
	APIKEY_SAVE_SCHEDULED_GAME_RTP: "/ApiKey/SaveScheduledGameRTPs",
	APIKEY_GET_SCHEDULED_GAME_ANALYTICAL_TOOLS: "/ApiKey/ScheduledGameAnalyticalTools",
	APIKEY_SAVE_SCHEDULED_GAME_ANALYTICAL_TOOLS: "/ApiKey/SaveScheduledGameAnalyticalTools",
	APIKEY_GET_SCHEDULED_GAME_PLATFORMS: "/ApiKey/ScheduledGamePlatforms",
	APIKEY_SAVE_SCHEDULED_GAME_PLATFORMS: "/ApiKey/SaveScheduledGamePlatforms",

	APIKEY_ADD_INSTANT_GAME: "/ApiKey/AddInstantGame",
	APIKEY_ADD_INSTANT_GAMES: "/ApiKey/AddInstantGames",
	APIKEY_SAVE_INSTANT_GAME: "/ApiKey/SaveInstantGame",
	APIKEY_GET_INSTANT_GAME_OTHER_CONFIGS: "/ApiKey/InstantGameOtherConfigs",
	APIKEY_SAVE_INSTANT_GAME_OTHER_CONFIGS: "/ApiKey/SaveInstantGameOtherConfigs",
	APIKEY_DELETE_INSTANT_GAME: "/ApiKey/DeleteInstantGame",
	APIKEY_GET_INSTANT_GAME: "/ApiKey/InstantGames",
	APIKEY_SAVE_INSTANT_GAMES: "/ApiKey/SaveInstantGames",
	APIKEY_GET_INSTANT_GAME_RTP: "/ApiKey/InstantGameRTPs",
	APIKEY_SAVE_INSTANT_GAME_RTP: "/ApiKey/SaveInstantGameRTPs",
	APIKEY_GET_INSTANT_GAME_ANALYTICAL_TOOLS: "/ApiKey/InstantGameAnalyticalTools",
	APIKEY_SAVE_INSTANT_GAME_ANALYTICAL_TOOLS: "/ApiKey/SaveInstantGameAnalyticalTools",
	APIKEY_GET_INSTANT_GAME_PLATFORMS: "/ApiKey/InstantGamePlatforms",
	APIKEY_SAVE_INSTANT_GAME_PLATFORMS: "/ApiKey/SaveInstantGamePlatforms",

	SCHEDULED_EVENTS_GET_ALL: "/ScheduledEvents/All",
	SCHEDULED_EVENTS_GET_EVENT: "/ScheduledEvents/Event",
	SCHEDULED_EVENTS_RECALCULATE: "/ScheduledEvents/Recalculate",
	SCHEDULED_EXPORT_EVENTS: "/ScheduledEvents/ExportAll",

	INSTANT_EVENTS_GET_ALL: "/InstantEvents/All",
	INSTANT_EVENTS_GET_EVENT: "/InstantEvents/Event",
	INSTANT_EVENTS_RECALCULATE: "/InstantEvents/Recalculate",
	INSTANT_EXPORT_EVENTS: "/InstantEvents/ExportAll",

	GET_PLAYERS: "/Player/All",
	GET_PLAYER_QUICK_VIEW: "/Player/QuickView",
	GET_PLAYER_SESSIONS: "/Player/SessionHistory",
	// GET_PLAYER_BET_HISTORY: "/Player/BetHistory",
	GET_PLAYER_BET_HISTORY: "/Player/BetSlipHistory",
	PLAYER_CANCEL_BETSLIP: "/Player/CancelBetSlip",
	PLAYER_CANCEL_BET: "/Player/CancelBet",
	CHANGE_PLAYER_STATE: "/Player/ChangeState",
	GET_PLAYER_DB_CACHE_COMPARE: "/Player/DBCacheCompare",
	GET_PLAYER_DB_CACHE_SYNCHRONIZE: "/Player/DBCacheSynchronize",
	EXPORT_PLAYERS: "/Player/ExportAll",
	EXPORT_PLAYERS_SESSION_HISTORY: "/Player/ExportSessionHistory",
	// EXPORT_PLAYERS_BET_HISTORY: "/Player/ExportBetHistory",
	EXPORT_PLAYERS_BET_HISTORY: "/Player/ExportBetSlipHistory",

	GET_BETSHOPS: "/BetShop/All",
	CREATE_BETSHOP: "/BetShop/Create",
	BETSHOP_GET_GENERAL_INFO: "/BetShop/GetGeneralInfo",
	BETSHOP_SAVE_GENERAL_INFO: "/BetShop/SaveGeneralInfo",
	BETSHOP_SAVE_CURRENCY: "/BetShop/SaveCurrency",
	BETSHOP_GET_CURRENCY: "/BetShop/GetCurrency",
	BETSHOP_DELETE_CURRENCY: "/BetShop/DeleteCurrency",
	GET_BETSHOP_BOXES: "/BetShop/GetBoxes",
	ADD_BETSHOP_BOX: "/BetShop/AddBox",
	SAVE_BETSHOP_BOX: "/BetShop/SaveBox",
	DELETE_BETSHOP_BOX: "/BetShop/DeleteBox",
	GET_BETSHOP_AVAILABLE_BOXES: "/BetShop/GetUnUsedBoxes",
	BETSHOP_GET_OPERATING_SETTINGS: "/BetShop/GetOperatingSettings",
	BETSHOP_SAVE_OPERATING_SETTINGS: "/BetShop/SaveOperatingSettings",
	BETSHOP_GET_UI: "/BetShop/GetUI",
	BETSHOP_SAVE_UI: "/BetShop/SaveUI",
	GET_BETSHOP_WALLET_HISTORY: "/BetShop/GetWalletHistory",
	GET_BETSHOP_WALLET: "/BetShop/GetWallet",
	SAVE_BETSHOP_WALLET: "/BetShop/SaveWallet",
	BETSHOP_UPLOAD_LOGO: "/BetShop/UploadLogo",
	BETSHOP_DELETE_LOGO: "/BetShop/DeleteLogo",
	BETSHOP_GET_SESSIONS: "/BetShop/AllSessions",
	BETSHOP_GET_SESSION_REPORT: "/BetShop/SessionReport",
	BETSHOP_GET_LIMIT_SETTINGS: "/BetShop/GetLimitSettings",
	BETSHOP_SAVE_LIMIT_SETTINGS: "/BetShop/SaveLimitSettings",
	BETSHOP_GET_BONUS_CONFIGS: "/BetShop/GetBonusConfigs",
	BETSHOP_SAVE_BONUS_CONFIGS: "/BetShop/SaveBonusConfigs",
	GET_BETSHOP_DB_CACHE_COMPARE: "/BetShop/DBCacheCompare",
	GET_BETSHOP_DB_CACHE_SYNCHRONIZE: "/BetShop/DBCacheSynchronize",
	EXPORT_BETSHOPS: "/BetShop/ExportAll",
	EXPORT_BETSHOPS_WALLET_HISTORY: "/BetShop/ExportWalletHistory",
	EXPORT_BETSHOPS_ALL_SESSIONS: "/BetShop/ExportAllSessions",

	GET_CASHIERS: "/Cashier/All",
	CREATE_CASHIER: "/Cashier/Create",
	CHANGE_CASHIER_STATE: "/Cashier/ChangeState",
	CASHIER_GET_GENERAL_INFO: "/Cashier/GetGeneralInfo",
	CASHIER_SAVE_GENERAL_INFO: "/Cashier/SaveGeneralInfo",
	CASHIER_GET_AVAILABLE_BETSHOPS: "/Cashier/AvailableBetshops",
	CASHIER_FORCE_LOGOUT: "/Cashier/ForceLogout",
	CASHIER_RESEND_REGISTRATION_EMAIL: "/Cashier/ResendRegistrationEmail",
	EXPORT_CASHIERS: "/Cashier/ExportAll",

	BONUSES_GETALL: "/Bonus/All",
	BONUS_CREATE: "/Bonus/Create",
	BONUS_GET_GENERAL_INFO: "/Bonus/GeneralInfo",
	BONUS_SAVE_GENERAL_INFO: "/Bonus/SaveGeneralInfo",
	BONUS_CHANGE_STATE: "/Bonus/ChangeState",
	BONUS_AVAILABLE_BETSHOPS: "/Bonus/AvailableBetShops",
	BONUS_AVAILABLE_GAMES: "/Bonus/AvailableGames",
	BONUS_GET_QUICK_VIEW: "/Bonus/QuickView",
	BONUS_GET_PRINT_VOUCHERS: "/Bonus/PrintVouchers",
	EXPORT_BONUSES: "/Bonus/ExportAll",

	JACKPOTS_GETALL: "/Jackpot/All",
	JACKPOT_CREATE: "/Jackpot/Create",
	JACKPOT_GET_QUICK_VIEW: "/Jackpot/QuickView",
	JACKPOT_GET_GENERAL_INFO: "/Jackpot/GeneralInfo",
	JACKPOT_SAVE_GENERAL_INFO: "/Jackpot/SaveGeneralInfo",
	JACKPOT_CHANGE_STATE: "/Jackpot/ChangeState",
	JACKPOT_AVAILABLE_BETSHOPS: "/Jackpot/AvailableBetShops",
	JACKPOT_AVAILABLE_GAMES: "/Jackpot/AvailableScheduledGames",
	JACKPOT_HISTORY: "/Jackpot/History",
	EXPORT_JACKPOT_HISTORY: "/Jackpot/ExportHistory",
	EXPORT_JACKPOTS: "/Jackpot/ExportAll",

	GET_TRANSACTIONS: "/BetTransaction/All",
	GET_TRANSACTIONS_HISTORY: "/BetTransaction/History",
	RESEND_TRANSACTION: "/BetTransaction/Resend",
	RESEND_ALL_TRANSACTIONS: "/BetTransaction/ResendAll",
	EXPOTS_BET_TRANSACTIONS: "/BetTransaction/ExportAll",

	GET_PROJECT_PERFORMANCE_REPORT: "/Report/ProjectPerformance",
	GET_BETSHOP_PERFORMANCE_REPORT: "/Report/BetshopPerformance",
	GET_PARTNER_ONLINE_REPORT: "/Report/PartnersRevenues",
	EXPORT_REPORT_PROJECT_PERFORMANCE: "/Report/ExportProjectPerformance",
	EXPORT_REPORT_BETSHOP_PERFORMANCE: "/Report/ExportBetshopPerformance",
	EXPORT_REPORT_PARTNERS_REVENUES: "/Report/ExportPartnersRevenues",

	GET_ERROR_LOGS: "/SystemLogs/Exceptions",
	GET_REQUEST_LOGS: "/SystemLogs/Requests",
	GET_ERROR_LOG: "/SystemLogs/ExceptionDetails",
	GET_REQUEST_LOG: "/SystemLogs/RequestDetails",
	GET_JOBS: "/Jobs/All",
	UNLOCK_JOBS: "/Jobs/Unlock",
	GET_JOBS_SETTINGS: "/Jobs/GetSettings",
	SAVE_JOBS_SETTINGS: "/Jobs/SaveSettings",

	TRANSLATION_GET_ALL_GROUPS: "/Translation/AllGroups",
	TRANSLATION_SAVE: "/Translation/Save",
	TRANSLATION_SAVE_SYSTEM_DEFAULT: "/Translation/SaveSystemDefault",
	TRANSLATION_CREATE: "/Translation/Create",
	TRANSLATION_RESET: "/Translation/Reset",
	TRANSLATION_GET_ALL: "/Translation/All",
	TRANSLATION_GET_ALL_SYSTEM_DEFAULT: "/Translation/AllSystemDefault",
	TRANSLATION_PUBLISH: "/Translation/Publish",
	TRANSLATION_PUBLISH_SYSTEM_DEFAULT: "/Translation/PublishSystemDefault",
	TRANSLATION_UNPUBLISH: "/Translation/Unpublish",

	LIVE_MONITOR_TRANSLATION_GET_ALL: "/Translation/LiveMonitor",
	LIVE_MONITOR_TRANSLATION_SAVE: "/Translation/SaveLiveMonitor",
	LIVE_MONITOR_TRANSLATION_PUBLISH: "/Translation/PublishLiveMonitor",

	SCHEDULED_RULE_GET_ALL: "/ScheduledRule/All",
	SCHEDULED_RULE_SYSTEM_ALL: "/ScheduledRule/SystemAll",
	SCHEDULED_RULE_GET_ALL_HISTORY: "/ScheduledRule/AllHistory",
	SCHEDULED_RULE_SYSTEM_GET_ALL_HISTORY: "/ScheduledRule/SystemAllHistory",
	SCHEDULED_RULE_GET_DETAILS: "/ScheduledRule/Details",
	SCHEDULED_RULE_GET_SYSTEM_DETAILS: "/ScheduledRule/SystemDetails",
	SCHEDULED_RULE_SAVE: "/ScheduledRule/Save",
	SCHEDULED_RULE_SYSTEM_SAVE: "/ScheduledRule/SystemSave",
	SCHEDULED_RULE_PUBLISH: "/ScheduledRule/Publish",
	SCHEDULED_SYSTEM_RULE_PUBLISH: "/ScheduledRule/SystemPublish",
	SCHEDULED_RULE_ADD: "/ScheduledRule/Add",
	SCHEDULED_PARTNER_AVAILABLE_LANGUAGES_FOR_RULE: "/ScheduledRule/PartnerAvailableLanguages",

	INSTANT_RULE_GET_ALL: "/InstantRule/All",
	INSTANT_RULE_SYSTEM_ALL: "/InstantRule/SystemAll",
	INSTANT_RULE_GET_ALL_HISTORY: "/InstantRule/AllHistory",
	INSTANT_RULE_SYSTEM_GET_ALL_HISTORY: "/InstantRule/SystemAllHistory",
	INSTANT_RULE_GET_DETAILS: "/InstantRule/Details",
	INSTANT_RULE_GET_SYSTEM_DETAILS: "/InstantRule/SystemDetails",
	INSTANT_RULE_SAVE: "/InstantRule/Save",
	INSTANT_RULE_SYSTEM_SAVE: "/InstantRule/SystemSave",
	INSTANT_RULE_PUBLISH: "/InstantRule/Publish",
	INSTANT_SYSTEM_RULE_PUBLISH: "/InstantRule/SystemPublish",
	INSTANT_RULE_ADD: "/InstantRule/Add",
	INSTANT_PARTNER_AVAILABLE_LANGUAGES_FOR_RULE: "/InstantRule/PartnerAvailableLanguages",

	GET_HOTKEYS: "/HotKey/All",

	USER_LOGS_GETALL: "/UserLogs/All",
	USER_LOGS_GET_DETAILS: "/UserLogs/Details",
	USER_LOGS_GET_RESOURCES: "/UserLogs/Resources",
	EXPORT_USER_LOGS_GETALL: "/UserLogs/ExportAll",

	SYSTEM_CURRENCIES_AVAILABLE: "/SystemCurrency/Available",
	SYSTEM_CURRENCIES_SYSTEM_AVAILABLE: "/SystemCurrency/SystemAvailable",
	SYSTEM_CURRENCIES_ALL: "/SystemCurrency/All",
	SYSTEM_CURRENCIES_SAVE: "/SystemCurrency/Save",
	SYSTEM_CURRENCIES_CREATE: "/SystemCurrency/Create",
	EXPORT_SYSTEM_CURRENCIES: "/SystemCurrency/ExportAll",

	SYSTEM_LANGUAGES_AVAILABLE: "/SystemLanguage/Available",
	SYSTEM_LANGUAGES_SYSTEM_AVAILABLE: "/SystemLanguage/SystemAvailable",
	SYSTEM_LANGUAGES_SYSTEM_AVAILABLE_PUBLISHED: "/SystemLanguage/SystemAvailablePublished",
	SYSTEM_LANGUAGES_ALL: "/SystemLanguage/All",
	SYSTEM_LANGUAGES_DELETE: "/SystemLanguage/Delete",
	SYSTEM_LANGUAGES_CREATE: "/SystemLanguage/Create",
	EXPORT_SYSTEM_LANGUAGES: "/SystemLanguage/ExportAll",

	DASHBOARD_PLAYERS_ACTIVITIES: "/Dashboard/PlayersActivities",
	DASHBOARD_ONLINE_BETSLIPS_ACTIVITIES: "/Dashboard/OnlineBetsActivities",
	DASHBOARD_RETAIL_BETSLIPS_ACTIVITIES: "/Dashboard/RetailBetsActivities",
	DASHBOARD_ONLINE_PLAYERS_COUNT: "/Dashboard/OnlinePlayersCount",
	DASHBOARD_ONLINE_PENDING_BETSLIPS_COUNT: "/Dashboard/OnlinePendingBetsCount",
	DASHBOARD_RETAIL_PENDING_BETSLIPS_COUNT: "/Dashboard/RetailPendingBetsCount",
	DASHBOARD_TOP_MARKETS: "/Dashboard/TopMarkets",
	DASHBOARD_ONLINE_TOP_MARKETS: "/Dashboard/OnlineTopMarkets",
	DASHBOARD_RETAIL_TOP_MARKETS: "/Dashboard/RetailTopMarkets",
	DASHBOARD_TOP_PLAYERS: "/Dashboard/TopPlayers",
	DASHBOARD_ONLINE_GAMES_PERFORMANCE: "/Dashboard/OnlineGamesPerformanceChart",
	DASHBOARD_RETAIL_GAMES_PERFORMANCE: "/Dashboard/RetailGamesPerformanceChart",
	DASHBOARD_ONLINE_PARTNERS_REVENUE: "/Dashboard/OnlinePartnersRevenue",
	DASHBOARD_RETAIL_PARTNERS_REVENUE: "/Dashboard/RetailPartnersRevenue",

	GET_MAINTENANCE_MODE: "/Settings/MaintenanceMode",
	SAVE_MAINTENANCE_MODE: "/Settings/SaveMaintenanceMode",

	GET_OPERATORS: "/Settings/Operators",
	GET_OPERATOR: "/Settings/Operator",
	SAVE_OPERATOR: "/Settings/SaveOperator",

	GET_SYSTEM_COUNTRIES: "/System/GetCountries",
	GET_SYSTEM_FOOTBALL_TEAMS: "/System/GetFootballTeams",
	GET_SYSTEM_MARKETS: "/System/GetMarkets",
	GET_SYSTEM_TIMEZONES: "/System/GetTimeZones",
	GET_SYSTEM_STREAMS: "/System/GetStreams",

	GET_MONITORING_ALL: "/SystemCounter/All",
	GET_MONITORING: "/SystemCounter/Get",

	AUTOSUGGESTION_GET_PARTNERS: "/Autosuggestion/Partners",
	AUTOSUGGESTION_GET_GAMES: "/Autosuggestion/Games",
	AUTOSUGGESTION_GET_BETSHOPS: "/Autosuggestion/BetShops",
	AUTOSUGGESTION_GET_APIKEYS: "/Autosuggestion/ApiKeys",
	AUTOSUGGESTION_GET_USERS: "/Autosuggestion/Users",
	AUTOSUGGESTION_GET_ACCESS_MANAGERS: "/Autosuggestion/AccessManagers",
	AUTOSUGGESTION_GET_CASHIERS: "/Autosuggestion/Cashiers",
	AUTOSUGGESTION_GET_PERMISSION_GROUPS: "/Autosuggestion/PermissionGroups",
	AUTOSUGGESTION_GET_CONTROLLERS: "/Autosuggestion/Controllers",
	AUTOSUGGESTION_GET_BONUSES: "/Autosuggestion/Bonuses",
	AUTOSUGGESTION_GET_JACKPOT_NAMES: "/Autosuggestion/Jackpots",
	AUTOSUGGESTION_GET_MARKETS: "/Autosuggestion/SystemMarkets",
	AUTOSUGGESTION_GET_CURRENCIES: "/Autosuggestion/SystemCurrencies",

	GET_SURVEYS: "/Survey/All",
	GET_SURVEY_QUICK_VIEW: "/Survey/QuickView",
	GET_SURVEY_PARTNERS: "/Survey/Partners",
	GET_SURVEY_AVAILABLE_PARTNERS: "/Survey/AvailablePartners",
	GET_SURVEY_PARTNER_FEEDBACKS: "/Survey/PartnerFeedbacks",
	GET_SURVEY_GENERAL_INFO: "/Survey/GeneralInfo",
	SAVE_SURVEY_GENERAL_INFO: "/Survey/SaveGeneralInfo",
	SURVEY_CHANGE_STATE: "/Survey/ChangeState",
	CREATE_SURVEY: "/Survey/Create",
	ADD_PARTNERS_SURVEY: "/Survey/AddPartners",
	SAVE_SURVEY_PARTNERS: "/Survey/SavePartners",
	EXPORT_SURVEYS: "/Survey/ExportAll"
};

export const SPECIFIC_ENDPOINTS = {
	[PROJECT_TYPE.ONLINE]: {
		DASHBOARD_TOP_MARKETS: PATHS.DASHBOARD_ONLINE_TOP_MARKETS,
		DASHBOARD_PENDING_BETSLIPS_COUNT: PATHS.DASHBOARD_ONLINE_PENDING_BETSLIPS_COUNT,
		DASHBOARD_BETSLIPS_ACTIVITIES: PATHS.DASHBOARD_ONLINE_BETSLIPS_ACTIVITIES,
		DASHBOARD_PARTNERS_REVENUE: PATHS.DASHBOARD_ONLINE_PARTNERS_REVENUE,
		GET_BETS: PATHS.GET_ONLINE_BETS,
		GET_BETS_NEW: PATHS.GET_ONLINE_BETS_NEW,
		GET_BET_DB_CACHE_COMPARE:  PATHS.GET_PLAYER_DB_CACHE_COMPARE,
		GET_BET_DB_CACHE_SYNCHRONIZE: PATHS.GET_PLAYER_DB_CACHE_SYNCHRONIZE,
		DASHBOARD_GAMES_PERFORMANCE: PATHS.DASHBOARD_ONLINE_GAMES_PERFORMANCE
	},
	[PROJECT_TYPE.RETAIL]: {
		DASHBOARD_TOP_MARKETS: PATHS.DASHBOARD_RETAIL_TOP_MARKETS,
		DASHBOARD_PENDING_BETSLIPS_COUNT: PATHS.DASHBOARD_RETAIL_PENDING_BETSLIPS_COUNT,
		DASHBOARD_BETSLIPS_ACTIVITIES: PATHS.DASHBOARD_RETAIL_BETSLIPS_ACTIVITIES,
		DASHBOARD_PARTNERS_REVENUE: PATHS.DASHBOARD_RETAIL_PARTNERS_REVENUE,
		GET_BETS: PATHS.GET_RETAIL_BETS,
		GET_BETS_NEW: PATHS.GET_RETAIL_BETS_NEW,
		GET_BET_DB_CACHE_COMPARE: PATHS.GET_BETSHOP_DB_CACHE_COMPARE,
		GET_BET_DB_CACHE_SYNCHRONIZE: PATHS.GET_BETSHOP_DB_CACHE_SYNCHRONIZE,
		DASHBOARD_GAMES_PERFORMANCE: PATHS.DASHBOARD_RETAIL_GAMES_PERFORMANCE
	},
	[PROJECT_TYPE.BACKOFFICE]: {}
};

export default PATHS;
