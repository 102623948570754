import { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Typography, DatePicker, Select, Input } from "antd";
import NumericInput from "components/common/numericInput";
import { getBetAnomaliesHistory, setBetAnomaliesHistoryFilters } from "store/actions/dashboard/riskManagment/betAnomalies/betAnomalies.actions";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import locale from "antd/es/date-picker/locale/en_US";
import { getGamesFromConfig } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";
import { RISK_CASE_STATUS_TRANSLATIONS, RISK_CASE_STATUSES } from "constants/riskManagement.constants";

const { Item: FormItem } = Form;
const allCategories = "";

/** Games Page History Filters Component */
const HistoryFilters = ({ filters = {}, getBetAnomaliesHistory = Function.prototype, setBetAnomaliesHistoryFilters = Function.prototype }) => {
	const [selectedPartnerId, setSelectedPartnerId] = useState(null);
	const [filterGameCategory, setFilterGameCategory] = useState(allCategories);

	const { t } = useTranslation();

	const formInstanceRef = useRef();

	const partnersNames = useAutosuggestion(AUTOSUGGESTION_TYPE.PARTNER_NAMES, []);

	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [selectedPartnerId], {
		actionArguments: [
			{
				partnerId: selectedPartnerId,
				params: {
					gameCategory: filterGameCategory === allCategories ? null : filterGameCategory
				}
			}
		]
	});

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true }]
		};
	}, []);

	const filtersList = [
		{ name: "partnerName", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID") },
		{ name: "partnerId", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID"), hidden: () => true },
		{ name: "projectId", title: t("pages.dashboard.apikeys.project_name_or_ID"), hidden: () => true },
		{ name: "projectName", title: t("pages.dashboard.apikeys.project_name_or_ID") },
		{ name: "playerExternalId", title: t("pages.dashboard.players.external_id") },
		{ name: "playerId", title: t("pages.dashboard.bets.player_id") },
		{ name: "minWinCount", title: `${t("common.min")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_winCount")}` },
		{ name: "maxWinCount", title: `${t("common.max")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_winCount")}` },
		{ name: "minWinPercent", title: `${t("common.min")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_winPercent")}` },
		{ name: "maxWinPercent", title: `${t("common.max")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_winPercent")}` },
		{ name: "minTotalWinAmount", title: `${t("common.min")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_defaultTotalWinAmount")}` },
		{ name: "maxTotalWinAmount", title: `${t("common.max")} ${t("pages.dashboard.risk_management.bet_anomalies_history_filter_defaultTotalWinAmount")}` },
		{ name: "date", title: t("pages.dashboard.bets.date_and_time") },
		{
			name: "riskCaseStatus",
			title: t("pages.dashboard.risk_management.bet_anomalies_history_filter_riskCaseStatus"),
			values: [
				{ title: t("common.all"), value: "" },
				...Object.values(RISK_CASE_STATUSES).map(riskCaseStatus => {

					return (
						{
							title: t(RISK_CASE_STATUS_TRANSLATIONS[riskCaseStatus]),
							value: riskCaseStatus
						}
					)
				})
			]
		}
	];

	const getItemKey = (item) => item.key;
	const getItemValue = (item) => item.value;

	const handleFilters = (filters) => {
		const modifiedFilters = { ...filters };
		if (modifiedFilters.partnerId) {
			modifiedFilters.partnerName = partnersNames.find((partner) => partner.key === filters.partnerId)?.value ?? "";
		} else {
			modifiedFilters.partnerName = ""
		}

		if (modifiedFilters.projectId) {
			modifiedFilters.projectName = apiKeyNames.find((partner) => partner.key === filters.projectId)?.value ?? "";
		} else {
			modifiedFilters.projectName = ""
		}

		if (!modifiedFilters.partnerName) {
			modifiedFilters.partnerId = "";
			modifiedFilters.projectName = "";
		}
		if (!modifiedFilters.projectName) {
			modifiedFilters.projectId = "";
		}

		setBetAnomaliesHistoryFilters(modifiedFilters);
	}

	return (
		<FiltersWrapper
			loadFn={getBetAnomaliesHistory}
			setFiltersFn={handleFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList}
			filtersName="betAnomaliesHistoryFilter"
			dependencies={[
				{ field: "gameCategory", resetField: "gameType", resetValue: "" },
				{ field: "partnerId", resetField: "projectId", resetValue: "" },
				{ field: "partnerId", resetField: "projectName", resetValue: "" },
				{ field: "partnerId", resetField: "partnerName", resetValue: "" },
				{ field: "partnerName", resetField: "projectId", resetValue: "" },
				{ field: "partnerName", resetField: "partnerId", resetValue: "" },
				{ field: "partnerName", resetField: "projectName", resetValue: "" },
				{ field: "projectName", resetField: "projectId", resetValue: "" },
				{ field: "projectId", resetField: "projectName", resetValue: "" }
			]}
			formInstanceRef={formInstanceRef}
		>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="playerExternalId" label={t("pages.dashboard.players.external_id")}>
						<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.players.external_id")}`} />
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="playerId" label={t("pages.dashboard.bets.player_id")}>
						<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.player_id")}`} />
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="partnerId" label={t("pages.dashboard.developerBetCorrections.partnerNameOrID")}>
						<SearchableSelect
							addNone={true}
							placeholder={`${t("common.start_typing")} ${t("pages.dashboard.partners.partner_name_or_id")}`}
							items={partnersNames}
							valueProp={getItemKey}
							textProp={getItemValue}
							renderText={getItemValue}
							suffixIcon={<i className="icon-down" />}
							onChange={(partnerId) => {
								setSelectedPartnerId(partnerId)
								if (partnerId === allCategories && typeof formInstanceRef?.current?.setFieldsValue === "function") {
									formInstanceRef.current.setFieldsValue({ projectId: allCategories });
								}
							}}
						/>
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem dependencies={["partnerId"]} noStyle>
						{
							({ getFieldValue }) => {
								const partnerId = getFieldValue("partnerId");
								return (
									<FormItem name="projectId" label={t("pages.dashboard.apikeys.project_name_or_ID")}>
										<SearchableSelect
											disabled={partnerId === allCategories}
											addNone={true}
											placeholder={`${t("common.start_typing")} ${t("pages.dashboard.apikeys.project_name_or_ID")}`}
											items={apiKeyNames}
											valueProp={getItemKey}
											textProp={getItemValue}
											renderText={getItemValue}
											suffixIcon={<i className="icon-down" />}
										/>
									</FormItem>
								)
							}
						}
					</FormItem>
				</Col>
			</Row>

			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{t("pages.dashboard.risk_management.bet_anomalies_history_filter_winCount")}</label>
					</div>
					<Row wrap={false}>
						<FormItem name="minWinCount" >
							<Input
								type="text"
								placeholder={t("common.min")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									[".", "-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									if (value.indexOf("0") === 0 && value.length !== 1) {
										value = value.replace("0", "")
									}
									formInstanceRef.current?.setFieldsValue({ minWinCount: value });
								}}
							/>
						</FormItem>
						<FormItem name="maxWinCount">
							<Input
								type="text"
								placeholder={t("common.max")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									[".", "-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									if (value.indexOf("0") === 0 && value.length !== 1) {
										value = value.replace("0", "")
									}
									formInstanceRef.current?.setFieldsValue({ maxWinCount: value });
								}}
							/>
						</FormItem>
					</Row>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{t("pages.dashboard.risk_management.bet_anomalies_history_filter_winPercent")}</label>
					</div>
					<Row wrap={false}>
						<FormItem name="minWinPercent">
							<Input
								type="text"
								placeholder={t("common.min")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									["-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									formInstanceRef.current?.setFieldsValue({ minWinPercent: value });
								}}
								onBlur={(e) => {
									let value = e.target.value;
									if (value.indexOf(".") === 0) {
										value = value.length > 1 ? "0" + value : "0"
									}
									if (value !== "" && (Number(value) || 0) < 0.1) {
										return formInstanceRef.current.setFieldsValue({ minWinPercent: 0.1 });
									}
									if ((Number(value) || 0) > 100) {
										return formInstanceRef.current.setFieldsValue({ minWinPercent: 100 });
									}
									formInstanceRef.current?.setFieldsValue({ minWinPercent: value });
								}}
							/>
						</FormItem>
						<FormItem name="maxWinPercent">
							<Input
								type="text"
								placeholder={t("common.max")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									["-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									formInstanceRef.current?.setFieldsValue({ maxWinPercent: value });
								}}
								onBlur={(e) => {
									let value = e.target.value;
									if (value.indexOf("-") > -1) {
										value = value.replace("-", "")
									}
									if (value.indexOf(".") === 0) {
										value = value.length > 1 ? "0" + value : "0"
									}
									if (value !== "" && (Number(value) || 0) < 0.1) {
										return formInstanceRef.current.setFieldsValue({ maxWinPercent: 0.1 });
									}
									if ((Number(value) || 0) > 100) {
										return formInstanceRef.current.setFieldsValue({ maxWinPercent: 100 });
									}
									formInstanceRef.current?.setFieldsValue({ maxWinPercent: value });
								}}
							/>
						</FormItem>
					</Row>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<div className="ant-form-item-label">
						<label>{`${t("pages.dashboard.risk_management.bet_anomalies_history_filter_defaultTotalWinAmount")} (EUR)`}</label>
					</div>
					<Row wrap={false}>
						<FormItem name="minTotalWinAmount">
							<Input
								type="text"
								placeholder={t("common.min")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									["-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									formInstanceRef.current?.setFieldsValue({ minTotalWinAmount: value });
								}}
								onBlur={(e) => {
									let value = e.target.value;
									if (value.indexOf(".") === 0) {
										value = value.length > 1 ? "0" + value : "0"
									}
									formInstanceRef.current?.setFieldsValue({ minTotalWinAmount: value });
								}}
							/>
						</FormItem>
						<FormItem name="maxTotalWinAmount">
							<Input
								type="text"
								placeholder={t("common.max")}
								onChange={(e) => {
									let value = "";
									let dotWasFound = false;
									e.target.value.split("").forEach(symbol => {
										if (symbol === ".") {
											if (dotWasFound) {
												return
											} else {
												dotWasFound = true;
											}
										}
										value += symbol
									});
									["-", "+"].forEach((symbol) => {
										if (value.indexOf(symbol) > -1) {
											value = value.replace(symbol, "")
										}
									})
									formInstanceRef.current?.setFieldsValue({ maxTotalWinAmount: value });
								}}
								onBlur={(e) => {
									let value = e.target.value;
									if (value.indexOf(".") === 0) {
										value = value.length > 1 ? "0" + value : "0"
									}
									formInstanceRef.current?.setFieldsValue({ maxTotalWinAmount: value });
								}}
							/>
						</FormItem>
					</Row>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem
						label={t("pages.dashboard.risk_management.bet_anomalies_history_filter_riskCaseStatus")}
						name="riskCaseStatus"
					>
						<Select
							suffixIcon={<i className="icon-down" />}
						>
							<Select.Option value={""}>{t("common.all")}</Select.Option>
							{
								Object.values(RISK_CASE_STATUSES).map(riskCaseStatus => {
									return (
										<Select.Option
											key={riskCaseStatus}
											value={riskCaseStatus}
										>
											{t(RISK_CASE_STATUS_TRANSLATIONS[riskCaseStatus])}
										</Select.Option>
									)
								})
							}
						</Select>
					</FormItem>
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem
						name="date"
						label={t("pages.dashboard.bets.date_and_time")}
					>
						<DatePicker.RangePicker
							placeholder={[t("common.from"), t("common.to")]}
							format={`${DATE_FORMAT} ${TIME_FORMAT}`}
							showTime={{ format: TIME_FORMAT }}
							allowClear={false}
							locale={{
								...locale,
								lang: {
									...locale.lang,
									ok: t("common.apply")
								}
							}}
						/>
					</FormItem>
				</Col>
			</Row>
		</FiltersWrapper>
	);
};

/** HistoryFilters propTypes
 * PropTypes
 */
HistoryFilters.propTypes = {
	/** Redux state property, games filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	setBetAnomaliesHistoryFilters: (filters) => {
		dispatch(setBetAnomaliesHistoryFilters(filters));
	},
	getBetAnomaliesHistory: (fromFirstPage) => {
		dispatch(getBetAnomaliesHistory(fromFirstPage));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.riskManagement.betAnomalies.history.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFilters);
