import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal, Input, Radio } from "antd";
const { Item: FormItem } = Form;

import NumericInput from "components/common/numericInput";

import { numberTransform } from "utils/common";
import { isFormChanged } from "utils/form";
import { convertCurrency, getPartnerCurrency } from "utils/currency";

import { PARTNER_WALLET_ACTION_TYPE } from "constants/partner.constants";
import { USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";
import { POPUP_SIZE } from "constants/common.constants";

import currencyType from "types/common/currency.type";

/** Balance Managment Popup Component */
const BalanceManagementComponent = ({ 
	initialBalance, 
	isSaving, 
	saveWallet, 
	additionalData, 
	popupTitle, 
	onClose, 
	maxBalance, 
	minBalance, 
	currency,
	currencyType, 
	partnerId, 
	currencies, 
	currencyId 
}) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldValue } = formInstance;

	const [action, setAction] = useState(PARTNER_WALLET_ACTION_TYPE.ADD);
	const [isFormTouched, setIsFormTouched] = useState(false);

	const currencyCode = getPartnerCurrency(
		currencyType === USER_SELECTED_CURRENCY_TYPE.CONVERTED ? currency.code.toUpperCase() : undefined,
		currencies,
		partnerId
	);

	/** Convert value to selected currency
	 * @function
	 * @param {number} - value to convert
	 * @returns {number}
	 * @memberOf BalanceManagementComponent
	 */
	const convertBySelectedCurrency = (value) => {
		return !currencyId ? convertCurrency(value, currencyCode, currencies, partnerId) : value;
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf BalanceManagementComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveWallet({
					balance: data.balance,
					action: data.action,
					reason: data.reason,
					currency: currencyCode,
					...additionalData
				});
			})
			.catch(Function.prototype);
	};

	/** Set form fields values, when wallet is loaded */
	useEffect(() => {
		setTimeout(() => {
			setFieldsValue({
				oldBalance: convertBySelectedCurrency(initialBalance)
			});
		}, 0);
	}, [initialBalance]);

	/** Apply change on old balance balance, and calculate new balance
	 * @function
	 * @memberOf BalanceManagementComponent
	 */
	const calculateBalance = () => {
		if (isNaN(Number(getFieldValue("balance")))) return;
		let balance = Number(getFieldValue("balance"));
		if (action === PARTNER_WALLET_ACTION_TYPE.REDUCE) {
			balance = 0 - balance;
		}
		setFieldsValue({
			newBalance: Number(convertBySelectedCurrency(initialBalance)) + balance
		});
	};

	/** Recalculate balance on action change */
	useEffect(() => {
		setTimeout(() => {
			calculateBalance();
		}, 0);
	}, [action]);

	/** Validate function for balance field
	 * @function
	 * @param {number} value - value of balance field
	 * @param {number} action - add/reduce
	 * @memberOf BalanceManagementComponent
	 */
	const validateBalanceValue = (value, action) => {
		let balance = Number(value);
		if (action === PARTNER_WALLET_ACTION_TYPE.REDUCE && balance > Number(convertBySelectedCurrency(initialBalance))) {
			return Promise.reject(t("validation.cant_reduce_more_than_balance"));
		} else if (action === PARTNER_WALLET_ACTION_TYPE.ADD && balance > maxBalance) {
			return Promise.reject(
				t("validation.must_be_less_than_other")
					.replace("%X%", t("pages.dashboard.wallet.amount"))
					.replace("%Y%", maxBalance + " " + (currencyId ? currencyId : currencyCode))
			);
		} else if (balance < minBalance) {
			return Promise.reject(
				t("validation.must_be_more_than_other")
					.replace("%X%", t("pages.dashboard.wallet.amount"))
					.replace("%Y%", minBalance + " " + (currencyId ? currencyId : currencyCode))
			);
		}
		return Promise.resolve();
	};

	return (
		<Modal open={true} title={popupTitle} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving, disabled: !isFormTouched }} centered>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					oldBalance: convertBySelectedCurrency(initialBalance),
					newBalance: convertBySelectedCurrency(initialBalance),
					balance: "",
					action: PARTNER_WALLET_ACTION_TYPE.ADD,
					reason: ""
				}}
				onValuesChange={(changed, formValues) =>
					setIsFormTouched(
						isFormChanged(
							{
								balance: Number(formValues.balance),
								action: formValues.action,
								reason: formValues.reason
							},
							{
								balance: 0,
								action: PARTNER_WALLET_ACTION_TYPE.ADD,
								reason: ""
							}
						)
					)
				}
			>
				<div>
					<Row>
						<Col span={24}>
							<FormItem label={t("pages.dashboard.wallet.current_balance")} name="oldBalance">
								<Input disabled={true} suffix={currencyId || currencyCode} />
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormItem name="action" className="form-item-without-margin">
								<Radio.Group
									size="large"
									onChange={(e) => {
										setAction(e.target.value);
										setTimeout(() => {
											validateFields(["balance"]);
										}, 0);
									}}
									options={[
										{ label: t("pages.dashboard.wallet.add"), value: PARTNER_WALLET_ACTION_TYPE.ADD },
										{ label: t("pages.dashboard.wallet.reduce"), value: PARTNER_WALLET_ACTION_TYPE.REDUCE }
									]}
								></Radio.Group>
							</FormItem>
						</Col>
						<Col span={24}>
							<FormItem
								name="balance"
								rules={[
									{ type: "number", message: t("validation.field_invalid"), transform: numberTransform },
									({ getFieldValue }) => ({
										validator(rule, value) {
											return validateBalanceValue(value, getFieldValue("action"));
										}
									})
								]}
							>
								<NumericInput
									prefix={action === PARTNER_WALLET_ACTION_TYPE.ADD ? " + " : " - "}
									onChange={() => {
										calculateBalance();
									}}
									autoFocus={true}
									placeholder="0"
								/>
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormItem label={t("pages.dashboard.wallet.changed_balance")} name="newBalance">
								<Input disabled={true} suffix={currencyId || getPartnerCurrency(currency.code, currencies, partnerId)} />
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormItem
								label={`${t("pages.dashboard.wallet.reason")}`}
								name="reason"
								rules={[
									{ required: true, whitespace: true, message: t("validation.field_required") },
									{ max: 1000, message: t("validation.field_invalid") }
								]}
							>
								<Input.TextArea maxLength={1000} rows={4} placeholder={t("pages.dashboard.wallet.reason_placeholder")} />
							</FormItem>
						</Col>
					</Row>
				</div>
			</Form>
		</Modal>
	);
};

/** BalanceManagementComponent propTypes
 * PropTypes
 */
BalanceManagementComponent.propTypes = {
	/** Initial Balance */
	initialBalance: PropTypes.number,
	/** Is true when balance is saving */
	isSaving: PropTypes.bool,
	/** Function to save wallet balance */
	saveWallet: PropTypes.func,
	/** Additional data to pass to API on saveWallet call */
	additionalData: PropTypes.object,
	/** Balance Managment popup title */
	popupTitle: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Possible max Balance */
	maxBalance: PropTypes.number,
	/** Possible min Balance */
	minBalance: PropTypes.number,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, user selected currency type */
	currencyType: PropTypes.oneOf(Object.values(USER_SELECTED_CURRENCY_TYPE)),
	/** Current Partner Id */
	partnerId: PropTypes.string,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** If set, it will be used as selected currency */
	currencyId: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		currency: state.profile.userInfo.currency,
		currencyType: state.profile.userInfo.currencyType,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, null)(BalanceManagementComponent);
