import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { refreshToken, getUser } from "utils/auth";
import { callbackByIsDefault } from "utils/currency";

import { USERINFO_ACTION_BEFORE, USERINFO_ACTION_FINISH, SET_USERINFO, UPDATE_USER_AVATAR, RESET_USER_CURRENCY, SET_CURRENCY_TYPE, SET_USER_CURRENCY, SET_USER_MAINTENANCE_MODE } from "../../../actionTypes";

const setUserInfoActionBefore = () => ({
	type: USERINFO_ACTION_BEFORE
});

const setUserInfoActionFinished = () => ({
	type: USERINFO_ACTION_FINISH
});

const setUserInfo = (userInfo) => ({
	type: SET_USERINFO,
	payload: { userInfo }
});

const resetUserCurrency = (currency) => ({
	type: RESET_USER_CURRENCY,
	payload: { currency }
});

export const resetUserSelectedCurrency = (currency) => {
	return (dispatch, getState) => {
		if(currency) {
			dispatch(resetUserCurrency(currency));
			return;
		}

		const state = getState();
		const globalPartnerId = state.partner.globalPartnerId;
		const currencies = state.profile.userInfo.currencies;

		dispatch(resetUserCurrency(currencies.find(c => callbackByIsDefault(c, globalPartnerId))));
	};
};

export const setCurrencyType = (type) => ({
	type: SET_CURRENCY_TYPE,
	payload: { type }
})

export const setUserCurrency = (currency) => ({
	type: SET_USER_CURRENCY,
	payload: { currency }
});

export const setUserInfoMaintenanceMode = (mode) => ({
	type: SET_USER_MAINTENANCE_MODE,
	payload: { mode }
});

export const updateUserAvatar = (avatarId) => ({
	type: UPDATE_USER_AVATAR,
	payload: { avatarId }
});

export const getUserInfo = (loadInBackground) => {
	return (dispatch) => {
		if (!loadInBackground) {
			dispatch(setUserInfoActionBefore());
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_USERINFO}`,
			method: Methods.GET
		})
			.then(({ data: { value: userInfo } }) => {
				dispatch(setUserInfo(userInfo));
				dispatch(setUserInfoActionFinished());
			})
			.catch(() => {
				dispatch(setUserInfoActionFinished());
			});
	};
};
