import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { GAMES_BEFORE, GAMES_FINISH, GAME_BEFORE, GAME_FINISH, SET_GAMES, DELETE_GAME, SET_GAME_DETAILS, CHANGE_GAME_STATE, SET_GAMES_FILTERS, SET_GAMES_SORTING, GAME_ALL_TEAMS_BEFORE, GAME_ALL_TEAMS_FINISH, SET_GAME_ALL_TEAMS, GAME_ADD_TEAMS_BEFORE, GAME_ADD_TEAMS_FINISH } from "store/actionTypes";
import { GAME_CATEGORY } from "constants/game.constants";

export const setGamesActionBefore = () => ({
	type: GAMES_BEFORE
});

export const setGamesActionFinished = () => ({
	type: GAMES_FINISH
});

export const setGameSaveActionBefore = () => ({
	type: GAME_BEFORE
});

export const setGameSaveActionFinished = () => ({
	type: GAME_FINISH
});

const setGameDetails = (id, details) => ({
	type: SET_GAME_DETAILS,
	payload: { id, details }
});

export const setGames = (games) => ({
	type: SET_GAMES,
	payload: { games }
});

const setDeleteGame = (id) => ({
	type: DELETE_GAME,
	payload: { id }
});

const setChangeGameState = (data) => ({
	type: CHANGE_GAME_STATE,
	payload: { data }
});

export const setGameFilters = (filters, gameCategory) => ({
	type: SET_GAMES_FILTERS,
	payload: { filters, gameCategory }
});

export const setGamesSorting = (sorting) => ({
	type: SET_GAMES_SORTING,
	payload: { sorting }
});

export const getGames = (fromFirstPage, loadAll, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.GETALL_SCHEDULED_GAMES : ApiUrls.GETALL_INSTANT_GAMES;
	return (dispatch, getState) => {
		dispatch(setGamesActionBefore());
		let params = null;
		if (loadAll) {
			params = { page: 1, limit: 500 };
		} else {
			params = {
				...getState().games.sorting,
				...getState().games.filters[gameCategory],
				page: fromFirstPage ? 1 : getState().games.sorting.page
			};
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: games } }) => {
				dispatch(setGames(games));
				if (fromFirstPage) dispatch(setGamesSorting({ ...getState().games.sorting, page: 1 }));
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const createGame = ({ gameCategory, ...game }, onSuccess) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.CREATE_SCHEDULED_GAME : ApiUrls.CREATE_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: game
		})
			.then(({ data: { value: game } }) => {
				dispatch(setGameSaveActionFinished());
				onSuccess && onSuccess(game);
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const deleteGame = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.DELETE_SCHEDULED_GAME : ApiUrls.DELETE_INSTANT_GAME;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.DELETE,
			data: { id }
		})
			.then(() => {
				dispatch(setDeleteGame(id));
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const getGameDetails = (id, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.SCHEDULED_GAME_GET_DETAILS : ApiUrls.INSTANT_GAME_GET_DETAILS;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: details } }) => {
				dispatch(setGameDetails(id, details));
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const changeGameState = (data, gameCategory) => {
	const url = gameCategory === GAME_CATEGORY.SCHEDULED ? ApiUrls.CHANGE_SCHEDULED_GAME_STATE : ApiUrls.CHANGE_INSTANT_GAME_STATE;
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${url}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { status, message: msg, value: data } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(setChangeGameState(data));
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};

export const setGameAllTeamsBefore = () => ({
	type: GAME_ALL_TEAMS_BEFORE
});

export const setGameAllTeamsFinished = () => ({
	type: GAME_ALL_TEAMS_FINISH
});

const setGameAllTeams = (data) => ({
	type: SET_GAME_ALL_TEAMS,
	payload: { data }
});

export const getAllTeams = ({ teamFormat }) => {
	
	return (dispatch, getState) => {
		dispatch(setGameAllTeamsBefore());
	
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAMES_ALL_PENALTY_TEAMS}`,
			method: Methods.GET,
			params: { teamFormat }
		})
			.then(({ data: { value: { teams } } }) => {
				dispatch(setGameAllTeams(teams))
			})
			.finally((ex) => {
				dispatch(setGameAllTeamsFinished());
			});
	};
};

export const setAddPenaltyTeamsBefore = () => ({
	type: GAME_ADD_TEAMS_BEFORE
});

export const setAddPenaltyTeamsFinished = () => ({
	type: GAME_ADD_TEAMS_FINISH
});

export const addPenaltyTeams = ({ id, teams }, onSuccess) => {
	
	return (dispatch, getState) => {
		dispatch(setAddPenaltyTeamsBefore());
	
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAMES_ADD_TEAMS}`,
			method: Methods.POST,
			data: { id, teams }
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
				}
				if (typeof onSuccess === "function") {
					onSuccess()
				}
			})
			.finally((ex) => {
				dispatch(setAddPenaltyTeamsFinished());
			});
	};
};