import { Fragment, useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCurrentPath from "hooks/useCurrentPath";

import { Dropdown, Menu, Input, Divider } from "antd";

import { getGlobalPartners } from "store/actions/dashboard/partners/partner.action";
import { setGlobalPartnerId } from "store/actions/dashboard/partners/partner.action";

import Paths from "constants/path.constants";
import { PARTNER_TYPE } from "constants/partner.constants";

import sessionStorageUtils from "utils/sessionStorage";
import { binaryToFlags } from "utils/common";

import partnerType from "types/partner/partner.type";
import userInfoType from "types/profile/userInfo.type";
import { getUser } from "utils/auth";
import { USER_ROLE, USER_ROLE_NAME } from "constants/user.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";

/** Partners global filter dropdown component on Header */

const GlobalPartnersFilter = ({
	isOnlinePath,
	isRetailPath,
	isVisible,
	isDisabled,
	getGlobalPartners,
	setGlobalPartnerId,
	globalPartnerId,
	userInfo,
	systemDefaultTranslation,
	globalPartners,
	// isLoading,
	systemDefaultRules
}) => {
	const [searchParams] = useSearchParams();
	const path = useCurrentPath();
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState("");
	const dropdownSearchElem = useRef(null);

	/** Filter partners by type
	 * @function
	 * @returns {array}
	 * @memberOf Glo`balPartnersFilter
	 */
	const getPartnersForType = () => {
		let result = [];
		const showOnlyOnline = isOnlinePath && !isRetailPath;
		const showOnlyRetail = !isOnlinePath && isRetailPath;
		if (!showOnlyOnline && !showOnlyRetail) {
			result = [...globalPartners];
		}
		if (showOnlyOnline) {
			result = globalPartners.filter((p) => binaryToFlags(Object.values(PARTNER_TYPE), p.type).includes(PARTNER_TYPE.ONLINE));
		}

		if (showOnlyRetail) {
			result = globalPartners.filter((p) => binaryToFlags(Object.values(PARTNER_TYPE), p.type).includes(PARTNER_TYPE.RETAIL));
		}

		return result;
	};

	const filteredPartners = getPartnersForType();

	/** Fires on dropdown change
	 * @function
	 * @memberOf GlobalPartnersFilter
	 */
	const onChange = (value) => {
		setGlobalPartnerId(value);
		setSearchValue("");
	};

	/** Get default value for partner dropdown, when it get shown
	 * @function
	 * @returns {string} - default partner id
	 * @memberOf GlobalPartnersFilter
	 */
	const getDefaultPartnerId = () => (sessionStorageUtils.get("selected_partner_id") ? sessionStorageUtils.get("selected_partner_id") : userInfo.mainPartnerId || "");

	/** Load partners */
	useEffect(() => {
		if (isVisible) {
			getGlobalPartners();
		}
	}, [isVisible]);

	/** Set global partner id to current editing apikey partnerId */
	useEffect(() => {
		const partnerId = searchParams.get("PI");
		if (partnerId) {
			setGlobalPartnerId(partnerId);
		}
	}, [path]);

	/** Load partners */
	useEffect(() => {
		if (isVisible) {
			if (!globalPartnerId) {
				setGlobalPartnerId(userInfo.mainPartnerId);
			} else {
				if (filteredPartners.length > 0 && !filteredPartners.find((p) => p.id === globalPartnerId)) {
					setGlobalPartnerId(userInfo.mainPartnerId);
				}
			}
		}
	}, [filteredPartners, isVisible]);

	useEffect(() => {
		const authData = getUser();
		if (authData?.role === USER_ROLE_NAME[USER_ROLE.ACCESS_MANAGER]) {
			setGlobalPartnerId(userInfo.mainPartnerId);
		}
	}, [userInfo]);

	return isVisible && filteredPartners.find((p) => p.id === getDefaultPartnerId()) ? (
		<div className={"global-dropdown" + (isDisabled ? " global-dropdown-disabled" : "")}>
			<Dropdown
				disabled={isDisabled}
				menu={{
					rootClassName: "global-dropdown-menu-search",
					onClick: (e) => e.key && onChange(e.key),
					items: [
						{
							key: 0,
							disabled: true,
							className: "global-dropdown-search",
							label: (
								<Fragment>
									<Input className="global-dropdown-search-input" placeholder={t("common.search")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} ref={dropdownSearchElem} />
									<Divider />
								</Fragment>
							)
						}
					].concat(
						((partnersArray) => {
							if (partnersArray.length === 0) {
								return {
									key: 1,
									disabled: true,
									className: "global-dropdown-empty",
									label: "Not Found!",
									onClick: (e) => e.key && onChange(e.key)
								};
							}
							return partnersArray.map((partner) => ({
								key: partner.id,
								// TODO: this className doesn't work, need to handle other way
								className: partner.id === globalPartnerId ? "global-dropdown-active" : "",
								label: partner.name + (partner.isTesting ? " (Test)" : ""),
								onClick: (e) => e.key && onChange(e.key)
							}));
						})(filteredPartners.filter((p) => !searchValue || p.id.toLowerCase() === searchValue.toLowerCase() || p.name.toLowerCase().includes(searchValue.toLowerCase())))
					)
				}}
				trigger={["click"]}
				placement="bottomRight"
				overlayClassName="global-dropdown-menu"
				onOpenChange={(visible) => {
					if (visible) {
						setTimeout(() => {
							dropdownSearchElem.current && dropdownSearchElem.current.focus();
						}, 20);
					}
				}}
			>
				<div className="global-dropdown-content">
					<span>
						{(filteredPartners.find((p) => p.id === globalPartnerId))?.name}
					</span>
					<i className="icon-down" />
				</div>
			</Dropdown>
		</div>
	) : null;
};

/** GlobalPartnersFilter propTypes
 * PropTypes
 */
GlobalPartnersFilter.propTypes = {
	/** Redux action to get all partners for global partners dropdown */
	getGlobalPartners: PropTypes.func,
	/** Redux state property, the user info */
	userInfo: userInfoType,
	/** Redux state property, is true when system default translations is enabled */
	systemDefaultTranslation: PropTypes.bool,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, is true when loading partners */
	isLoading: PropTypes.bool,
	/** Redux action to set global partner id */
	setGlobalPartnerId: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, is true when system default rules is enabled */
	systemDefaultRules: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getGlobalPartners: () => {
		dispatch(getGlobalPartners());
	},
	setGlobalPartnerId: (partnerId) => {
		dispatch(setGlobalPartnerId(partnerId));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.partners.isLoading,
		globalPartners: state.partner.globalPartners,
		globalPartnerId: state.partner.globalPartnerId,
		userInfo: state.profile.userInfo,
		systemDefaultTranslation: state.translations.systemDefault,
		systemDefaultRules: state.translations.rules[REDUX_RULES_STORE_FIELD[GAME_CATEGORY.SCHEDULED]].main.systemDefault || state.translations.rules[REDUX_RULES_STORE_FIELD[GAME_CATEGORY.INSTANT]].main.systemDefault
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalPartnersFilter);
