/**
	* @typedef {number} GameCategory
*/

/**
	* @enum {GameCategory}
*/
export const GAME_CATEGORY = {
	SCHEDULED: 1,
	INSTANT: 2
};

export const SCHEDULED_GAME_TYPE = {
	FOOTBALL_SINGLE_MATCH: { value: 1, type: "football_single_match" },
	ENGLISH_LEAGUE: { value: 2, type: "english_league" },
	HORSES_RACE: { value: 4, type: "horse_racing_flat" },
	GREYHOUNDS_RACE: { value: 8, type: "greyhounds" },
	AFRICAN_CUP: { value: 16, type: "african_cup" },
	KENO: { value: 32, type: "keno" },
	WORLD_CUP: { value: 64, type: "world_cup" },
	EUROPEAN_CUP: { value: 128, type: "european_cup" },
	CHAMPIONS_LEAGUE: { value: 256, type: "champions_league" },
	COPA_LIBERTADORES: { value: 512, type: "copa_libertadores" },
	STEEPLECHASING: { value: 1024, type: "horse_race_steeple_chasing" },
	PENALTY_SHOOTOUT: { value: 2048, type: "penalty_shootout" },
	TURKISH_LEAGUE: { value: 4096, type: "turkish_league" }
};

export const INSTANT_GAME_TYPE = {
	FOOTBALL_SINGLE_MATCH: { value: 1, type: "football_single_match" }
};

export const GAME_TYPES = {
	[GAME_CATEGORY.SCHEDULED]: SCHEDULED_GAME_TYPE,
	[GAME_CATEGORY.INSTANT]: INSTANT_GAME_TYPE
};

export const GAME_EVENT_TYPE = {
	EVENT: 1,
	WEEK: 2,
	LEAGUE: 3
};

export const SCHEDULED_GAME_TYPE_MAPPER = {
	[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: "football_single_match",
	[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: "english_league",
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "horse_racing_flat",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "greyhounds",
	[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: "african_cup",
	[SCHEDULED_GAME_TYPE.KENO.value]: "keno",
	[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: "world_cup",
	[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: "european_cup",
	[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: "champions_league",
	[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: "copa_libertadores",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "horse_race_steeple_chasing",
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: "penalty_shootout",
	[SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value]: "turkish_league"
};

export const INSTANT_GAME_TYPE_MAPPER = {
	[INSTANT_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: "instant_football_single_match"
};

export const GAME_TYPE_MAPPER = {
	[GAME_CATEGORY.SCHEDULED]: SCHEDULED_GAME_TYPE_MAPPER,
	[GAME_CATEGORY.INSTANT]: INSTANT_GAME_TYPE_MAPPER
};

export const GAME_NAME_MAPPER = {
	"footballSingleMatch": "football_single_match",
	"footballEnglandLeague": "english_league",
	"horseRaceFlat": "horse_racing_flat",
	"greyhoundRace": "greyhounds",
	"footballAfricanCup": "african_cup",  
	"keno": "keno",
	"footballWorldCup": "world_cup",
	"footballEuropeanCup": "european_cup",
	"footballChampionsLeague": "champions_league",
	"footballCopaLibertadores": "copa_libertadores",
	"horseRaceSteeplechasing": "horse_race_steeple_chasing",
	"penalty": "penalty_shootout",
	"footballTurkishLeague": "turkish_league"
};

export const SCHEDULED_GAME_TYPE_TO_NAME_MAPPER = {
	[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: "footballSingleMatch",
	[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: "footballEnglandLeague",
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "horseRaceFlat",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "greyhoundRace",
	[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: "footballAfricanCup",
	[SCHEDULED_GAME_TYPE.KENO.value]: "keno",
	[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: "footballWorldCup",
	[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: "footballEuropeanCup",
	[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: "footballChampionsLeague",
	[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: "footballCopaLibertadores",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "horseRaceSteeplechasing",
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: "penalty",
	[SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value]: "footballTurkishLeague"
};

export const SCHEDULED_GAME_TYPE_ICON_MAPPER = {
	[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: "icon-football",
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "icon-horseRacing",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "icon-greyhounds",
	[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: "icon-league",
	[SCHEDULED_GAME_TYPE.KENO.value]: "icon-keno",
	[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: "icon-africanCup",
	[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: "icon-worldCup",
	[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: "icon-europeanCup",
	[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: "icon-championsLeague",
	[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: "icon-copaLibertadores",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "icon-horseRaceSteeplechasing",
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: "icon-penaltyShootout",
	[SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value]: "icon-turkishLeague"
}

export const FOOTBALL_PROBABILITIES = {
	GOAL_BY_ATTACK: { value: 1, key: "byAttack" },
	GOAL_BY_PENALTY: { value: 2, key: "byPenalty" },
	GOAL_BY_CORNER: { value: 4, key: "byCorner" },
	GOAL_BY_FREE_KICK: { value: 8, key: "byFreeKick" },
	NOGOAL_ATTACK: { value: 16, key: "attack" },
	NOGOAL_PENALTY: { value: 32, key: "penalty" },
	NOGOAL_CORNER_THROW: { value: 64, key: "corner" },
	NOGOAL_FREE_KICK: { value: 128, key: "freeKick" },
	NOGOAL_RED_CARD: { value: 256, key: "redCard" },
	NOGOAL_YELLOW_CARD: { value: 512, key: "yellowCard" }
};

export const PENALTY_PROBABILITIES = {
	GOAL_TOP_LEFT_SPECTER: { value: 1 << 0 /* 1 */, key: "topLeftSpectre" },
	GOAL_TOP_CENTRE_SPECTER: { value: 1 << 1 /* 2 */, key: "topCentreSpectre" },
	GOAL_TOP_RIGHT_SPECTER: { value: 1 << 2 /* 4 */, key: "topRightSpectre" },

	GOAL_CENTRE_LEFT_SPECTER: { value: 1 << 3 /* 8 */, key: "centreLeftSpectre" },
	GOAL_CENTRE_CENTRE_SPECTER: { value: 1 << 4 /* 16 */, key: "centreCentreSpectre" },
	GOAL_CENTRE_RIGHT_SPECTER: { value: 1 << 5 /* 32 */, key: "centreRightSpectre" },

	GOAL_BOTTOM_LEFT_SPECTER: { value: 1 << 6 /* 64 */, key: "bottomLeftSpectre" },
	GOAL_BOTTOM_CENTRE_SPECTER: { value: 1 << 7 /* 128 */, key: "bottomCentreSpectre" },
	GOAL_BOTTOM_RIGHT_SPECTER: { value: 1 << 8 /* 256 */, key: "bottomRightSpectre" },

	NOGOAL_MISS_SHOOT: { value: 1 << 9 /* 512 */, key: "missedShoot" },
	NOGOAL_SAVE: { value: 1 << 10 /* 1024 */, key: "missedSave" },
	NOGOAL_WOODWORK: { value: 1 << 11 /* 2048 */, key: "missedWoodwork" }
};

export const GAME_STATUSES = {
	NEW: 1,
	CLOSE_FOR_BETTING: 2,
	STARTED: 4,
	FINISHED: 8,
	CANCELED: 16,
	PREAMBLE_STARTED: 32
};

export const GAME_STATE = {
	ACTIVE: 1,
	CLOSE_FOR_BETTING: 2,
	INACTIVE: 4
};

export const HORSE_RACE_FORMAT = {
	HORSE_6: 1,
	HORSE_8: 2,
	HORSE_10: 4,
	HORSE_12: 8
};

export const GREYHOUNDS_RACE_FORMAT = {
	GREYHOUND_6: 1,
	GREYHOUND_8: 2
};


export const KENO_FORMAT = {
	//#region Unsupported formats 
	// KENO_6: 1,
	// KENO_12: 8,
	//#endregion
	KENO_8: 2,
	KENO_10: 4
};

export const SCHEDULED_GAME_TYPE_FORMAT = new Proxy(
	{
		[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: HORSE_RACE_FORMAT,
		[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: GREYHOUNDS_RACE_FORMAT,
		[SCHEDULED_GAME_TYPE.KENO.value]: KENO_FORMAT,
		[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: HORSE_RACE_FORMAT,
		DEFAULT: {}
	},
	{
		get(target, prop) {
			return prop in target ? target[prop] : target.DEFAULT;
		}
	}
);

export const GAME_CYCLE_MINUTES = {
	CYCLE1MIN: 2,
	CYCLE2MIN: 4,
	CYCLE3MIN: 8,
	CYCLE4MIN: 16,
	CYCLE5MIN: 32,
	CYCLE6MIN: 64
};

export const GAME_LABEL_TYPE = {
	NEW: 1,
	TEST: 2
}

export const GAME_LABEL_TYPE_FLAGS = [GAME_LABEL_TYPE.NEW, GAME_LABEL_TYPE.TEST];

export const GAME_LABEL_TYPE_MAPPER = {
	[GAME_LABEL_TYPE.NEW]: "New",
	[GAME_LABEL_TYPE.TEST]: "Test"
};

export const GAME_STREAM_CONFIGURATION_TYPE = {
	WOWZA: 1,
	NANOCOSMOS: 2,
	FLUSSONIC: 4
};

export const GAME_TEAM_FORMAT = {
	NATIONAL_TEAMS: 1,
	INTERNATIONAL_CLUBS: 2
}

export const GAME_TEAM_CONFIGS = {
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: {
		MIN_ENABLED_COUNT: 10,
		MAX_TEAMS_COUNT: 20
	}
}

export const ACTIVE_GAMES_COUNT_POSSIBLE_VALUES = Array.from(Array(19).keys()).map((n) => n + 2);

export const LEAGUE_ACTIVE_GAMES_COUNT_POSSIBLE_VALUES = [2, 3, 4, 5, 6];

export const BETS_CLOSE_IN_POSSIBLE_VALUES = [5, 10, 15];

export const PENALTY_SHOOTOUT_TEAMS_AND_RATINGS_VALIDATION_COEFFICIENT = [0.7, 0.8];

export const REDUX_EDITING_API_KEY_GAME_FIELD = {
	[GAME_CATEGORY.SCHEDULED]: "games",
	[GAME_CATEGORY.INSTANT]: "instantGames"
};

export const REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD = {
	[GAME_CATEGORY.SCHEDULED]: "analyticalTools",
	[GAME_CATEGORY.INSTANT]: "instantAnalyticalTools"
};

export const REDUX_EDITING_API_KEY_PLATFORMS_FIELD = {
	[GAME_CATEGORY.SCHEDULED]: "platforms",
	[GAME_CATEGORY.INSTANT]: "instantPlatforms"
};
