import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MAX_INT_32_VALUE, POPUP_SIZE } from "constants/common.constants";
import { useTranslation } from "react-i18next";
import { Modal, Form, Row, Col, Select, Input } from "antd";
import { RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS, RISK_MANAGEMENT_ACTION_TYPES, RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY, RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY_TRANSLATIONS } from "constants/riskManagement.constants";
import { createPlayerSuspiciousActivitiesConfiguration, updatePlayerSuspiciousActivitiesConfiguration } from "store/actions/dashboard/riskManagment/playerSuspiciousActivities/playerSuspiciousActivities.actions";
import { useEffect } from "react";
import NumericInput from "components/common/numericInput";
import { EMAIL_REGEX } from "constants/regex.constants";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

const { Item: FormItem } = Form;

const ConfigurationDeepView = ({ onClose, record, isSaving, createPlayerSuspiciousActivitiesConfiguration, updatePlayerSuspiciousActivitiesConfiguration }) => {

	const { t } = useTranslation();
	const [antdForm] = Form.useForm();
	const { validateFields } = antdForm;
	const isEditMode = Boolean(record);
	const initialValues = (
		isEditMode
			? record
			: (
				{
					currency: undefined,
					minBetAmount: undefined,
					minDeviation: undefined,
					averageUpdateFrequency: RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY.ONE_MONTH,
					actionType: RISK_MANAGEMENT_ACTION_TYPES.NONE,
					emails: []
				}
			)
	)

	const handleForm = async () => {
		try {
			const data = await validateFields();
			const emails = (
				data.actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE
					? (data.emails?.filter((email) => EMAIL_REGEX.test(email)) ?? [])
					: data.emails
			);
			const actionFunction = isEditMode ? updatePlayerSuspiciousActivitiesConfiguration : createPlayerSuspiciousActivitiesConfiguration
			const sendData = { ...data, emails }
			if (isEditMode) {
				sendData.id = record.id
			}
			actionFunction(sendData, () => {
				if (typeof onClose === "function") {
					onClose();
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const currencyNames = useAutosuggestion(
		AUTOSUGGESTION_TYPE.CURRENCY_NAMES,
		[]
	)

	return (
		<Modal
			open={true}
			closable={false}
			maskClosable={false}
			title={t(
				isEditMode
					? "pages.dashboard.risk_management.player_suspicious_activities_configurations_editConfigurations"
					: "pages.dashboard.risk_management.player_suspicious_activities_configurations_addConfigurations"
			)}
			width={POPUP_SIZE.BIG}
			centered
			onOk={handleForm}
			okText={t(
				isEditMode
					? "common.save"
					: "common.create"
			)}
			onCancel={onClose}
			cancelText={t("common.cancel")}
			okButtonProps={{ loading: isSaving }}
		>
			<Form
				className="dashboard-form"
				layout="vertical"
				requiredMark={false}
				form={antdForm}
				initialValues={initialValues}
				colon={false}
			>
				<Row gutter={[16, 0]}>
					<Col span={12}>
						<FormItem
							name="currency"
							label={`${t("pages.dashboard.bets.currency")} *`}
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								}
							]}
						>
							<Select placeholder={t("pages.dashboard.bets.currency")} suffixIcon={<i className="icon-down" />}>
								{currencyNames.map(({ key, value }) => {
									return (
										<Select.Option key={key} value={key}>
											{`${key} - ${value}`}
										</Select.Option>
									);
								})}
							</Select>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label={`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")} *`}
							name="minBetAmount"
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								},
								{
									validator: (_, values) => {
										const num = Number(values) ?? 0
										if (num > MAX_INT_32_VALUE) {
											return Promise.reject(
												t("validation.must_be_less")
													.replace("%X%", MAX_INT_32_VALUE)
											)
										}
										return Promise.resolve()
									}
								}
							]}
						>
							<NumericInput
								placeholder={t("pages.dashboard.risk_management.player_suspicious_activities_configurations_betAmount")}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col span={12}>
						<FormItem
							label={`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")} *`}
							name="minDeviation"
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								},
								{
									validator: (_, values) => {
										const minDeviation = Number(values) ?? 0
										if (minDeviation < 10 || minDeviation > 5000) {
											return Promise.reject(t("validation.must_be_between").replace("%X%", 10).replace("%Y%", 5000));
										}

										return Promise.resolve();
									}
								},
							]}
						>
							<NumericInput
								placeholder={t("pages.dashboard.risk_management.player_suspicious_activities_configurations_deviation")}
							/>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							name="averageUpdateFrequency"
							label={`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency")} *`}
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								}
							]}
						>
							<Select
								placeholder={t("pages.dashboard.risk_management.player_suspicious_activities_configurations_averageUpdateFrequency")}
								suffixIcon={<i className="icon-down" />}
								disabled={true}
								options={Object.values(RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY).map((value) => ({
									key: value,
									value: value,
									label: t(RISK_MANAGEMENT_AVERAGE_UPDATE_FREQUENCY_TRANSLATIONS[value])
								}))}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 0]}>
					<Col span={12}>
						<FormItem
							name="actionType"
							label={`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_actionType")} *`}
							rules={[
								{
									required: true,
									message: t("validation.field_required")
								}
							]}
						>
							<Select
								placeholder={t("pages.dashboard.risk_management.player_suspicious_activities_configurations_actionType")}
								suffixIcon={<i className="icon-down" />}
								options={Object.values(RISK_MANAGEMENT_ACTION_TYPES).map((value) => ({
									key: value,
									value: value,
									label: t(RISK_MANAGEMENT_ACTION_TYPE_TRANSLATIONS[value])
								}))}
							/>
						</FormItem>
					</Col>
					<FormItem dependencies={["actionType"]} noStyle>
						{({ getFieldValue }) => {
							const actionType = getFieldValue("actionType");
							return (
								<Col span={12}>
									<FormItem
										name="emails"
										label={`${t("pages.dashboard.risk_management.player_suspicious_activities_configurations_emails")} *`}
										rules={[
											{
												validator: (_, values) => {
													if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
														return Promise.resolve();
													}
													if (!Array.isArray(values) || values.length === 0) {
														return Promise.reject(t("validation.field_required"));
													}

													return Promise.resolve();
												}
											},
											{
												validator: (_, values) => {
													if (Array.isArray(values) && values.length > 10) {
														return Promise.reject(t("validation.must_be_less").replace("%X%", 10));
													}
													return Promise.resolve();
												}
											},
											{
												validator: (_, values) => {
													if (actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE) {
														return Promise.resolve();
													}
													let invalidValue = null;
													const invalidValueExists = values.some((value) => {
														if (!EMAIL_REGEX.test(value)) {
															invalidValue = value;
															return true;
														}
														return false;
													});
													if (invalidValueExists) {
														return Promise.reject(`${invalidValue} ${t("validation.email_format")}`);
													}
													return Promise.resolve();
												}
											}
										]}
									>
										<SearchableSelect
											disabled={actionType === RISK_MANAGEMENT_ACTION_TYPES.NONE}
											mode="tags"
											placeholder={t("pages.dashboard.risk_management.player_suspicious_activities_configurations_emails")}
											items={[]}
											valueProp={(item) => item}
											textProp={(item) => item}
											renderText={(item) => item}
											getPopupContainer={(target) => target.parentNode}
										/>
									</FormItem>
								</Col>
							);
						}}
					</FormItem>
				</Row>
			</Form>
		</Modal>
	);
};

ConfigurationDeepView.propTypes = {
	record: PropTypes.object,
	isSaving: PropTypes.bool,
	onClose: PropTypes.func,
	createPlayerSuspiciousActivitiesConfiguration: PropTypes.func,
	updatePlayerSuspiciousActivitiesConfiguration: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	createPlayerSuspiciousActivitiesConfiguration: (data, onSuccess) => {
		dispatch(createPlayerSuspiciousActivitiesConfiguration(data, onSuccess));
	},
	updatePlayerSuspiciousActivitiesConfiguration: (data, onSuccess) => {
		dispatch(updatePlayerSuspiciousActivitiesConfiguration(data, onSuccess))
	}
});

const mapStateToProps = (state) => ({
	isSaving: state.riskManagement.playerSuspiciousActivities.configurations.isSaving
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationDeepView);
