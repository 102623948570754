import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Row, Modal, Select, Input } from "antd";
const { Item: FormItem } = Form;

// import { changeSettledBet, changeSettledBetslip } from "store/actions/dashboard/betslips/settled.action";

import { POPUP_SIZE } from "constants/common.constants";
import { RISK_CASE_STATUS_TRANSLATIONS, RISK_CASE_STATUSES } from "constants/riskManagement.constants";
import { changePlayerSuspiciousActivitiesRiskCaseStatus } from "store/actions/dashboard/riskManagment/playerSuspiciousActivities/playerSuspiciousActivities.actions";

/** Betslip Status changing Popup Component */
const ChangeRiskCaseStatusPopup = ({ item, isLoading, changePlayerSuspiciousActivitiesRiskCaseStatus, onClose }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields } = formInstance;

	const [currentRiskCaseStatus, setCurrentRiskCaseStatus] = useState(item.riskCaseStatus);

	/** Fires when form submitted
	 * @function
	 * @memberOf ChangeRiskCaseStatusPopup
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				changePlayerSuspiciousActivitiesRiskCaseStatus({ id: item.id, status: data.riskCaseStatus })
			})
			.then(() => onClose())
			.catch(Function.prototype);
	};

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.risk_management.player_suspicious_activities_history_riskCaseStatus")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.SMALL}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isLoading, disabled: currentRiskCaseStatus === item.riskCaseStatus }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={item}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24}>
						<FormItem label={`${t("pages.dashboard.bets.status")} *`} name="riskCaseStatus" rules={[{ required: true, message: t("validation.field_required") }]}>
							<Select
								getPopupContainer={() => document.body}
								onChange={(e) => setCurrentRiskCaseStatus(e)}
								suffixIcon={<i className="icon-down" />}
							>
								{
									Object.values(RISK_CASE_STATUSES).map(riskCaseStatus => {
										return (
											<Select.Option key={riskCaseStatus} value={riskCaseStatus}>
												{t(RISK_CASE_STATUS_TRANSLATIONS[riskCaseStatus])}
											</Select.Option>
										)
									})
								}
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** ChangeRiskCaseStatusPopup propTypes
 * PropTypes
 */
ChangeRiskCaseStatusPopup.propTypes = {
	isLoading: PropTypes.bool,
	changePlayerSuspiciousActivitiesRiskCaseStatus: PropTypes.func,
	item: PropTypes.object,
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	changePlayerSuspiciousActivitiesRiskCaseStatus: (record) => {
		dispatch(changePlayerSuspiciousActivitiesRiskCaseStatus(record));
	},
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.riskManagement.playerSuspiciousActivities.history.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRiskCaseStatusPopup);
