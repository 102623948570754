import { Form, Row, Col, Input, Select, Tooltip } from "antd"
import FormItemLabel from "antd/es/form/FormItemLabel"
import NumericInput from "components/common/numericInput"
import { BETSLIP_MODES } from "constants/bet.constants"
import { JACKPOT_BONUS_LEVEL_COLORS, JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS } from "constants/bonus.constants"
import { useTranslation } from "react-i18next"
import { countDecimals, flagsToBinary, isNullish, numberTransform } from "utils/common"

const JackpotLevel = ({ field, level, levelList, existingLevelTypes, allFieldsExceptCurrent, disabled }) => {
	const { t } = useTranslation();

	const lessChecking = (listLevel) => existingLevelTypes.includes(listLevel.levelType) && (listLevel.levelType < level.levelType);
	const moreChecking = (listLevel) => existingLevelTypes.includes(listLevel.levelType) && (listLevel.levelType > level.levelType);
	const isNotSkipValue = (arg) => !isNullish(arg) && arg !== "";
	
	const interLevelValidationDependenciesOf_seedAmount_minHitAmount_maxHitAmount = [
		"hitCount",
		...allFieldsExceptCurrent.reduce((acc, field) => {
			["seedAmount", "minHitAmount", "maxHitAmount"].forEach(fieldName => {
				acc.push(["levels", field.name, fieldName])
			})
			return acc
		}, [])
	];

	const interLevelValidationDependenciesOf_minBetAmount = [
		"hitCount",
		...allFieldsExceptCurrent.reduce((acc, field) => {
			["minBetAmount"].forEach(fieldName => {
				acc.push(["levels", field.name, fieldName])
			})
			return acc
		}, [])
	];

	return (
		<Col flex="0 0 33%">
			<div className="vs--bonus-jackpot-level-header" >
				<div
					className="vs--pl-16 vs--pr-16 vs--pt-10 vs--pb-10 vs--flex vs--justify-start vs--align-center"
					style={{ background: JACKPOT_BONUS_LEVEL_COLORS[level.levelType] }}
				>
					<span className="vs--font-small vs--font-medium">
						{t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[level.levelType])}
					</span>
				</div>
				<div className="vs--pt-8 vs--pl-16 vs--pr-16">
					<Row>
						<Col span={24}>
							<Form.Item
								name={[field.name, "poolName"]}
								label={t('pages.dashboard.bonuses.jackpot_poolName_name')}
								rules={
									[
										{ max: 15, message: t("validation.must_be_less_and_not_equal_than_other").replace("%X%", t('pages.dashboard.bonuses.jackpot_poolName_name')).replace("%Y%", 15) }
									]
								}
							>
								<Input
									disabled={disabled}
									placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_poolName_name')}`}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name={[field.name, "betSlipType"]}
								label={`${t('pages.dashboard.bonuses.betType')} *`}
								rules={
									[
										{
											required: true,
											message: t("validation.field_required"),
										}
									]
								}
							>
								<Select
									disabled={disabled}
									placeholder={`${t('common.select')} ${t('pages.dashboard.bonuses.betType')}`}
									suffixIcon={(<i className="icon-down" />)}
								>
									<Select.Option value={BETSLIP_MODES.SINGLE}>
										{t('pages.dashboard.bets.single')}
									</Select.Option>
									<Select.Option value={BETSLIP_MODES.MULTI}>
										{t('pages.dashboard.bets.multi')}
									</Select.Option>
									<Select.Option value={flagsToBinary([BETSLIP_MODES.SINGLE, BETSLIP_MODES.MULTI])}>
										{`${t('pages.dashboard.bets.single')} or ${t('pages.dashboard.bets.multi')}`}
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name={[field.name, "minBetAmount"]}
								label={`${t('pages.dashboard.bonuses.jackpot_min_bet_amount')} *`}
								dependencies={interLevelValidationDependenciesOf_minBetAmount}
								rules={
									[
										{ required: true, message: t("validation.field_required") },
										({ getFieldValue }) => ({
											transform: numberTransform,
											validator(rule, value) {
												const forLessChecking = levelList.filter(lessChecking);
												const forMoreChecking = levelList.filter(moreChecking);
												let levelForMoreOrEqualCase = null;
												let levelForLessOrEqualCase = null;

												
												const isThereExistMoreOrEqualThenAllowedInPreviousLevel = forLessChecking.some(levelForCheck => {
													if (isNotSkipValue(levelForCheck.minBetAmount) && (Number(levelForCheck.minBetAmount) >= value)) {
														levelForMoreOrEqualCase = levelForCheck.levelType;
														return true
													}
													return false
												});

												if (isThereExistMoreOrEqualThenAllowedInPreviousLevel) {
													const levelTranslation = t('pages.dashboard.bonuses.level')
													const levelTypeTranslation = t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelForMoreOrEqualCase])
													return Promise.reject(
														t("validation.must_be_more_and_not_equal_than_other")
															.replace("%X%", t('pages.dashboard.bonuses.jackpot_min_bet_amount'))
															.replace("%Y%", `${levelTypeTranslation} ${levelTranslation} ${t('pages.dashboard.bonuses.jackpot_min_bet_amount')}`)
													);
												}

												

												const isThereExistLessOrEqualThenAllowedInNextLevel = forMoreChecking.some(levelForCheck => {
													if (isNotSkipValue(levelForCheck.minBetAmount) && (Number(levelForCheck.minBetAmount) <= value)) {
														levelForLessOrEqualCase = levelForCheck.levelType;
														return true
													}
													return false
												});

												if (isThereExistLessOrEqualThenAllowedInNextLevel) {
													const levelTranslation = t('pages.dashboard.bonuses.level')
													const levelTypeTranslation = t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelForLessOrEqualCase])
													return Promise.reject(
														t("validation.must_be_less_and_not_equal_than_other")
															.replace("%X%", t('pages.dashboard.bonuses.jackpot_min_bet_amount'))
															.replace("%Y%", `${levelTypeTranslation} ${levelTranslation} ${t('pages.dashboard.bonuses.jackpot_min_bet_amount')}`)
													);
												}

												return Promise.resolve()
											}
										})
									]
								}
							>
								<NumericInput
									decimalsCount={8}
									disabled={disabled}
									placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_min_bet_amount')}`}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name={[field.name, "seedAmount"]}
								label={`${t('pages.dashboard.bonuses.jackpot_seed_amount_level')} *`}
								dependencies={interLevelValidationDependenciesOf_seedAmount_minHitAmount_maxHitAmount}
								rules={
									[
										{ required: true, message: t("validation.field_required") },
										{
											type: "number",
											min: 0.00000001,
											max: 100000000,
											message: t("validation.must_be_between").replace("%X%", 0.00000001).replace("%Y%", 100000000),
											transform: numberTransform
										},
										({ getFieldValue }) => ({
											transform: numberTransform,
											validator(rule, value) {
												const minHitAmount = getFieldValue(["levels", field.name, "minHitAmount"]);
												if (isNullish(minHitAmount) || value < Number(minHitAmount)) {
													return Promise.resolve()
												}
												const fieldName = `${t('pages.dashboard.bonuses.jackpot_hit_amount_min')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
												return Promise.reject(
													t("validation.must_be_less_and_not_equal_than_other")
														.replace("%X%", t('pages.dashboard.bonuses.jackpot_seed_amount_level'))
														.replace("%Y%", fieldName)
												);
											}
										}),
										({ getFieldValue }) => ({
											transform: numberTransform,
											validator(rule, value) {

												const forLessChecking = levelList.filter(lessChecking);
												const forMoreChecking = levelList.filter(moreChecking);

												let fieldNameForMoreOrEqualCase = null;
												let levelForMoreOrEqualCase = null;

												const isThereExistMoreOrEqualThenAllowedInPreviousLevel = forLessChecking.some(levelForCheck => {
													if (isNotSkipValue(levelForCheck.maxHitAmount) && (Number(levelForCheck.maxHitAmount) >= value)) {
														levelForMoreOrEqualCase = levelForCheck.levelType;
														fieldNameForMoreOrEqualCase = `${t('pages.dashboard.bonuses.jackpot_hit_amount_max')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
														return true
													}
													if (isNotSkipValue(levelForCheck.minHitAmount) && (Number(levelForCheck.minHitAmount) >= value)) {
														levelForMoreOrEqualCase = levelForCheck.levelType;
														fieldNameForMoreOrEqualCase = `${t('pages.dashboard.bonuses.jackpot_hit_amount_min')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
														return true
													}
													if (isNotSkipValue(levelForCheck.seedAmount) && (Number(levelForCheck.seedAmount) >= value)) {
														levelForMoreOrEqualCase = levelForCheck.levelType;
														fieldNameForMoreOrEqualCase = t('pages.dashboard.bonuses.jackpot_seed_amount_level');
														return true
													}
													return false
												});

												if (isThereExistMoreOrEqualThenAllowedInPreviousLevel) {
													const levelTranslation = t('pages.dashboard.bonuses.level')
													const levelTypeTranslation = t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelForMoreOrEqualCase])
													return Promise.reject(
														t("validation.must_be_more_and_not_equal_than_other")
															.replace("%X%", t('pages.dashboard.bonuses.jackpot_seed_amount_level'))
															.replace("%Y%", `${levelTypeTranslation} ${levelTranslation} ${fieldNameForMoreOrEqualCase}`)
													);
												}


												let fieldNameForLessOrEqualCase = null;
												let levelForLessOrEqualCase = null;

												const isThereExistLessOrEqualThenAllowedInNextLevel = forMoreChecking.some(levelForCheck => {

													if (isNotSkipValue(levelForCheck.seedAmount) && (Number(levelForCheck.seedAmount) <= value)) {
														levelForLessOrEqualCase = levelForCheck.levelType;
														fieldNameForLessOrEqualCase = t('pages.dashboard.bonuses.jackpot_seed_amount_level');
														return true
													}
													if (isNotSkipValue(levelForCheck.minHitAmount) && (Number(levelForCheck.minHitAmount) <= value)) {
														levelForLessOrEqualCase = levelForCheck.levelType;
														fieldNameForLessOrEqualCase = `${t('pages.dashboard.bonuses.jackpot_hit_amount_min')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
														return true
													}
													if (isNotSkipValue(levelForCheck.maxHitAmount) && (Number(levelForCheck.maxHitAmount) <= value)) {
														levelForLessOrEqualCase = levelForCheck.levelType;
														fieldNameForLessOrEqualCase = `${t('pages.dashboard.bonuses.jackpot_hit_amount_max')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
														return true
													}
													return false
												});

												if (isThereExistLessOrEqualThenAllowedInNextLevel) {
													const levelTranslation = t('pages.dashboard.bonuses.level')
													const levelTypeTranslation = t(JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelForLessOrEqualCase])
													return Promise.reject(
														t("validation.must_be_less_and_not_equal_than_other")
															.replace("%X%", t('pages.dashboard.bonuses.jackpot_seed_amount_level'))
															.replace("%Y%", `${levelTypeTranslation} ${levelTranslation} ${fieldNameForLessOrEqualCase}`)
													);
												}

												return Promise.resolve()
											}
										})
									]
								}
							>
								<NumericInput
									decimalsCount={8}
									disabled={disabled}
									placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_seed_amount_level')}`}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormItemLabel prefixCls="ant-form" label={`${t('pages.dashboard.bonuses.jackpot_hit_amount')} *`} />
							<Row gutter={8} wrap={false}>
								<Col span={12}>
									<Form.Item
										name={[field.name, "minHitAmount"]}
										dependencies={interLevelValidationDependenciesOf_seedAmount_minHitAmount_maxHitAmount}
										rules={
											[
												{ required: true, message: t("validation.field_required") },
												{
													type: "number",
													min: 0.00000001,
													max: 100000000,
													message: t("validation.must_be_between").replace("%X%", 0.00000001).replace("%Y%", 100000000),
													transform: numberTransform
												},
												({ getFieldValue }) => ({
													transform: numberTransform,
													validator(rule, value) {
														const maxHitAmount = getFieldValue(["levels", field.name, "maxHitAmount"]);
														if (isNullish(maxHitAmount) || value < Number(maxHitAmount)) {
															return Promise.resolve()
														}
														const fieldName = `${t('pages.dashboard.bonuses.jackpot_hit_amount_max')} ${t('pages.dashboard.bonuses.jackpot_hit_amount')}`;
														return Promise.reject(
															t("validation.must_be_less_and_not_equal_than_other")
																.replace("%X%", t('pages.dashboard.bonuses.jackpot_seed_amount_level'))
																.replace("%Y%", fieldName)
														);
													}
												})
											]
										}
									>
										<NumericInput
											decimalsCount={8}
											disabled={disabled}
											placeholder={t('pages.dashboard.bonuses.jackpot_hit_amount_min')}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name={[field.name, "maxHitAmount"]}
										dependencies={interLevelValidationDependenciesOf_seedAmount_minHitAmount_maxHitAmount}
										rules={
											[
												{ required: true, message: t("validation.field_required") },
												{
													type: "number",
													min: 0.00000001,
													max: 100000000,
													message: t("validation.must_be_between").replace("%X%", 0.00000001).replace("%Y%", 100000000),
													transform: numberTransform
												}
											]
										}
									>
										<NumericInput
											decimalsCount={8}
											disabled={disabled}
											placeholder={t('pages.dashboard.bonuses.jackpot_hit_amount_max')}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="vs--bonus-jackpot-general-info-contributions">
							<Row gutter={0} wrap={false}>
								<Col span={12}>
									<Form.Item
										name={[field.name, "contribution"]}
										label={`${t('pages.dashboard.bonuses.jackpot_contribution')} *`}
										rules={
											[
												{ required: true, message: t("validation.field_required") },
												() => ({
													validator(rule, value) {
														if (value < 0.0001 || 10 < value) {
															return Promise.reject(t("validation.must_be_between").replace("%X%", 0.0001).replace("%Y%", 10))
														}
														if (countDecimals(value) > 4) {
															return Promise.reject(t("validation.decimal_count_permitted").replace("%X%", 4))
														}
														return Promise.resolve();
													}
												})
											]
										}
									>
										<NumericInput
											disabled={disabled}
											placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_contribution')}`}
											style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name={[field.name, "nextSeedContribution"]}
										label={`${t('pages.dashboard.bonuses.jackpot_next_seed_contribution')} % *`}
										rules={
											[
												{ required: true, message: t("validation.field_required") },
												() => ({
													validator(rule, value) {
														if (value < 0.0001 || 50 < value) {
															return Promise.reject(t("validation.must_be_between").replace("%X%", 0.0001).replace("%Y%", 50))
														}
														if (countDecimals(value) > 4) {
															return Promise.reject(t("validation.decimal_count_permitted").replace("%X%", 4))
														}
														return Promise.resolve();
													}
												})
											]
										}
									>
										<NumericInput
											disabled={disabled}
											placeholder={`${t('common.enter')} ${t('pages.dashboard.bonuses.jackpot_next_seed_contribution')}`}
											style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
										/>
									</Form.Item>
									<Tooltip
										overlayStyle={{ maxWidth: 380 }}
										title={
											<div className="vs--flex vs--flex-col">
												<div className="vs--mb-8">{t('pages.dashboard.bonuses.jackpot_next_seed_contribution_description-1')}</div>
												<div>{t('pages.dashboard.bonuses.jackpot_next_seed_contribution_description-2')}</div>
											</div>
										}
										placement="bottomRight"
									>
										<i className="icon-info" />
									</Tooltip>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</Col>
	)
}
export default JackpotLevel