import { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"
import { Col, Form, Modal, Row } from "antd"
import { POPUP_SIZE } from "constants/common.constants"
import { connect } from "react-redux";
import Flag from "components/common/flag";
import SearchableSelect from "components/common/searchableSelect";
import { GAME_TEAM_FORMAT, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { addPenaltyTeams, getAllTeams } from "store/actions/dashboard/virtuals/games/games.action";
import { isFormChanged } from "utils/form";

const AddTeams = ({ addTeamsLoading, onClose, onSuccess, teamFormat, addPenaltyTeams, getAllTeams, allTeams, isAllTeamsLoading, id, existingTeams }) => {
	const { t } = useTranslation()
	const [formInstance] = Form.useForm();
	const { getFieldValue, getFieldsValue, setFieldValue, setFieldsValue, validateFields } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false)

	const listTeams = useMemo(() => {
		const existingTeamsDictionary = existingTeams.reduce((acc, team) => {
			acc[team.countryCode] = true
			return acc;
		}, {})
		return allTeams.filter(team => {
			return !existingTeamsDictionary[team.countryCode]
		})
	}, [existingTeams, allTeams])
	
	const handleForm = () => {
		validateFields()
		.then((data) => {
			addPenaltyTeams({ ...data, id  }, () => {
				if (typeof onSuccess === "function") {
					onSuccess()
				}
			})
			setIsFormTouched(false);
		})
		.catch((ex) => {
			console.log(ex);
		});
	};

	const formChanged = (formValues) => {
		return isFormChanged(formValues, { teams: [] });
	};

	useEffect(() => {
		getAllTeams({ teamFormat })
	}, [teamFormat])

	return (
		<Modal
			open={true}
			title={<span>{t("common.add")}</span>}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.MIDDLE}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: addTeamsLoading, disabled: !isFormTouched }}
			centered
		>
			<Form
				className="dashboard-form apikey-edit-addNewGame"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{}}
				onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<Form.Item
							label={`${t("pages.dashboard.games.teamOrClubs")} *`}
							name="teams"
						>
							<SearchableSelect
								mode="tags"
								placeholder={t("pages.dashboard.games.teamOrClubs")}
								maxTagCount={20}
								items={listTeams}
								valueProp={(item) => item.countryCode}
								textProp={(item) => item.countryCode}
								renderText={(item) => {
									return (
										<div className="vs--flex vs--justify-start vs--align-center">
											<Flag code={item.countryCode} gameType={SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value} />
											{`${item.countryCode} - ${item.countryName}`}
										</div>
									)
								}}
								getPopupContainer={() => document.body}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}
/** AddTeams propTypes
 * PropTypes
 */
AddTeams.propTypes = {
	id: PropTypes.string,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	addTeamsLoading: PropTypes.bool,
	allTeams: PropTypes.array,
	isAllTeamsLoading: PropTypes.bool,
	teamFormat: GAME_TEAM_FORMAT.NATIONAL_TEAMS,
	getAllTeams: PropTypes.func,
	existingTeams: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
	getAllTeams: ({ teamFormat }) => {
		dispatch(getAllTeams({ teamFormat }))
	},
	addPenaltyTeams: ({ id, teams }, onSuccess) => {
		dispatch(addPenaltyTeams({ id, teams }, onSuccess))
	}
});

const mapStateToProps = (state, props) => ({
	allTeams: state.games.allTeams.data,
	isAllTeamsLoading: state.games.allTeams.isLoading,
	addTeamsLoading: state.games.addTeamsLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTeams);