import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Row, Col, DatePicker, Radio, Tooltip, Select } from 'antd';
import locale from "antd/es/date-picker/locale/en_US";
import { getProjectPerformanceReport, setProjectPerformanceReportFilters } from "store/actions/dashboard/reports/projectPerformance/projectPerformance.action";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from 'components/common/searchableSelect';
import useAutosuggestion from 'hooks/useAutosuggestion';
import { monthAgo } from 'utils/dateTime';
import { AUTOSUGGESTION_TYPE } from 'constants/autoSuggestion.constants';
import { GAME_CATEGORY } from 'constants/game.constants';
import { flagsToBinary, getGamesFromConfig } from 'utils/common';
import profilePartnersType from 'types/profile/profilePartners.type';
import { DATE_FORMAT } from 'constants/date.constants';
const { Item: FormItem } = Form;
const allCategories = "";

/** Project Performance Report Page Filters Component */
const Filters = ({
	setProjectPerformanceReportFilters,
	getProjectPerformanceReport,
	filters,
	globalPartnerId,
	partners
}) => {

	const { t } = useTranslation();
	const [filterGameCategory, setFilterGameCategory] = useState(allCategories)

	const apiKeyNames = useAutosuggestion(
		AUTOSUGGESTION_TYPE.API_KEY_NAMES,
		[globalPartnerId, filterGameCategory],
		{
			disableAction: () => !globalPartnerId,
			actionArguments: [
				{
					params: {
						gameCategory: (filterGameCategory === allCategories ? null : filterGameCategory)
					}
				}
			]
		}
	);

	const availableGameCategories = useMemo(() => {
		const selectedPartner = partners?.find(partner => partner.id === globalPartnerId);
		const partnerGameCategory = selectedPartner?.gameCategory;
		return partnerGameCategory ?? flagsToBinary(Object.values(GAME_CATEGORY));
	}, [partners, globalPartnerId])

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: true }]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "id", title: t("pages.dashboard.reports.project"), values: [{ key: "", value: t("common.all") }, ...apiKeyNames].map((b) => ({ title: b.value, value: b.key })) },
		{
			name: "gameCategory",
			title: t("pages.dashboard.games.gameCategory"),
			values: [{ title: t("common.all"), value: allCategories }].concat(
				Object.values(GAME_CATEGORY).map((gc) => ({
					title: t(`pages.dashboard.games.gameCategory_${gc}`),
					value: gc
				}))
			)
		},
		{
			name: "gameType",
			title: t("pages.dashboard.reports.game_type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				filters.gameCategory === allCategories
					? []
					: getGamesFromConfig(filters.gameCategory).map((g) => ({
							title: t(`common.${g.type}`),
							value: g.value
						}))
			)
		}
	];

	return (
		<FiltersWrapper
			filtersName="projectPerformance"
			loadFn={() => getProjectPerformanceReport()}
			setFiltersFn={(filters) => setProjectPerformanceReportFilters(filters)}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList()}
			defaultOpened={true}
			dependencies={[
				{
					field: "gameCategory",
					resetField: "gameType",
					resetValue: ""
				},
				{
					field: "gameCategory",
					resetField: "id",
					resetValue: ""
				}
			]}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4} >
						<FormItem
							label={t('pages.dashboard.games.gameCategory')}
							name="gameCategory"
						>
							<Select
								suffixIcon={(<i className="icon-down" />)}
								onChange={(value) => setFilterGameCategory(value)}
							>
								<Select.Option
									value={allCategories}
									title={t('common.all')}
								>
									{t('common.all')}
								</Select.Option>
								{
									Object.values(GAME_CATEGORY)
										.filter(value => {
											return availableGameCategories & value
										})
										.map(value => (
											<Select.Option
												key={value}
												value={value}
												title={t(`pages.dashboard.games.gameCategory_${value}`)}
											>
												{t(`pages.dashboard.games.gameCategory_${value}`)}
											</Select.Option>
										))
								}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4} >
						<FormItem
							label={t('pages.dashboard.reports.project')}
							name="id"
						>
							<SearchableSelect
								items={[
									{ key: "", value: t("common.all") },
									...apiKeyNames
								]}
								valueProp={item => item.key}
								textProp={item => item.value}
								renderText={item => item.value}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4} >
						<FormItem
							dependencies={["gameCategory"]}
							noStyle
						>
							{
								({ getFieldValue }) => {
									const gameCategory = getFieldValue("gameCategory");
									return (
										<FormItem
											label={t('pages.dashboard.reports.game_type')}
											name="gameType"
										>
											<Select
												suffixIcon={(<i className="icon-down" />)}
												disabled={gameCategory === allCategories}
											>
												<Select.Option value={""}><span title={t('common.all')}>{t('common.all')}</span></Select.Option>
												{
													gameCategory !== allCategories
														? (
															getGamesFromConfig(gameCategory).map(g => (
																<Select.Option
																	key={g.value}
																	value={g.value}
																	title={t(`common.${g.type}`)}
																>
																	{t(`common.${g.type}`)}
																</Select.Option>
															))
														)
														: null
												}
											</Select>
										</FormItem>
									)
								}
							}

						</FormItem>
					</Col>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.events.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} HH:00`}
								showTime={{ format: "HH" }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to set project performance report filters */
	setProjectPerformanceReportFilters: PropTypes.func,
	/** Redux state property, project performance report filters */
	filters: PropTypes.object,
	/** Redux action to get project performance report */
	getProjectPerformanceReport: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents partners of user info */
	partners: PropTypes.arrayOf(profilePartnersType)
}

const mapDispatchToProps = (dispatch) => ({
	getProjectPerformanceReport: () => {
		dispatch(getProjectPerformanceReport());
	},
	setProjectPerformanceReportFilters: (filters) => {
		dispatch(setProjectPerformanceReportFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.reports.projectPerformance.filters,
		globalPartnerId: state.partner.globalPartnerId,
		partners: state.profile.userInfo.partners
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
