import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tag, Button } from "antd";

import { hasPermission } from "utils/permissions";

import ApiUrls from "constants/api.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import ExportButton from "components/common/exportButton";

import PartnerAddComponent from "./partners-add.component";
import PartnerWalletAdjustmentComponent from "./partner-wallet-adjustment.component";
import Filters from "./filters.component";

import { getPartners, setPartnersSorting } from "store/actions/dashboard/partners/partners.action";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Paths from "constants/path.constants";

import { binaryToFlags, numberWithSpaces } from "utils/common";
import { convertCurrency, getPartnerCurrency } from "utils/currency";

import countries from "systemData/countries";
import { PARTNER_TYPE, PARTNER_STATE } from "constants/partner.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";
import { USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";

import partnerType from "types/partner/partner.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";

/** Partners Page Component */
const PartnersComponent = ({ getPartners, partners, isLoading, total, setPartnersSorting, sorting, filters, currency, currencies, currencyType }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	/** State to show/hide partner creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const [managingPartner, setManagingPartner] = useState(null);

	/** Close balance managing popup */
	useEffect(() => {
		setManagingPartner(null);
	}, [partners]);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.partners.list.name"),
			dataIndex: "name",
			render: (value, record) => (record.isTesting ? value + " (Test)" : value)
		},
		{
			title: t("pages.dashboard.partners.list.status"),
			dataIndex: "state",
			render: (value) => (value ? <Tag color="green">{t("common.active")}</Tag> : <Tag color="red">{t("common.inactive")}</Tag>)
		},
		{
			title: t("pages.dashboard.partners.list.country"),
			dataIndex: "countryCode",
			render: (value) => (value ? countries[value.toLowerCase()] : ""),
			sorter: false
		},
		{
			title: t("pages.dashboard.partners.list.type"),
			dataIndex: "type",
			render: (value) => (value === PARTNER_TYPE.ONLINE ? t("common.online") : value === PARTNER_TYPE.RETAIL ? t("common.retail") : t("common.online") + ", " + t("common.retail")),
			sorter: false
		},
		{
			title: t("pages.dashboard.partners.list.currency"),
			dataIndex: "currency",
			render: (value, record) => getPartnerCurrency(currencyType === USER_SELECTED_CURRENCY_TYPE.CONVERTED ? currency.code : undefined, currencies, record.id),
			sorter: false,
			disableExport: true
		},
		...(hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_WALLET, action: PERMISSION_ACTION.VIEW })
			? [
					{
						title: t("pages.dashboard.partners.list.current_balance"),
						dataIndex: "balance",
						render: (value, record) => {
							if (binaryToFlags(Object.values(PARTNER_TYPE), record.type).includes(PARTNER_TYPE.RETAIL)) {
								const currencyCode = getPartnerCurrency(currencyType === USER_SELECTED_CURRENCY_TYPE.CONVERTED ? currency.code : undefined, currencies, record.id);
								return numberWithSpaces(convertCurrency(value, currencyCode, currencies, record.id));
							}
							return "-";
						}
					}
				]
			: []),
		{
			title: t("pages.dashboard.partners.list.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.partners.list.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	/* Specific case, view action busy for activiti functional */
	const generateEditAction = () => {
		if (!hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.VIEW })) {
			return null;
		}
		const obj = {
			handler: (record) => {
				navigate(Paths.DASHBOARD_PARTNERS_EDIT.replace(DYNAMIC_PATH_ID_REGEX, record.id));
			},
			icon: <i className="icon-eye" />
		};
		if (hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.MODIFY })) {
			delete obj["icon"];
		}
		return obj;
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.partners") }]} />
				<div className="dashboard-section-content">
					<div className="table-header">
						<div className="table-buttons-dropdowns">
							{hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.EXPORT }) ? (
								<ExportButton columns={columns.filter((c) => !c.disableExport).map((c) => ({ title: c.title, key: c.dataIndex }))} tableName={t("pages.dashboard.menu.partners")} url={ApiUrls.EXPORT_PARTNERS} filters={filters} sorting={sorting} />
							) : null}
							{hasPermission({ resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.CREATE }) ? (
								<div className="table-buttons-dropdowns-button">
									<Button onClick={() => setShowCreatePopup(true)} type="primary">
										{t("pages.dashboard.partners.create_partner")}
									</Button>
								</div>
							) : null}
						</div>
						<Filters />
					</div>

					<Table
						loading={isLoading}
						columns={columns}
						data={partners}
						loadFn={getPartners}
						sorting={sorting}
						setSortingFn={setPartnersSorting}
						total={total}
						actions={{
							edit: generateEditAction(),

							view: hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_TRANSACTIONS_HISTORY, action: PERMISSION_ACTION.VIEW })
								? {
										handler: (record) => {
											navigate(Paths.DASHBOARD_PARTNERS_VIEW.replace(DYNAMIC_PATH_ID_REGEX, record.id));
										},
										hidden: (record) => {
											return !binaryToFlags(Object.values(PARTNER_TYPE), record.type).includes(PARTNER_TYPE.RETAIL);
										},
										title: t("common.see_activity"),
										icon: <i className="icon-activity" />
									}
								: null,

							balance: hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_WALLET, action: PERMISSION_ACTION.MODIFY })
								? {
										handler: (record) => {
											return setManagingPartner({
												balance: record.balance,
												partnerId: record.id
											});
										},
										hidden: (record) => {
											return !binaryToFlags(Object.values(PARTNER_TYPE), record.type).includes(PARTNER_TYPE.RETAIL);
										},
										title: t("pages.dashboard.partners.list.adjust_wallet_balance"),
										icon: <i className="icon-balance" />
									}
								: null
						}}
						isDisabled={(record) => record.state === PARTNER_STATE.DISABLED}
						enableReload={true}
					/>
				</div>
			</div>

			{showCreatePopup ? <PartnerAddComponent isPartnersLoading={isLoading} partners={partners} onClose={() => setShowCreatePopup(false)} /> : null}

			{managingPartner !== null ? <PartnerWalletAdjustmentComponent partner={managingPartner} onClose={() => setManagingPartner(null)} /> : null}
		</Fragment>
	);
};

/** PartnersComponent propTypes
 * PropTypes
 */
PartnersComponent.propTypes = {
	/** Redux action to get partners */
	getPartners: PropTypes.func,
	/** Redux state property, represents the array of partners  */
	partners: PropTypes.arrayOf(partnerType),
	/** Redux state property, is true when loading partners */
	isLoading: PropTypes.bool,
	/** Redux state property, partners total count */
	total: PropTypes.number,
	/** Redux action to set partners sorting details */
	setPartnersSorting: PropTypes.func,
	/** Redux state property, partners sorting details */
	sorting: sortingType,
	/** Redux state property, partners filters */
	filters: PropTypes.object,
	/** Redux state property, current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, user selected currency type */
	currencyType: PropTypes.oneOf(Object.values(USER_SELECTED_CURRENCY_TYPE))
};

const mapDispatchToProps = (dispatch) => ({
	getPartners: () => {
		dispatch(getPartners());
	},
	setPartnersSorting: (sorting) => {
		dispatch(setPartnersSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.partners.isLoading,
		partners: state.partners.partners,
		total: state.partners.total,
		sorting: state.partners.sorting,
		filters: state.partners.filters,
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies,
		currencyType: state.profile.userInfo.currencyType
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersComponent);
