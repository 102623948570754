import PropTypes from "prop-types";

import { connect } from "react-redux";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import SettledTableByBetSlipComponent from "../../common/settled/tableByBetSlip.component";
import SettledTableByBetComponent from "../../common/settled/tableByBet.component";

import { getSettledBets, setSettledBetsSorting, setSettledBetsFilters } from "store/actions/dashboard/betslips/settled.action";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";

import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { GAME_CATEGORY } from "constants/game.constants";

/** Betslips Page Settled betslips Tab Online SubTabe Page Component */
const OnlineSettledBetsComponent = ({
	bets,
	sorting,
	filters,
	total,
	isLoading,
	getSettledBets,
	setSettledBetsSorting,
	setSettledBetsFilters,
	gameCategory,
	showBetsBySeparate
}) => {
	const Component = showBetsBySeparate ? SettledTableByBetComponent : SettledTableByBetSlipComponent;
	const exportURL = showBetsBySeparate ? ApiUrls.EXPORT_ONLINE_BETS : ApiUrls.EXPORT_ONLINE_BETSLIPS;
	return (
		<Component
			bets={bets}
			sorting={sorting}
			filters={filters}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.ONLINE}
			getSettledBets={getSettledBets}
			setSettledBetsSorting={setSettledBetsSorting}
			setSettledBetsFilters={setSettledBetsFilters}
			exportURL={exportURL + "?isPending=" + false}
			permission={PERMISSION_RESOURCE.BETSLIPS_ONLINE}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

/** OnlineSettledBetsComponent propTypes
 * PropTypes
 */
OnlineSettledBetsComponent.propTypes = {
	/** Redux state property, represents the array of online settled betslips */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, online settled betslips sorting details */
	sorting: sortingType,
	/** Redux state property, online settled betslips filters */
	filters: PropTypes.object,
	/** Redux state property, online settled betslips total count */
	total: PropTypes.number,
	/** Redux state property, is true when loading settled bets */
	isLoading: PropTypes.bool,
	/** Redux action to get settled bets */
	getSettledBets: PropTypes.func,
	/** Redux action to set settled bets sorting details */
	setSettledBetsSorting: PropTypes.func,
	/** Redux action to set settled bets filters */
	setSettledBetsFilters: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	showBetsBySeparate: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslips.settled.online.bets,
		total: state.betslips.settled.online.total,
		sorting: state.betslips.settled.online.sorting,
		filters: state.betslips.settled.online.filters,
		isLoading: state.betslips.isLoading
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = true }) => ({
	getSettledBets: (fromFirstPage, gameCategory) => {
		dispatch(getSettledBets(PROJECT_TYPE.ONLINE, fromFirstPage, gameCategory, showBetsBySeparate));
	},
	setSettledBetsSorting: (sorting) => {
		dispatch(setSettledBetsSorting(sorting, PROJECT_TYPE.ONLINE));
	},
	setSettledBetsFilters: (filters) => {
		dispatch(setSettledBetsFilters(filters, PROJECT_TYPE.ONLINE));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineSettledBetsComponent);
