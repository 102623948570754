import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import useCurrentPath from "hooks/useCurrentPath";
import { useTranslation } from "react-i18next";

import Dropdown from "antd/lib/dropdown";

import { resetUserSelectedCurrency, setUserCurrency, setCurrencyType } from "store/actions/dashboard/profile/userInfo.action";

import { ditinctArrayOfObjects, mergeClassNames } from "utils/common";
import Paths from "constants/path.constants";
import { USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";

import currencyType from "types/common/currency.type";

const PATHS_TO_SHOW_DROPDOWN_CURRENCY = {
	[Paths.DASHBOARD_STATISTICS]: Paths.DASHBOARD_STATISTICS,
	[Paths.DASHBOARD_PARTNERS]: Paths.DASHBOARD_PARTNERS,
	[Paths.DASHBOARD_ONLINE_PLAYERS]: Paths.DASHBOARD_ONLINE_PLAYERS,
	[Paths.DASHBOARD_BETSLIPS_ONLINE]: Paths.DASHBOARD_BETSLIPS_ONLINE,
	[Paths.DASHBOARD_BETSLIPS_RETAIL]: Paths.DASHBOARD_BETSLIPS_RETAIL,
	[Paths.DASHBOARD_REPORTS_PARTNER_ONLINE]: Paths.DASHBOARD_REPORTS_PARTNER_ONLINE,
	[Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE]: Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE,
	[Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE]: Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE,
	[Paths.DASHBOARD_ONLINE_PLAYERS_VIEW]: Paths.DASHBOARD_ONLINE_PLAYERS_VIEW
};

const PATHS_TO_SHOW_DROPDOWN_DISABLED = {
	[Paths.DASHBOARD_ONLINE_PLAYERS_VIEW]: Paths.DASHBOARD_ONLINE_PLAYERS_VIEW
}

const getMainPartnerId = (state) => state.partner.mainPartnerId;
const getGlobalPartnerId = (state) => state.partner.globalPartnerId;
const selectCurrencyType = (state) => state.profile.userInfo.currencyType;

/** Partner Global currency dropdown component on Header */
const PartnerCurrencyDropdown = ({ 
	isPartnerDropDownVisible, 
	currency, 
	currencies, 
	resetUserSelectedCurrency, 
	setUserCurrency, 
	setCurrencyType 
}) => {
	const path = useCurrentPath();
	const { t } = useTranslation();
	const mainPartnerId = useSelector(getMainPartnerId);
	const globalPartnerId = useSelector(getGlobalPartnerId);
	const currencyType = useSelector(selectCurrencyType);

	const isDropDownVisible = Boolean(PATHS_TO_SHOW_DROPDOWN_CURRENCY[path]);
	const isDropdownDisabled = Boolean(PATHS_TO_SHOW_DROPDOWN_DISABLED[path]);

	const filteredCurrencies = useMemo(() => {
		if (!isPartnerDropDownVisible) {
			return [{ name: t('common.default'), code: "isDefault" }, ...currencies];
		}

		return currencies.reduce((acc, currency) => {
			const newCurrency = { ...currency };
			if (newCurrency.isDefault) {
				newCurrency.name = `${t('common.default')}: ${newCurrency.name}`;
			}

			if (newCurrency.partnerId === globalPartnerId) {
				acc.push(newCurrency);
			}

			return acc;
		}, [])
	}, [isPartnerDropDownVisible, globalPartnerId]);

	/** Function, fires on drodown item click
	 * @function
	 * @param {string} code
	 * @memberOf PartnerCurrencyDropdown
	 */
	const handleDropdownChange = (code) => {
		if (!code || code === "isDefault") {
			setCurrencyType(USER_SELECTED_CURRENCY_TYPE.DEFAULT);
			return;
		}

		const curr = ditinctArrayOfObjects(filteredCurrencies, "code").find((c) => c.code === code);
		setUserCurrency(curr);
		setCurrencyType(USER_SELECTED_CURRENCY_TYPE.CONVERTED);
	};

	useEffect(() => {
		resetUserSelectedCurrency(isPartnerDropDownVisible ? undefined : mainPartnerId);
	}, [isPartnerDropDownVisible, globalPartnerId, mainPartnerId]);

	if (!isDropDownVisible) {
		return null;
	}

	return (
		<div className={mergeClassNames("global-dropdown", isDropdownDisabled && "global-dropdown-disabled")}>
			<Dropdown
				disabled={isDropdownDisabled}
				menu={{
					onClick: filteredCurrencies ? (e) => handleDropdownChange(e ? e.key : null) : Function.prototype,
					items: filteredCurrencies
						? ditinctArrayOfObjects(filteredCurrencies, "code").map((c, i) => {
							return {
								key: c.code || i,
								label: `${c.name}${c.code && c.code !== "isDefault" ? ` - ${c.code.toUpperCase()}` : ""}`,
								// TODO: this className doesn't work, need to handle other way
								className: (!isPartnerDropDownVisible && currencyType === USER_SELECTED_CURRENCY_TYPE.DEFAULT ? c.code === t('common.default') : c.code === currency?.code) ? "global-dropdown-active" : ""
							};
						})
						: []
				}}
				trigger={["click"]}
				placement="bottomRight"
				overlayClassName="global-dropdown-menu"
				getPopupContainer={() => document.body}
			>
				<div className="global-dropdown-content">
					<i className="icon-currency-header" />
					<span>{!isPartnerDropDownVisible ? (currencyType === USER_SELECTED_CURRENCY_TYPE.DEFAULT ? `${t('common.default')}` : currency.code) : currency ? `${currency.isDefault ? `${t('common.default')}: ${currency.code}` : currency.code}` : ""}</span>
					<i className="icon-down" />
				</div>
			</Dropdown>
		</div>
	);
};

/** PartnerCurrencyDropdown propTypes
 * PropTypes
 */
PartnerCurrencyDropdown.propTypes = {
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	setUserCurrency: PropTypes.func,
	resetUserSelectedCurrency: PropTypes.func,
	setCurrencyType: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	setUserCurrency: (currency) => {
		dispatch(setUserCurrency(currency))
	},
	resetUserSelectedCurrency: (currency) => {
		dispatch(resetUserSelectedCurrency(currency))
	},
	setCurrencyType: (type) => {
		dispatch(setCurrencyType(type))
	}
});

const mapStateToProps = (state) => {
	return {
		currency: state.profile.userInfo.currency,
		currencies: state.profile.userInfo.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCurrencyDropdown);
