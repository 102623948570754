import { createCallbackByCurrencyCode, callbackByIsDefault, generateDefaultCurrency } from "utils/currency";
import API_URL from "constants/api.constants";
import { USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";
import {
	PROFILE_ACTION_BEFORE,
	PROFILE_ACTION_FINISH,
	SET_PROFILE,
	PROFILE_SAVE_ACTION_BEFORE,
	PROFILE_SAVE_ACTION_FINISH,
	USERINFO_ACTION_BEFORE,
	USERINFO_ACTION_FINISH,
	SET_USERINFO,
	UPDATE_USER_AVATAR,
	RESET_USER_CURRENCY,
	SET_CURRENCY_TYPE,
	SET_USER_CURRENCY,
	SET_USER_MAINTENANCE_MODE,
	PASSWORD_SETTINGS_ACTION_BEFORE,
	PASSWORD_SETTINGS_ACTION_FINISH,
	SET_PASSWORD_SETTINGS,
	CHANGE_PASSWORD_ACTION_BEFORE,
	CHANGE_PASSWORD_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case USERINFO_ACTION_BEFORE:
			return {
				...state,
				isUserInfoLoading: true
			};
		case USERINFO_ACTION_FINISH:
			return {
				...state,
				isUserInfoLoading: false
			};
		case SET_USERINFO:
			return {
				...state,
				userInfo: {
					...payload.userInfo,
					avatar: `${import.meta.env.SYSTEM_CDN_URL}/system/users/${payload.userInfo.id}/${payload.userInfo.avatarId}_small.png`,
					currency: payload.userInfo.currencies.find((currency => callbackByIsDefault(currency, payload.userInfo.mainPartnerId)))
				}
			};
		case UPDATE_USER_AVATAR:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					avatar: `${import.meta.env.SYSTEM_CDN_URL}/system/users/${state.userInfo.id}/${payload.avatarId}_small.png`
				}
			};
		case RESET_USER_CURRENCY:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					currency: payload.currency ?? state.userInfo.currencies.find((currency => callbackByIsDefault(currency, state.userInfo.mainPartnerId))),
					currencyType: USER_SELECTED_CURRENCY_TYPE.DEFAULT
				}
			};
		case SET_CURRENCY_TYPE:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					currencyType: payload.type
				}
			};
		case SET_USER_CURRENCY:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					currency: payload.currency
				}
			};
		case SET_USER_MAINTENANCE_MODE:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					maintenanceMode: payload.mode
				}
			};
		case PROFILE_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PROFILE_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_PROFILE:
			return {
				...state,
				profile: payload.profile,
				userInfo: {
					...state.userInfo,
					firstName: payload.profile.firstName,
					lastName: payload.profile.lastName
				}
			};
		case PROFILE_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case PROFILE_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case PASSWORD_SETTINGS_ACTION_BEFORE:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: true
				}
			};
		case PASSWORD_SETTINGS_ACTION_FINISH:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: false
				}
			};
		case SET_PASSWORD_SETTINGS:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					passwordSettings: payload.settings
				}
			};
		case CHANGE_PASSWORD_ACTION_BEFORE:
			return {
				...state,
				isPasswordChanging: true
			};
		case CHANGE_PASSWORD_ACTION_FINISH:
			return {
				...state,
				isPasswordChanging: false
			};
		default:
			return state;
	}
};
